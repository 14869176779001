

export const getFakeUsers = () => {
  const users = []
  const now = new Date()
  for (let i = 0; i < 30; i++) {
    users.push({ id: Math.round(Math.random() * 50), email: 'nodernedarim@gmail.com', date: new Date(now.getFullYear(), now.getMonth(), Math.round(Math.random() * 30)).toISOString(' ') })
  }
  return users
}

export const mockStudents = () => {
  return [
    {
      _id: 1,
      student_name: "John Doe",
      student_id: "208952341",
      exam_id: 101,
      reviewSubmitted: true,
      saveForLater: false,
      date: "2024-02-28",
      answers: [
        {
          question_id: 1,
          answer: "Option A",
          grade: 10,
          teacher_feedback: null,
        },
        {
          question_id: 2,
          answer: "Option C",
          grade: 10,
          teacher_feedback: null,
        },
      ]
    },
    {
      _id: 2,
      student_name: "Jane Smith",
      student_id: "208952341",
      exam_id: 101,
      reviewSubmitted: false,
      saveForLater: true,
      date: "2024-02-27",
      answers: [
        {
          question_id: 1,
          answer: "Option B",
          grade: 25,
          teacher_feedback: null,
        },
        {
          question_id: 2,
          answer: "Option D",
          grade: 20,
          teacher_feedback: null,
        },
      ]
    },
    ,
    {
      _id: 3,
      student_name: "Noder Doker",
      student_id: "208952341",
      exam_id: 101,
      reviewSubmitted: false,
      saveForLater: false,
      date: "2024-02-27",
      answers: [
        {
          question_id: 1,
          answer: "Option B",
          grade: 0,
          teacher_feedback: null,
        },
        {
          question_id: 2,
          answer: "Option D",
          grade: 0,
          teacher_feedback: null,
        },
      ]
    },
  ].map(student => {
    const totalAnswerGrade = student.answers.reduce((acc, curr) => acc + curr.grade, 0);
    const maxPossibleGrade = student.answers.reduce((acc, curr) => acc + 100, 0); // Total possible score for all questions
    const grade = (totalAnswerGrade / maxPossibleGrade) * 100;
    return { ...student, grade };
  });
};


const POST_TITLES = [
  'The Role of AI in Modern Education: Enhancing Learning with Exam Generators',
  'How AI-Powered Exam Generators are Shaping the Future of Education',
  'Revolutionizing Education: The Impact of AI in Exam Preparation',
  'AI-Driven Exam Generators: Bridging the Gap Between Technology and Education',
  'Empowering Educators: The Benefits of AI-Based Exam Generators',
  'Advancing Education Through Technology: The Rise of AI in Exam Creation',
  'Breaking Barriers: How AI-Enabled Exam Generators are Reshaping Education',
  'The Future of Learning: Harnessing AI for Smarter Exam Generation',
  'Transforming Education with AI: The Evolution of Exam Generation',
  'AI in Education: Improving Learning Outcomes with Intelligent Exam Generators',
]

export const generateFakeViewsAndCovers = () => {
  // ! The convers in the server sit in the static folder, this way it works in the production
  return [...Array(10)].map((_, index) => ({
    cover: `/static/assets/images/covers/cover_${index + 1}.jpg`,
    view: Math.floor(Math.random() * 10000),
  }))
}
