import React, { useEffect, useState } from 'react';
// MUI
import { Chip, Grid, Paper, Typography, Box } from '@mui/material';
// Utils
import { featuresConfig } from '../utils/configs';
import { isMobile, getIcon, getFontSize, getIconColor } from '../utils';

const FeaturesList = ({
    user,
    language,
    languageData,
    isRTL,
    onInput,
    onFeatureClick,
    inputText,
    selectedFeature
}) => {
    const [selectedFeatureObj, setSelectedFeatureObj] = useState(null);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const inputQuery = searchParams.get('input');

        if (inputQuery) {
            // send the input to the parent component which re-render this component with the right selectedFeture prop
            onFeatureClick(inputQuery)
        }
    }, []);

    useEffect(() => {
        if (!selectedFeature)
            setSelectedFeatureObj(null)
        else {
            const foundFeature = featuresConfig.find(feature => feature.field === selectedFeature);
            if (foundFeature) {
                setSelectedFeatureObj(foundFeature);
            }
        }
    }, [selectedFeature])

    const handleFeatureClick = (feature) => {
        onFeatureClick(feature.field)
    };

    const handleTextInputChange = (value, field) => {
        onInput(value, field)
    };

    return (
        <div>
            {selectedFeatureObj ? (
                <div>
                    <Paper style={{ padding: '20px', margin: '20px', display: 'flex', flexDirection: 'column' }}>
                        <div>
                            <Typography variant="h5" style={{ marginLeft: '10px', direction: language !== 'en' ? 'rtl' : 'ltr' }}>
                                {` ${(languageData?.create_from ?? '') + (isRTL ? selectedFeatureObj.hebrewLabel : selectedFeatureObj.label)}`}
                            </Typography>
                            <Typography variant="subtitle1" style={{ color: 'gray', marginLeft: '10px', direction: language !== 'en' ? 'rtl' : 'ltr', fontSize: isMobile ? '' : getFontSize() }}>
                                {isRTL ? selectedFeatureObj.hebrewShortExplain : selectedFeatureObj.shortExplain}
                            </Typography>
                        </div>
                        {selectedFeatureObj.component(user, inputText, handleTextInputChange)}
                    </Paper>
                </div>
            ) : (
                <>
                    <Typography variant={isMobile ? "h6" : "h4"} sx={{ fontWeight: 500 }} gutterBottom p={1.5}>
                        {languageData.select_input_title}
                    </Typography>

                    <Grid container spacing={2} mb={4}>
                        {featuresConfig.map((feature, index) => (
                            <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                                <Paper
                                    onClick={() => handleFeatureClick(feature)}
                                    style={{
                                        margin: isMobile ? 10 : 5,
                                        padding: '20px',
                                        cursor: 'pointer',
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        borderRadius: '12px',
                                        transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                                    }}
                                    onMouseEnter={(e) => {
                                        e.currentTarget.style.transform = 'scale(1.05)';
                                        e.currentTarget.style.boxShadow = '0 4px 20px rgba(0, 0, 0, 0.1)';
                                    }}
                                    onMouseLeave={(e) => {
                                        e.currentTarget.style.transform = 'scale(1)';
                                        e.currentTarget.style.boxShadow = 'none';
                                    }}
                                >
                                    <Box display="flex" flexDirection="column" alignItems="center">
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            borderRadius="50%"
                                            bgcolor={getIconColor(feature.field)}
                                            width={60}
                                            height={60}
                                            mb={2}
                                        >
                                            {getIcon(feature.field)}
                                        </Box>
                                        <Typography variant="h6" align="center" style={{ fontSize: isMobile ? '' : getFontSize().title }}>
                                            {language === 'en' ? feature.label : feature.hebrewLabel}
                                            {feature.beta && (
                                                <Chip label={'Beta'} sx={{ fontWeight: 600, mx: 1 }} />
                                            )}
                                        </Typography>
                                    </Box>
                                    <Typography variant="body2" color="textSecondary" align="center" style={{ marginTop: '10px', direction: language !== 'en' ? 'rtl' : 'ltr', fontSize: isMobile ? '' : getFontSize().subtitle }}>
                                        {language === 'en' ? feature.shortExplain : feature.hebrewShortExplain}
                                    </Typography>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                </>
            )}
        </div>
    );
};

export default FeaturesList;
