import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material'
import QRCodeComponent from './QRCodeComponent';

function QRDialog({ url, open, handleClose }) {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            sx={{ width: '100%', overflow: 'hidden' }}
            PaperProps={{
                sx: {
                    width: '-webkit-fill-available',
                }
            }}
        >
            <DialogTitle id="form-dialog-title" sx={{ fontSize: '2rem', pb: 0, textAlign: 'center' }}>
                Scan Me
            </DialogTitle>
            <DialogContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 5, overflow: 'hidden' }}>
                <QRCodeComponent url={url} size={'75%'} />
            </DialogContent>
        </Dialog>
    )
}

export default QRDialog;
