import api from "./api"

async function readPPT(file) {
  const formData = new FormData()
  formData.append('pptFile', file)

  try {
    const response = await api.post('/exports/pptx/read', formData)
    return response
  } catch (error) {
    console.error(error)
    return null
  }
}

export default readPPT
