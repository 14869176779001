import React from 'react'; // Import React at the top
import { Box, Grid } from '@mui/material';
import { colors } from '../../../utils/customTheme';
import { isMobile } from '../../../utils';
import microsoftLogo from '../assets/logos/microsoft.png';
import googleLogo from '../assets/logos/google.png';
import amazonLogo from '../assets/logos/amazon.png';
import atidLogo from '../assets/logos/atid.png';
// import aliansLogo from '../assets/logos/alians.png';
import arielLogo from '../assets/logos/ariel.png';
import mindcetLogo from '../assets/logos/mindcet.png';
import ortLogo from '../assets/logos/ort.png';

const IconRow = () => {
    return (
        <Box
            sx={{
                backgroundColor: colors.onboarding,
                padding: isMobile ? '10px 48px 48px 48px' : '10px 80px 80px 80px',
                width: '100vw',
            }}
        >
            <Grid
                container
                direction={'row'}
                justifyContent="space-around"
                alignItems="center"
                spacing={2}
            >
                <Grid item>
                    <img src={microsoftLogo} alt="Microsoft" style={styles.icon} />
                </Grid>
                <Grid item>
                    <img src={googleLogo} alt="Google" style={styles.icon} />
                </Grid>
                <Grid item>
                    <img src={amazonLogo} alt="Amazon" style={styles.icon} />
                </Grid>
                <Grid item>
                    <img src={atidLogo} alt="Atid" style={styles.icon} />
                </Grid>
                <Grid item>
                    <img src={mindcetLogo} alt="Mindcet" style={styles.icon} />
                </Grid>
                {/* <Grid item>
                    <img src={aliansLogo} alt="Alians" style={styles.icon} />
                </Grid> 
                <Grid item>
                    <img src={arielLogo} alt="Ariel" style={styles.icon} />
                </Grid>
                <Grid item>
                    <img src={ortLogo} alt="ORT" style={styles.icon} />
                </Grid>*/}
            </Grid>
        </Box>
    );
};

const styles = {
    icon: {
        width: '80px', // Adjust the size as needed
        height: '80px',
        objectFit: 'contain', // Maintain aspect ratio
    },
};

export default IconRow;
