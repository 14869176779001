import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode';

const QRCodeComponent = ({ url, size = '75%' }) => {
    const [QRUrl, setQRUrl] = useState('');

    useEffect(() => {
        QRCode.toDataURL(url, { errorCorrectionLevel: 'M' }).then((dataUrl) => {
            setQRUrl(dataUrl);
        });
    }, [url]);

    return QRUrl && (
        <div style={{
            display: 'contents',
            width: '100%',
        }}>
            <img src={QRUrl} alt="Exam-QR" style={{ width: size, height: 'auto' }} />
        </div>
    );
};

export default QRCodeComponent;
