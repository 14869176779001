import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AppBar,
  Box,
  Toolbar,
  Button,
  Divider,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ExamLogo from '../assets/no-bg-logo.png';
import { pages } from '../utils/pages';
import { goToStore } from '../utils';
import ROLES from '../utils/roles';
import { useLanguage } from '../contexts/languageContext';
import { isMobile } from '../utils';
import { useUser } from '../hooks/useUser';
import AvatarProfile from './AvatarProfile';

export default function Header({ page, token }) {
  const navigate = useNavigate();
  const { user } = useUser();
  const { language, languageData, ToggleLanguage } = useLanguage();

  const loginOnClick = () => {
    navigate('/login');
  };

  const signUpOnClick = () => {
    navigate('/signup');
  };

  const renderBuyExamsButton = () => (
    !user?.roles?.some(role => [ROLES.PREMIUM.label, ROLES.IL_TEACHER.label].includes(role)) && (
      <Button
        variant="contained"
        color="secondary"
        onClick={() => goToStore(navigate)}
        sx={{ margin: '10px' }}
      >
        {languageData.buy_exams_btn_label ?? "Premium"}
      </Button>
    )
  );

  const renderAuthButtons = () => (
    page === pages.landingPage && (
      <>
        <Button
          onClick={loginOnClick}
          sx={{
            margin: '5px',
            color: 'black',
            paddingRight: '10px',
            paddingLeft: '10px',
            ml: 'auto',
            border: '0px solid black',
            ':hover': {
              border: '1px solid black',
            },
          }}
        >
          Login
        </Button>
        <Button
          onClick={signUpOnClick}
          sx={{
            margin: '5px',
            backgroundColor: 'black',
            color: 'white',
            paddingRight: '10px',
            paddingLeft: '10px',
            ':hover': {
              backgroundColor: '#2b2b2b',
              color: 'white',
            },
          }}
        >
          Get Started
        </Button>
      </>
    )
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        elevation={0}
        position="sticky"
        style={{ backgroundColor: '#FFFFFF', color: 'black' }}
      >
        <Toolbar sx={{ justifyContent: 'flex-end', padding: 0 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {page !== pages.landingPage && renderBuyExamsButton()}
            {renderAuthButtons()}
            {user && (
              <AvatarProfile
                language={language}
                ToggleLanguage={ToggleLanguage}
                token={token}
              />
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Divider />
    </Box>
  );
}
