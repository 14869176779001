import React, { useState, useEffect, useMemo } from 'react';
import { Card, CardContent, Grid, Button, Box, LinearProgress, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { enterpriseSubjectsConfig, subjectsConfig, websiteLinks } from '../../utils/configs';
import { saveOnboardingData } from '../../requests/onboarding';
import useSnackBar from '../../hooks/useSnackBar';
import RoleSelectionStep from './RoleSelectionStep';
import SubjectSelectionStep from './SubjectSelectionStep';
import DetailsInputStep from './DetailsInputStep';
import { colors } from '../../utils/customTheme';
import ROLES from '../../utils/roles';
import { ONBOARDNG_ROLES } from '../../utils/roles';
import { useUser } from '../../hooks/useUser';
import { SnackbarTypes } from '../../utils/snackbarTypes';
import SummaryStep from './SummaryStep';
import { homepage } from '../../components/menu/MenuLinks';

const OnboardingFlow = () => {
  const { user } = useUser();
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedRoleId, setSelectedRoleId] = useState(null);
  const [selectedSubjects, setSelectedSubjects] = useState([]);

  const [institutionType, setInstitutionType] = useState('');
  const [institutionName, setInstitutionName] = useState('');
  const [level, setLevel] = useState('');
  const [nextBtnLoading, setNextBtnLoading] = useState(false);

  const navigate = useNavigate();
  const { openSnackBar, SnackBarComponent } = useSnackBar();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [currentStep]);

  const selectedRole = useMemo(() => {
    return ONBOARDNG_ROLES.find(role => role._id === selectedRoleId)?.role;
  }, [selectedRoleId]);

  async function handleSaveOnboardingData(selectedRole, selectedSubjects = [], institutionType = '', institutionName = '', level = '') {
    setNextBtnLoading(true);

    try {
      const saveSuccess = await saveOnboardingData({ type: selectedRole, subjects: selectedSubjects, institution: institutionType, name: institutionName, level });
      if (saveSuccess) {
        openSnackBar('SAVE_SUCCESS');
        navigate(homepage);
      } else {
        throw new Error('Failed to save onboarding data');
      }
    } catch (error) {
      console.error('Error saving onboarding data:', error);
      openSnackBar(SnackbarTypes.ERROR.field);
    } finally {
      setNextBtnLoading(false);
    }
    setNextBtnLoading(false);
  }

  const handleNext = () => {
    if (selectedRole === ROLES.ENTERPRISE.label && currentStep === 1) {
      setCurrentStep(prevStep => prevStep + 2);
    } else {
      setCurrentStep(prevStep => prevStep + 1);
    }
  };

  const handleBack = () => {
    setCurrentStep(prevStep => prevStep - 1);
  };

  const handleRoleSelection = (roleId) => {
    setSelectedRoleId(roleId);
  };

  const handleSubjectSelection = (subjects) => {
    setSelectedSubjects(subjects);
  };

  const isDisabled = () => {
    if (currentStep === 0 && !selectedRoleId) return true;
    if (currentStep === 1 && selectedSubjects.length === 0) return true;
    if (currentStep === 2 && (!institutionType || !institutionName || !level)) return true;
    return false;
  };

  const handleStartFreeTrial = async () => {
    handleSaveOnboardingData(selectedRole, selectedSubjects, institutionType, institutionName, level);
  };

  const steps = [
    {
      name: 'RoleSelection',
      component: RoleSelectionStep,
      props: { selectedRoleId, onSelect: handleRoleSelection },
    },
    {
      name: 'SubjectSelection',
      component: SubjectSelectionStep,
      props: {
        selectedSubjects,
        handleSubjectSelection,
        subjectsConfig: selectedRole === ROLES.ENTERPRISE.label ? enterpriseSubjectsConfig : subjectsConfig,
      },
    },
    {
      name: 'DetailsInput',
      component: DetailsInputStep,
      props: { institutionType, setInstitutionType, institutionName, setInstitutionName, level, setLevel },
    },
    {
      name: 'Summary',
      component: SummaryStep,
      props: {
        selectedRole,
        selectedSubjects,
        institutionName,
        level,
        onStartFreeTrial: handleStartFreeTrial,
        nextBtnLoading
      },
    },
  ];

  const CurrentStepComponent = steps[currentStep].component;

  return (
    <Grid container justifyContent="center" alignItems="center" sx={{ bgcolor: colors.onboarding, minHeight: '100vh', alignItems: 'flex-start' }}>
      <Grid item xs={12} md={8}>
        <LinearProgress
          variant="determinate"
          value={((currentStep + 1) / steps.length) * 100}
          sx={{
            marginTop: 2, height: 5, borderRadius: 5,
            '& .MuiLinearProgress-bar': {
              backgroundColor: colors.main,
            },
          }}
        />
        <Card sx={{ backgroundColor: colors.onboarding, border: 'none' }} variant="outlined">
          <CardContent>
            <Box mt={2} mb={5}>
              <CurrentStepComponent {...steps[currentStep].props} />
            </Box>
            {(currentStep < steps.length - 1) &&
              <Box
                display="flex"
                justifyContent="center"
                sx={{
                  position: 'fixed',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  zIndex: 1,
                }}>
                {currentStep !== 0 && (
                  <Button sx={{ m: 2, minWidth: '150px' }} variant='contained' onClick={handleBack}>
                    Back
                  </Button>
                )}

                <Button sx={{ m: 2, minWidth: '150px' }} variant='contained' onClick={handleNext} disabled={isDisabled()}>
                  {!nextBtnLoading ?
                    'Next' :
                    <CircularProgress sx={{ color: 'white' }} size={24} />
                  }
                </Button>
              </Box>}
          </CardContent>
        </Card>
      </Grid>
      <SnackBarComponent />
    </Grid>
  );
};

export default OnboardingFlow;
