import React, { useState, useEffect } from 'react'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import PostCard from '../post-card'
import PostSearch from '../post-search'
import NewPostDialog from '../../NewPostDialog'

import { useUser } from '../../../../hooks/useUser'
import { Button } from '@mui/material'
import { paddingPageStyle } from '../../../../utils/customTheme'
// ----------------------------------------------------------------------

export default function BlogView({ posts }) {
    const { isAdmin } = useUser()
    const [admin, setAdmin] = useState(false)
    const [openNewPostDialog, setOpenNewPostDialog] = useState(false)

    const handleNewPostClick = () => {
        setOpenNewPostDialog(true)
    }

    const handleCloseNewPostDialog = () => {
        setOpenNewPostDialog(false)
    }

    useEffect(() => {
        isAdmin().then((res) => {
            if (res) setAdmin(true)
        })
    }, [])

    return (
        <div style={{ padding: 30, ...paddingPageStyle }}>
            <Typography variant='h4'>AI In Education Blog</Typography>
            {admin && (
                <Button variant='contained' onClick={handleNewPostClick}>
                    New Post - admins only
                </Button>
            )}
            <Grid container spacing={1} alignItems='center'>
                <Grid item xs={12} sm={12}>
                    <PostSearch posts={posts} />
                </Grid>
                {/* <Grid item xs={12} sm={4}>
                    <PostSort
                        options={[
                            { value: 'latest', label: 'Latest' },
                            { value: 'popular', label: 'Popular' },
                            { value: 'oldest', label: 'Oldest' },
                        ]}
                    />
                </Grid> */}
            </Grid>

            <Grid container spacing={3}>
                {posts.map((post, index) => (
                    <Grid key={post._id} item xs={12} sm={3} md={3} lg={3}>
                        <PostCard post={post} index={index} />
                    </Grid>
                ))}
            </Grid>
            {admin && <NewPostDialog
                open={openNewPostDialog}
                onClose={handleCloseNewPostDialog}
            />}
        </div>
    )
}
