import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Box, Button, Typography, Grid, Paper } from '@mui/material';
import { isMobile, shuffleArray } from '../../utils';
import QuestionTypeManager from './QuestionTypeManager';
import { useLanguage } from '../../contexts/languageContext';
import LanguageIcon from '@mui/icons-material/Language';
import SubmissionDialog from './SubmissionDialog';

const Exam = ({ examJson, onFinish, token }) => {
    const [answers, setAnswers] = useState(getInitialAnswers);
    const [displaySubmitError, setDisplaySubmitError] = useState(false);
    const [showSubmitDialog, setShowSubmitDialog] = useState(false);
    const { languageData, isRTL, ToggleLanguage } = useLanguage();
    const firstUnansweredQuestionRef = useRef(null);

    useEffect(() => {
        const saveAnswers = () => {
            const expirationTime = Date.now() + 60 * 60 * 1000; // 1 hour expiration
            localStorage.setItem('examAnswers', JSON.stringify({ answers, expirationTime }));
        };
        if (answers && Object.keys(answers).length > 0)
            saveAnswers();
    }, [answers]);


    useEffect(() => {
        if (examJson?.settings?.is_supervised) {
            const handleBeforeUnload = (event) => {
                event.preventDefault();
                event.returnValue = '';
            };

            const handleVisibilityChange = () => {
                if (document.hidden) {
                    handleFinish(true);
                }
            };

            window.addEventListener('beforeunload', handleBeforeUnload);
            document.addEventListener('visibilitychange', handleVisibilityChange);

            return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
                document.removeEventListener('visibilitychange', handleVisibilityChange);
            };
        }
    }, [examJson, languageData]);


    const handleAnswer = (questionId, answer) => {
        setAnswers(prevAnswers => ({
            ...prevAnswers,
            [questionId]: answer
        }));
    };

    const handleFinish = (didLeaveTab = false) => {
        if (didLeaveTab) {
            let restoredAnswers = { ...answers }
            if (!restoredAnswers || Object.keys(restoredAnswers).length === 0)
                restoredAnswers = getInitialAnswers();
            localStorage.removeItem('examAnswers'); // Clear data after submission
            onFinish(restoredAnswers, examJson);
            return
        }

        const isValid = validateAnswers();
        if (!isValid) {
            setDisplaySubmitError(true);
            setTimeout(() => { setDisplaySubmitError(false); }, 8000);

            const firstUnansweredQuestion = examJson.questions.find(question => !answers[question._id]);
            if (firstUnansweredQuestion) {
                firstUnansweredQuestionRef.current = firstUnansweredQuestion._id;
                const firstUnansweredQuestionElement = document.getElementById(firstUnansweredQuestionRef.current);
                if (firstUnansweredQuestionElement) {
                    firstUnansweredQuestionElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            } else {
                firstUnansweredQuestionRef.current = null;
            }
            return;
        }
        localStorage.removeItem('examAnswers'); // Clear data after submission
        onFinish(answers, examJson);
    };

    const validateAnswers = () => {
        const submittedQuestions = Object.keys(answers).filter(answerId =>
            examJson.questions.some(question => question._id === answerId)
        );
        return submittedQuestions.length === examJson.questions.length;
    };

    const closeSubmitDialog = () => {
        setShowSubmitDialog(false)
    }
    const handleSubmitDialog = () => {
        setShowSubmitDialog(false)
        handleFinish()
    }

    const shuffledQuestions = useMemo(() =>
        examJson?.settings?.shuffle_questions ? shuffleArray(examJson.questions) : examJson.questions
        , [examJson.questions]);

    if (!examJson) {
        return <Typography variant="body1">Error fetching exam. Please refresh the page or try again later.</Typography>;
    }

    return (
        <div dir={isRTL ? 'rtl' : 'ltr'}>
            <Button
                variant="outlined"
                sx={{ margin: '10px 10px 0px 10px', alignContent: 'center' }}
                onClick={() => ToggleLanguage(null, token)}
                startIcon={!isRTL ? <LanguageIcon /> : null}
            >
                {!isRTL ? 'Change To Hebrew' : 'שנו לשפה האנגלית'}
            </Button>
            <Box p={isMobile ? 2 : 5}>
                {shuffledQuestions.map((question, index) => (
                    <Paper sx={{ p: 3, mt: 3 }}>
                        <Grid key={question._id} container spacing={1} justifyContent="center" >
                            <Grid item xs={12} id={question._id}>
                                <Typography variant="h5" gutterBottom>
                                    {`${languageData?.quiz?.question_label} ${index + 1}`}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <QuestionTypeManager
                                    type={question.type}
                                    allQuestionsProps={{
                                        questionId: question._id,
                                        title: question.question,
                                        onAnswer: handleAnswer,
                                    }}
                                    openQuestionProps={{}}
                                    closeQuestionProps={{
                                        options: question?.options,
                                        correctAnswer: question?.correctAnswer
                                    }}
                                    graphQuestionProps={{
                                        functions: question.functions
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                ))}
                <Box mt={2}>
                    <Button onClick={() => setShowSubmitDialog(true)} variant="contained" color="primary" fullWidth>
                        {languageData?.quiz?.exam.finish_label}
                    </Button>
                </Box>
                {displaySubmitError && <Typography variant='body1' color='red'>{languageData?.quiz?.completion_msgs.error_msg}</Typography>}
            </Box>
            <SubmissionDialog
                open={showSubmitDialog}
                closeDialog={closeSubmitDialog}
                submitExam={handleSubmitDialog}
            />
        </div>
    );
};

export default Exam;


function getInitialAnswers() {
    const storedAnswers = localStorage.getItem('examAnswers');
    if (storedAnswers) {
        const parsedAnswers = JSON.parse(storedAnswers);
        const expirationTime = parsedAnswers.expirationTime;
        if (expirationTime && Date.now() > expirationTime) {
            localStorage.removeItem('examAnswers');
            return {};
        }
        return parsedAnswers.answers;
    }
    return {};
};
