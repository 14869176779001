import api from './api';
import { processResponsePromise } from './utils';

export function getLobbyStatus() {
    try {
        return processResponsePromise(api.get('/users/lobby/status'))
    } catch (err) {
        return null
    }
};
