import React from 'react';
import { Grid, Paper } from '@mui/material';
import CustomSelect from './CustomSelect';
import { isMobile } from '../utils';
import { defaultLanguageParam, defaultParams } from '../utils/examOptions';
// Options
import { allOptions } from '../utils/examOptions'
import { languageCodes, useLanguage } from '../contexts/languageContext';
import { colors } from '../utils/customTheme';

const ExamParameters = ({
    onLevelChange,
    onLanguageChange,
    languageData,
    remixParameters,
}) => {
    const { language } = useLanguage()
    const languageDefaultParam = defaultLanguageParam(language, languageCodes.hebrew)

    return (
        <Paper elevation={0} sx={{ display: 'flex', justifyContent: 'center', margin: 2.7, backgroundColor: 'transparent' }}>
            <Grid
                container
                direction='row'
                spacing={2}
                justifyContent='center'
                alignItems='center'
                padding={2}
                width="100%"
            >
                <Grid item xs={isMobile ? 12 : 4}>
                    <CustomSelect
                        id={languageData?.difficulty_label ?? 'Difficulty'}
                        label={languageData?.difficulty_label ?? 'Difficulty'}
                        value={remixParameters?.difficulty ?? defaultParams.examDifficulty}
                        onChangeHandler={onLevelChange}
                        options={languageData?.levelOptions ?? allOptions.levelOptions}
                    />
                </Grid>
                <Grid item xs={isMobile ? 12 : 4}>
                    <CustomSelect
                        id={languageData?.language_label ?? 'Language'}
                        label={languageData?.language_label ?? 'Language'}
                        value={remixParameters?.language ?? languageDefaultParam}
                        onChangeHandler={onLanguageChange}
                        options={languageData?.languageOptions ?? allOptions.languageOptions}
                    />
                </Grid>
            </Grid>
        </Paper>
    )
};

export default ExamParameters;
