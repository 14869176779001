import React, { useState, useContext } from 'react';
import { Avatar, Menu, MenuItem, ListItemIcon, Link, Divider } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import ChooseLanguageBtn from './ChooseLanguageBtn';
import { logout } from '../requests/auth';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import { EMPTY_STRING } from '../utils';

export default function AvatarProfile({ language, ToggleLanguage, token }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const userContext = useContext(UserContext);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        logout().then(() => {
            userContext.logout();
            navigate('/');
        });
    };

    return (
        <div style={{ margin: 10 }}>
            <Avatar
                onClick={handleClick}
                sx={{ cursor: 'pointer' }}
            >
                {userContext?.user?.fullname?.substring(0, 1).toUpperCase() ?? EMPTY_STRING}
            </Avatar>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleClose}>
                    <ChooseLanguageBtn
                        handleToggleLanguage={() => ToggleLanguage(null, token)}
                        language={language}
                    />
                </MenuItem>
                <Divider />
                {userContext.isPremium() &&
                    <>
                        <MenuItem
                            component={Link}
                            href="https://exam-generator.lemonsqueezy.com/billing"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={handleClose}
                        >
                            <ListItemIcon>
                                <SettingsIcon fontSize="small" />
                            </ListItemIcon>
                            Manage Subscription
                        </MenuItem>
                        <Divider />
                    </>
                }
                <MenuItem
                    component={Link}
                    onClick={() => { handleClose(); handleLogout(); }}
                >
                    <ListItemIcon>
                        <LogoutIcon fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </div>
    );
}
