// OrgForm.js
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { TextField, Button, Container, Typography, Grid, FormControlLabel, Switch } from '@mui/material';
import UploadCSV from './UploadCSV';
import UploadImage from './UploadImage';
import { createOrg, editOrg } from '../../requests/organizations';
import useSnackBar from '../../hooks/useSnackBar'


const OrgForm = ({ org }) => {
    const { openSnackBar, SnackBarComponent } = useSnackBar()
    const [orgState, setOrgState] = useState({
        name: org?.name ?? '',
        manager: org?.manager ?? '',
        total_exams: org?.total_exams,
        logo: org?.logo ?? '',
        whitelist: org?.whitelist ?? [],
    })
    const [formStatus, setFormStatus] = useState('primary');
    const [displayWhitelist, setDisplayWhitelist] = useState(false);
    const uploadImgRef = useRef(null)
    const uploadCsvRef = useRef(null)
    const signupLink = org?._id ? `https://www.exam-generator.com/org/signup?id=${org._id}` : null

    const resetForm = () => {
        setOrgState({ name: '', manager: '', total_exams: '', logo: '', whitelist: '' })
        setFormStatus('primary');
        if (uploadImgRef.current)
            uploadImgRef.current.reset()
        if (uploadCsvRef.current)
            uploadCsvRef.current.reset()
    };

    const AfterCSVUpload = (jsonDataFromCsv) => {
        // todo: validations for json if needed
        setOrgState(prevOrgState => ({ ...prevOrgState, whitelist: jsonDataFromCsv }))

        console.log('whitelist uploaded:', jsonDataFromCsv);
    };

    const AfterImageUpload = (base64String) => {
        setOrgState(prevOrgState => ({ ...prevOrgState, logo: base64String }))
    }

    const handleFormSubmit = (event) => {
        event.preventDefault();

        // Basic validation for required fields
        if (!orgState.name) {
            alert('Please fill in all required fields.');
            return;
        }

        // Avoiding override the current values (if not specified)
        const orgCopy = { ...orgState }
        if (!orgCopy.whitelist)
            delete orgCopy.whitelist
        if (!orgCopy.logo)
            delete orgCopy.logo

        console.log(orgState)

        new Promise((res, rej) => {
            res(
                org?._id
                    ? editOrg(org._id, { ...orgCopy }, openSnackBar)
                    : createOrg(orgCopy, openSnackBar)
            )
        }).finally(() => {
            setFormStatus('success');
            resetForm();
        })
    };

    const toggleDisplyWhitelist = (e) => {
        const checked = e.target.checked
        setDisplayWhitelist(checked)
    }

    return (
        <Container maxWidth="sm" sx={{ mt: 4 }}>
            <Typography variant="h4" align="center" sx={{ mb: 2 }}>
                Organization Form
            </Typography>
            {signupLink && <Typography variant="body1" align="center" sx={{ mb: 2 }}>
                Link for signup: <a href={signupLink} target='_blank'>{signupLink}</a>
            </Typography>}
            <form onSubmit={handleFormSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            label="Org Name"
                            value={orgState.name}
                            onChange={(e) => setOrgState(prevOrg => ({ ...prevOrg, name: e.target.value }))}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Org Manager Email"
                            type="email"
                            value={orgState.manager}
                            onChange={(e) => setOrgState(prevOrg => ({ ...prevOrg, manager: e.target.value }))}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <UploadImage ref={uploadImgRef} onImageUpload={AfterImageUpload} imageBase64={orgState.logo} />
                    </Grid>
                    <Grid item xs={12}>
                        <UploadCSV onFileUpload={AfterCSVUpload} whitelist={org?.whitelist} ref={uploadCsvRef} orgName={org?.name} />
                        <FormControlLabel control={
                            <Switch onChange={toggleDisplyWhitelist} disabled={!orgState?.whitelist || !orgState?.whitelist?.length} />
                        } label="Display whitelist" />
                        {displayWhitelist && orgState?.whitelist?.length &&
                            <div style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                {orgState.whitelist.map(x =>
                                    <Typography variant='body1'>{x.email} -- {x.name}</Typography>
                                )}
                            </div>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color={formStatus} fullWidth>
                            {formStatus === 'success' ? 'Success' : (org?._id ? 'Update Org' : 'Create Org')}
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        {/* Add a button to reset all fields */}
                        <Button variant="outlined" color="secondary" fullWidth onClick={resetForm}>
                            Reset Form
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <SnackBarComponent />
        </Container>
    );
};


OrgForm.propTypes = {
    org: PropTypes.shape({
        name: PropTypes.string,
        manager: PropTypes.string,
        logo: PropTypes.any,
    }),
};

export default OrgForm;

{/* 
 #### Total exams amount ####
<Grid item xs={12}>
    <TextField
        label="Total Exams"
        type="number"
        value={orgState.total_exams}
        onChange={(e) => setOrgState(prevOrg => ({ ...prevOrg, total_exams: e.target.value }))}
        fullWidth
        required
    />
    <Typography variant="body2" color="text.secondary">
        Must be more than 0.
    </Typography>
</Grid> */}