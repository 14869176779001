import React from 'react';
import { Box, Typography, Button, Grid, Container, Paper } from '@mui/material';
import { Assignment, School, Insights, Grade } from '@mui/icons-material';
import LandingHeader from '../Header';
import { isMobile } from '../../../utils';

const ExamsUseCase = () => {
    return (
        <Box>
            <LandingHeader />
            <Container maxWidth="auto" sx={{ mt: 10 }}>
                <Paper elevation={3} sx={{ p: 4 }}>
                    <Grid container spacing={4} alignItems="center">
                        {/* Full Width Column for Introductory Text */}
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                                {!isMobile && <Assignment color='primary' sx={{ fontSize: 40, mr: 1 }} />}
                                <Typography variant="h3" sx={{ flexGrow: 1, fontSize: '35px' }} component="h1" aria-label="Innovative Exam Maker and Assessment Tools">
                                    Innovative Exam Maker and Assessment Tools: Revolutionize Your Exam Processes with AI
                                </Typography>
                            </Box>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                Transform your exam and assessment workflows with our cutting-edge AI-powered tools. Designed to streamline the entire process from exam creation to grading and reporting, our platform enhances efficiency and accuracy. Whether you need to design custom exams, automate grading, or gain detailed insights into student performance, our tools offer a comprehensive solution for modern educational needs.
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                With features like secure online exam environments, real-time feedback, and advanced analytics, our platform is engineered to support educators and institutions in delivering fair, effective, and insightful assessments. Embrace the future of education technology with our innovative solutions tailored to enhance learning and evaluation.
                            </Typography>
                        </Grid>
                        {/* Features and Benefits Column */}
                        <Grid item xs={12}>
                            <Box sx={{ mb: 4 }}>
                                <Typography variant="h3" component="h2" sx={{ fontSize: '35px' }}>
                                    Why Choose Our AI-Powered Exam Platform?
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Assignment color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Efficient Exam Creation</Typography>
                                    </Box>
                                    Create comprehensive and customizable exams quickly with our intuitive exam maker. Our platform allows you to design exams that cater to your specific educational objectives and streamline the process of distributing assessments.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <School color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Automated Test Grading</Typography>
                                    </Box>
                                    Our AI-driven grading system automates the grading process, providing immediate feedback and detailed performance reports. This feature reduces administrative workload and ensures timely, accurate evaluations.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Insights color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Advanced Analytics</Typography>
                                    </Box>
                                    Gain deep insights into student performance through our detailed analytics and exam reports. Our platform helps you identify trends, assess strengths and weaknesses, and make data-driven decisions to improve instructional strategies.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Grade color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Secure Exam Environment</Typography>
                                    </Box>
                                    Ensure academic integrity with our secure online exam environment. Our platform is designed to provide a fair testing process, safeguarding against any form of academic dishonesty and ensuring a trustworthy evaluation process.
                                </Typography>
                            </Box>
                            <Typography variant="h3" component="h2" sx={{ fontSize: '35px' }}>
                                How to Conduct Exams Effectively with Our Platform
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mt: 2, mb: 4 }}>
                                Utilize our AI-powered exam maker to streamline the entire exam process. From designing and distributing exams to automating grading and analyzing results, our platform offers a complete solution to enhance the efficiency and effectiveness of your assessments. Discover how our tools can support your educational goals and improve the overall examination experience.
                            </Typography>
                            <Box>
                                <Button variant="contained" color="primary" href="/signup">
                                    Sign Up for Free
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </Box>
    );
};

export default ExamsUseCase;
