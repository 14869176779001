import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, TextField } from '@mui/material';
import ExamList from './ExamList';
import useHistory from '../../hooks/useHistory';
import { useDebounce } from '../../hooks/useDebounce';

const MergeDialog = ({ isRTL, languageData, open, onClose, onMerge, currentExamId }) => {
    const { history, fetchHistory } = useHistory();
    const [filteredHistory, setFilteredHistory] = useState([]);
    const [selectedExams, setSelectedExams] = useState([]);
    const debouncedSearch = useDebounce(handleChangeSearch, 400);

    useEffect(() => {
        if (open && !history) {
            fetchHistory();
        }
    }, [open]);

    useEffect(() => {
        setFilteredHistory(history)
    }, [history])

    const handleToggleExam = (examId) => {
        setSelectedExams(prevSelectedExams => {
            if (prevSelectedExams.includes(examId)) {
                return prevSelectedExams.filter(id => id !== examId);
            } else {
                return [...prevSelectedExams, examId];
            }
        });
    };

    function handleChangeSearch(e) {
        const value = e.target.value;
        setFilteredHistory(history.filter(exam => exam.title.toLowerCase().includes(value.toLowerCase())));
    }

    const handleMerge = () => {
        // I know onMerge is drilled from above, couldnt think of another way
        setSelectedExams([])
        onMerge(selectedExams.filter(x => x))
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{languageData?.title ?? 'Choose exams to merge'}</DialogTitle>
            <DialogContent>
                <Typography variant="body1" sx={{ marginBottom: '10px' }}>Selected exams will be merged into the current exam in preview page</Typography>
                <TextField type="text" label='Search Exam' fullWidth sx={{ marginBottom: '15px' }} onChange={debouncedSearch} />
                {!history || history.length === 0 ? (
                    <p style={{ padding: '10px' }}>{languageData?.empty_message ?? 'Fetching exams to merge...'}</p>
                ) : (filteredHistory?.length > 0 ? (
                    <ExamList exams={filteredHistory.filter(exam => exam._id != currentExamId)} selectedExams={selectedExams} handleToggleExam={handleToggleExam} />
                ) : (
                    <p style={{ padding: '10px' }}>{'No exams found'}</p>
                ))}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{languageData?.cancel_button ?? 'Cancel'}</Button>
                <Button onClick={handleMerge} variant='contained' color="primary">{languageData?.merge_button ?? 'Merge'}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default MergeDialog;
