import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { requestBlogPostById } from '../../requests/blog';
import { generateFakeViewsAndCovers } from '../../utils/fakeData';
import { isMobile } from '../../utils';

const BlogPost = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const postData = await requestBlogPostById(id);
                setPost(postData);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                navigate('/')
            }
        };

        fetchPost();
    }, [id]);

    const handleBack = () => {
        navigate('/blog');
    };

    return (
        <Box p={3}>
            <Button onClick={handleBack} variant="outlined" color="primary" sx={{ marginBottom: '1rem' }}>
                Back
            </Button>
            {loading ? (
                <Box display="flex" justifyContent="center">
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Typography variant="h4" gutterBottom>
                        {post.title}
                    </Typography>
                    <Box>
                        <Typography variant="subtitle1" gutterBottom>
                            {post.createdAt}
                        </Typography>
                        <Box mb={2}>
                            <img src={post.cover} alt={post.title} style={{ width: isMobile ? '100%' : '30%', height: 'auto' }} />
                        </Box>
                        <Typography variant="body1" gutterBottom>
                            {post.content}
                        </Typography>
                    </Box>
                </>
            )}
        </Box>
    );
};

export default BlogPost;
