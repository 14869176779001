import React from "react";
import MicrosoftLogin from "react-microsoft-login";
import { useUser } from '../hooks/useUser'
import api from '../requests/api'
import { useNavigate } from 'react-router-dom'
import { HttpStatusCode } from 'axios'
import { handleSelectedtierIfExist } from '../utils'
import useHistory from "../hooks/useHistory";
import { handleRedirectAfterAuth } from "../utils/auth";


export default (props) => {
    const navigate = useNavigate()
    const { login } = useUser()
    const historyContext = useHistory()

    const authHandler = async (err, data) => {
        if (!data?.account) {
            return
        }
        const payload = {
            user: {
                email: data.account.username,
                fullname: data.account.name,
            },
            microsoftIdToken: data.idToken
        }
        if (props.tokenData) {
            payload.user.roles = props.tokenData.roles
            payload.user.exams_left = props.tokenData.exams
        }

        try {
            const res = await api.post(`/auth/microsoft`, payload);

            if (res.status === HttpStatusCode.Ok) {
                // Check if the 'Authorization' header is present in the responseW
                handleSelectedtierIfExist()
                login(res.data);
                historyContext.resetHistory()
                handleRedirectAfterAuth(navigate, res?.data)
            }
        } catch (error) {
            console.error('Error making POST request to /auth:', error);
        }
    };
    // TODO: remove this clientId to be injected from env
    return (
        <MicrosoftLogin graphScopes={["openid", "profile", "email"]} clientId={process.env.REACT_APP_MICROSOFT_CLIENT_ID} authCallback={authHandler} />
    );
};