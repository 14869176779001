import React from 'react';
import { Box, Typography, TextField, MenuItem, FormControl, InputLabel, Select } from '@mui/material';
import { colors } from '../../utils/customTheme';

const DetailsInputStep = ({ institutionType, setInstitutionType, institutionName, setInstitutionName, level, setLevel }) => {
    if (institutionType === "Higher Education") {
        setLevel("Academic");
    }
    return (
        <Box sx={{ bgcolor: colors.onboarding, minHeight: '100vh' }}>
            <Typography variant="h5" component="h2" gutterBottom style={{ fontWeight: 'bold' }}>
                Institution Details
            </Typography>
            <FormControl fullWidth margin="normal">
                <InputLabel>Institution Type</InputLabel>
                <Select
                    value={institutionType}
                    onChange={(e) => setInstitutionType(e.target.value)}
                    label="Institution Type"
                    sx={{ backgroundColor: 'white' }}
                >
                    <MenuItem value="K-12 School">K-12 School</MenuItem>
                    <MenuItem value="Government">Government</MenuItem>
                    <MenuItem value="Higher Education">Higher Education (University/College)</MenuItem>
                    <MenuItem value="Bootcamp">Bootcamp</MenuItem>
                </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
                <InputLabel>Education Level</InputLabel>
                <Select
                    value={level}
                    onChange={(e) => setLevel(e.target.value)}
                    label="Level"
                    sx={{ backgroundColor: 'white' }}
                >
                    <MenuItem value="1st Grade">1st Grade</MenuItem>
                    <MenuItem value="2nd Grade">2nd Grade</MenuItem>
                    <MenuItem value="3rd Grade">3rd Grade</MenuItem>
                    <MenuItem value="4th Grade">4th Grade</MenuItem>
                    <MenuItem value="5th Grade">5th Grade</MenuItem>
                    <MenuItem value="6th Grade">6th Grade</MenuItem>
                    <MenuItem value="7th Grade">7th Grade</MenuItem>
                    <MenuItem value="8th Grade">8th Grade</MenuItem>
                    <MenuItem value="9th Grade">9th Grade</MenuItem>
                    <MenuItem value="10th Grade">10th Grade</MenuItem>
                    <MenuItem value="11th Grade">11th Grade</MenuItem>
                    <MenuItem value="12th Grade">12th Grade</MenuItem>
                    <MenuItem value="Academic">Academic</MenuItem>
                </Select>
            </FormControl>
            <TextField
                fullWidth
                label="Institute Name"
                autoFocus
                margin="normal"
                value={institutionName}
                onChange={(e) => setInstitutionName(e.target.value)}
            />
        </Box>
    );
};

export default DetailsInputStep;
