import React, { useMemo } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Box, Grid, CircularProgress } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { isMobile } from '../../utils';
import { DownloadCSVButton } from '../../components/B2B/DownloadCSV';
import { useLanguage } from '../../contexts/languageContext';

const StudentTable = ({ students: responses, selectedStudent, onStudentClick, onRequestReport, reportState }) => {
    const { isRTL } = useLanguage()

    const studentsGradesList = useMemo(() => responses.map(student => ({
        [!isRTL ? 'fullname' : 'שם מלא']: student.student_info.full_name,
        [!isRTL ? 'id' : 'תעודת זהות']: student.student_info.personal_id + '',
        [!isRTL ? 'grade' : 'ציון']: student.grade,
        [!isRTL ? 'email' : 'מייל']: student.email
    })), [responses, isRTL])

    return (
        <Box p={isMobile ? 0 : 3}>
            <Grid container justifyContent="center">
                <Grid item xs={12} sm={10} md={12} lg={12}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Student Name</TableCell>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Grade</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell style={{ minWidth: '225px' }}>Actions</TableCell>
                                    <TableCell align="right" style={{ width: '100px', minWidth: '100px' }}><DownloadCSVButton list={studentsGradesList} filename={isRTL ? "ציונים" : "grades"} /></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {responses.map((response) => (
                                    <TableRow key={response._id} style={{ cursor: 'pointer', backgroundColor: selectedStudent && selectedStudent._id === response._id ? '#e8f3ff' : 'transparent' }}>
                                        <TableCell scope="row" >
                                            <div style={{ maxWidth: '80px', width: 'max-content' }}>
                                                {response?.student_name ?? '---'}
                                            </div>
                                        </TableCell>
                                        <TableCell>{response?.student_info?.personal_id ?? '---'}</TableCell>
                                        <TableCell>{response.grade}</TableCell>
                                        <TableCell>
                                            {response.reviewSubmitted && <CheckCircleIcon style={{ fontSize: 16, marginRight: 4, color: 'lightgreen' }} />}
                                            {response.saveForLater && <AccessTimeIcon style={{ color: 'orange', fontSize: 16, marginRight: 4 }} />}
                                        </TableCell>
                                        <TableCell >
                                            <div style={{ display: 'flex', gap: '10px' }}>
                                                <Button variant="outlined" sx={{ maxHeight: '40px' }} onClick={() => onStudentClick(response)}>{isMobile ? "Review" : "Start a review"}</Button>
                                                <Button variant="outlined" color='secondary' sx={{ maxHeight: '40px' }} onClick={() => onRequestReport(response._id)} disabled={reportState.loading}>
                                                    {reportState.loading && response?._id == reportState?.response_id ? <CircularProgress size={22} color='secondary' /> : "AI Report"}
                                                </Button>
                                            </div>
                                        </TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Box>
    );
};

export default StudentTable;
