import React, { useEffect, useMemo, useState } from 'react';
import { Box, IconButton, Typography, Card, Grid, CardContent, Button } from '@mui/material';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { useLanguage } from '../../contexts/languageContext';
import { useNavigate } from 'react-router-dom';
import { questionsType } from '../../utils/examOptions';
import { isMobile } from '../../utils';

const FrequentlyMistakenQuestions = ({ students, examJson }) => {
    const [topMistakenQuestions, setTopMistakenQuestions] = useState([]);
    const [viewMore, setViewMore] = useState(false);
    const { languageData, isRTL } = useLanguage();
    const navigate = useNavigate();

    const handleRemix = (mistake) => {
        const { focus, questions, difficulty, language, source, skills } = examJson.parameters;

        const isURL = source === 'url';
        const isMath = source === 'math';
        const inputSource = (source && (isURL || isMath)) ? source : 'text';

        const remixData = {
            prompt: mistake ?? '',
            focus: focus ?? '',
            questions: questions ?? '',
            difficulty: difficulty ?? '',
            language: language ?? '',
            skills: skills ?? [],
            source: source ?? '',
        };

        sessionStorage.setItem('remix', JSON.stringify(remixData));
        navigate(`/exam?input=${inputSource}`);
    };

    const topMistakenQuestionsMemoized = useMemo(() => {
        const mistakenCount = {};

        if (!students || !examJson || students.length === 0 || !examJson.questions || examJson.questions.length === 0) {
            return [];
        }

        students.forEach(student => {
            student.answers.forEach(answer => {
                const question = examJson.questions.find(q => q._id === answer.question_id);
                if (!question) return;

                const isMultipleOrFillInType = (question.type === questionsType.multiple || question.type === questionsType.fill_in);
                const isOpenOrGraphType = (question.type === questionsType.open || questionsType.graph);

                if (isMultipleOrFillInType && !question.correctAnswers.includes(answer.answer)) {
                    mistakenCount[answer.question_id] = (mistakenCount[answer.question_id] || 0) + 1;
                } else if (isOpenOrGraphType && (answer.grade < (question.grade * 0.5))) {
                    mistakenCount[answer.question_id] = (mistakenCount[answer.question_id] || 0) + 1;
                }
            });
        });

        if (Object.keys(mistakenCount).length === 0) {
            return [];
        }

        const sortedMistakenQuestions = Object.keys(mistakenCount).sort((a, b) => mistakenCount[b] - mistakenCount[a]);

        return sortedMistakenQuestions.slice(0, 10).map(questionId => ({
            question: examJson.questions.find(question => question._id === questionId),
            mistakes: mistakenCount[questionId]
        }));
    }, [students, examJson]);

    useEffect(() => {
        setTopMistakenQuestions(topMistakenQuestionsMemoized);
    }, [topMistakenQuestionsMemoized]);

    if (!topMistakenQuestions || topMistakenQuestions.length === 0) {
        return null;
    }

    const displayedQuestions = viewMore ? topMistakenQuestions : topMistakenQuestions.slice(0, 3);

    return (
        <Grid container spacing={2} p={isMobile ? 1 : 3}>
            {displayedQuestions.map((mistakenQuestion, index) => (
                <Grid item xs={12} key={index}>
                    <Card>
                        <CardContent >
                            <Box sx={{ display: 'flex', textAlign: isRTL ? 'right' : 'left' }}>
                                <Typography variant="h6">
                                    {languageData?.review?.analytics?.frequenlty_mistaken} {index + 1}
                                    <span style={{ fontSize: 14, marginLeft: 10, color: 'red', fontWeight: '600' }}>
                                        &nbsp; {((mistakenQuestion.mistakes / students.length) * 100).toFixed(2)}%
                                    </span>
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', textAlign: isRTL ? 'right' : 'left' }}>
                                <Typography>
                                    {`${mistakenQuestion.question.question} `}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', textAlign: isRTL ? 'right' : 'left' }}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => handleRemix(mistakenQuestion.question.question)}
                                    sx={{ mt: 2 }}
                                >
                                    {languageData?.review?.analytics?.learn_from_mistakes || 'Learn from mistakes'}
                                </Button>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
            {topMistakenQuestions.length > 3 && <Grid item xs={12}>
                <Button variant="text" color="primary" onClick={() => setViewMore(!viewMore)}>
                    {viewMore ? languageData?.review?.analytics?.view_less : languageData?.review?.analytics?.view_more}
                </Button>
            </Grid>}
        </Grid>
    );
};

export default FrequentlyMistakenQuestions;
