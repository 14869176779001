import React from 'react';
import { Box, Typography, Button, Grid, Container, Paper } from '@mui/material';
import { Chat, Grade, AssignmentTurnedIn } from '@mui/icons-material';
import LandingHeader from '../Header';
import { isMobile } from '../../../utils';

const FeedbackGrades = () => {
    return (
        <Box>
            <LandingHeader />
            <Container maxWidth="auto" sx={{ mt: 10 }}>
                <Paper elevation={3} sx={{ p: 4 }}>
                    <Grid container spacing={4} alignItems="flex-start">
                        {/* Full Width Column for Introductory Text */}
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                                {!isMobile && <Chat color='primary' sx={{ fontSize: 40, mr: 1 }} />}
                                <Typography variant="h3" component="h1" sx={{ flexGrow: 1, fontSize: '35px' }} aria-label="AI-Powered Feedback and Grades for Online Assessments">
                                    Revolutionize Online Assessments with AI-Powered Feedback and Comprehensive Grades
                                </Typography>
                            </Box>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4, lineHeight: 1.6 }}>
                                Discover how our AI-driven feedback and grading system can transform your online assessments. With advanced algorithms and insightful analytics, our platform offers detailed grades, constructive feedback, and a holistic view of student performance. Enhance the learning experience and streamline assessment processes with our AI-powered solutions.
                            </Typography>
                        </Grid>
                        {/* Features and Benefits Column */}
                        <Grid item xs={12}>
                            <Box sx={{ mb: 4 }}>
                                <Typography variant="h3" component="h2" sx={{ fontSize: '35px', mb: 2 }}>
                                    Why Opt for AI in Feedback and Grading?
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mb: 2, lineHeight: 1.6 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Chat color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">AI-Driven Constructive Feedback</Typography>
                                    </Box>
                                    Enhance student learning and development with personalized, AI-generated feedback. Our system provides targeted insights on test performance, helping students understand their mistakes and improve their skills effectively.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mb: 2, lineHeight: 1.6 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Grade color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Detailed Grades and Comprehensive Analytics</Typography>
                                    </Box>
                                    Our AI-powered platform generates detailed grades and in-depth analytics for online assessments. Understand student progress through clear and actionable data, enabling educators to tailor their teaching methods and provide targeted support.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mb: 4, lineHeight: 1.6 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <AssignmentTurnedIn color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Monitor and Track Student Performance</Typography>
                                    </Box>
                                    Track student performance over time with AI-driven insights. Our system allows educators to analyze trends and adjust teaching strategies based on detailed performance metrics and feedback.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box>
                                <Typography variant="h3" component="h2" sx={{ fontSize: '35px', mb: 2 }}>
                                    How to Integrate AI for Effective Feedback and Grading in Online Assessments
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mb: 4, lineHeight: 1.6 }}>
                                    Implement our AI-powered feedback and grading system to elevate your online assessments. Our platform provides personalized feedback, detailed grades, and performance tracking, helping you enhance educational outcomes and streamline assessment processes. Start leveraging advanced AI tools today to improve the effectiveness of your online assessments and support student success.
                                </Typography>
                                <Button variant="contained" color="primary" href="/signup">
                                    Sign Up for Free
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </Box>
    );
};

export default FeedbackGrades;
