import React, { useState } from 'react';
import { Typography, TextField } from '@mui/material';
import Graph from '../../components/Recharts/Graph';
import { useLanguage } from '../../contexts/languageContext';
import { useDebounce } from '../../hooks/useDebounce'

const GraphQuestion = ({ questionId, title, functions, onAnswer }) => {
    const [answer, setAnswer] = useState('');
    const { languageData, isRTL } = useLanguage()
    const debouncedOnAnswer = useDebounce(onAnswer)

    const handleAnswerChange = (e) => {
        const newAnswer = e.target.value
        setAnswer(newAnswer);
        if (newAnswer.trim() !== '')
            debouncedOnAnswer(questionId, newAnswer)
    }

    return (
        <div dir={isRTL ? 'rtl' : 'ltr'}>
            <Graph fnStrings={functions} />
            <Typography variant="h6" gutterBottom>{title}</Typography>
            <TextField
                label={languageData?.quiz?.answer_label ?? ''}
                value={answer}
                onChange={handleAnswerChange}
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                margin="normal"
                error={!answer}
            />
        </div>
    );
};

export default GraphQuestion;
