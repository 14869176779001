import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Button,
  Stack,
  CircularProgress,
  Tooltip,
  Box
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import GoogleIcon from '@mui/icons-material/Google';
import { useGoogleLogin } from '@react-oauth/google';
import { buttonsIds, reportClick } from '../requests/analytics';
import useSnackBar from '../hooks/useSnackBar';
import api from '../requests/api';

// Components
import DownloadPDF from './ExportButtons/DownloadPDF';
import DownloadDocx from './ExportButtons/DownloadDocx';
import DownloadGIFT from './ExportButtons/DownloadGIFT';
import successGif from '../assets/success.gif';
// import OpenFeedbackForm from './OpenFeedbackForm';
// import ShareButton from './Share/ShareButton';

// Utils
import { useUser } from '../hooks/useUser';
import { useLanguage } from '../contexts/languageContext';
import { isMobile } from '../utils';
import GenerateQRButton from './QRCode/GenerateQRButton';

const teacherFeedbackLabel = "משוב על המבחן"
const feedbackLabel = "Feedback"


function ExamDialog({ title = 'Your Exam is ready!', open, onClose, examTitle, id, examJson, GFLink }) {
  const { openSnackBar, SnackBarComponent } = useSnackBar();

  const [isLoading, setIsLoading] = useState(false)
  const [googleFormsBtnData, setGoogleFormsBtnData] = useState({
    link: 'https://docs.google.com/forms/',
    label: '',
  })
  const [googleFormsLabel, setGoogleFormsLabel] = useState('Google Forms')

  const { user } = useUser()
  const { languageData, isRTL } = useLanguage()

  useEffect(() => {
    // If there is GFlink from History
    if (GFLink) {
      setGoogleFormsLabel('Click The Link Below 🔗');
      setGoogleFormsBtnData({ link: GFLink, label: 'View exam in Google Forms' })
    }
    else {
      // Reset GF states here
      setGoogleFormsBtnData({ link: null, label: '' });
      setGoogleFormsLabel('Google Forms');
    }
  }, [id]);


  const handleGoogleFormsPermission = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const access_token = tokenResponse.access_token
      sessionStorage.setItem(
        'tokens',
        JSON.stringify({
          access_token,
          expired: new Date().getTime() + tokenResponse.expires_in * 1000,
        })
      )
      handleGoogleForms(access_token)
    },
    scope: 'https://www.googleapis.com/auth/forms.body',
    flow: 'implicit',
    onError: (err) => console.error(err),
    onNonOAuthError: (err) => console.error(err),
  })

  const handleOpenGoogleForms = (url) => {
    setGoogleFormsBtnData({ link: url, label: 'View exam in Google Forms' })
    window.open(url, '_blank');
  }

  const handleGoogleForms = async (access_token) => {
    setIsLoading(true)
    await api.post(`/exams/${id}/google-forms`, { access_token, title: examTitle, exam_id: id, exam: examJson })
      .then((response) => {
        if (response) {
          const url = response.data
          setGoogleFormsLabel('Click The Link Below 🔗');
          handleOpenGoogleForms(url);
          reportClick(buttonsIds.workedExportGoogleForms);
        }
      })
      .catch((err) => {
        console.error(err)
        return null
      })
    setIsLoading(false)
  }

  function getAccessToken() {
    reportClick(buttonsIds.exportGoogleForms);
    const tokensJson = sessionStorage.getItem('tokens')
    if (!tokensJson) return null
    try {
      const tokens = JSON.parse(tokensJson)
      if (tokens.access_token && tokens.expired > new Date().getTime())
        return tokens.access_token
      else {
        sessionStorage.removeItem('tokens')
        return null
      }
    } catch (err) {
      console.error(err)
      sessionStorage.removeItem('tokens')
      return null
    }
  }

  return (
    < Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth='sm'
      sx={{ textAlign: 'center', height: '100vh', width: '100vw' }
      }
    >
      <DialogContent>
        {/* <img src={successGif} alt='Success' /> */}
        <DialogTitle sx={{ mb: 2, fontSize: '30px', fontWeight: '600' }}>
          {languageData?.exam_dialog?.title ?? title}
        </DialogTitle>
        {user?.exams_left > 0 &&
          <Typography variant='subtitle1'>
            {`${languageData?.exam_dialog?.you_have ?? 'You Have'} 
            ${user?.exams_left} 
            ${languageData?.exam_dialog?.exams_left_text ?? 'Exams Left'}`}
          </Typography>}
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Stack spacing={2}>
            {/* <ShareButton isRTL={isRTL} languageData={languageData?.preview} examId={id} forceDesktopStyle /> */}
            <GenerateQRButton url={`${window.location.origin}/quiz?id=${id}`} />
            <DownloadPDF examId={id} label={languageData?.exan_dialog?.pdf_label ?? 'Preview PDF'} openSnackBar={openSnackBar} />
            <DownloadDocx examId={id} label={languageData?.exam_dialog?.word_label ?? 'Download Word'} openSnackBar={openSnackBar} />
            <Box sx={{ position: 'relative', display: 'inline-flex', alignItems: 'center' }}>
              <DownloadGIFT examId={id} label={languageData?.exam_dialog?.gift_label ?? 'Download GIFT'} openSnackBar={openSnackBar} />
              {!isMobile && (
                <Tooltip title={languageData?.exam_dialog?.gift_tooltip_title ?? "File format that can be uploaded to Moodle"} arrow>
                  <HelpOutlineIcon sx={{ position: 'absolute', right: '-24px', cursor: 'pointer', color: '#FA8011' }} />
                </Tooltip>
              )}
            </Box>
            {/* TODO: export this button to a component called GoogleFormsButton */}
            <Button
              variant='contained'
              startIcon={<GoogleIcon />}
              sx={{ backgroundColor: '#724ABD' }}
              disabled={!!GFLink || !!googleFormsBtnData?.link}
              onClick={() =>
                getAccessToken()
                  ? handleGoogleForms(getAccessToken())
                  : handleGoogleFormsPermission()
              }
            >
              {isLoading ? (
                <CircularProgress size={24} color='inherit' />
              ) : (
                googleFormsLabel
              )}
            </Button>
            <a href={googleFormsBtnData.link} target="_blank" onClick={() => reportClick(buttonsIds.linkToGoogleForms)}>{googleFormsBtnData.label}</a>
          </Stack>
        </DialogActions>
      </DialogContent>
      <SnackBarComponent />
    </Dialog >
  )
}

export default ExamDialog
