import api from './api'
import { saveExam } from './exam'
import { processResponsePromise } from './utils'
import { HttpStatusCode } from 'axios';
import { convertISOToDDMMYYYY } from '../utils/dateHelpers';


export function addExamToHub(examId, subject) {
    return Promise.all([
        addExam(examId),
        addSubjectToExam(examId, subject)
    ])
}

function addExam(examId) {
    return api.post('/hub', { id: examId })
}

function addSubjectToExam(examId, subject) {
    return saveExam(examId, { _id: examId, subject: subject })
}

export function getHub() {
    // TODO: consider create an endpoint to get hub filtered by subject. (when the hub gets too large)
    return processResponsePromise(api.get('/hub'))
}

/**
 * TODO: consider merge this with get-user-for-quiz
 * Requests exam JSON data from the server for the currentUser
 * @param {string} examId - The ID of the exam to request.
 * @returns {Promise<{title: string, filename: string, exam: Array, properties: Object, parameters: Object, _id: string, date: string}>} The exam JSON data.
 */
export const requestExamHub = async (examId) => {
    try {
        const response = await api.get(`/hub/${examId}`);
        if (response.status == HttpStatusCode.Ok) {
            // Change date format:
            if (response.data.date)
                response.data.date = convertISOToDDMMYYYY(response.data.date)
            return response.data;
        } else {
            throw Error(`Could not get exam | status code: ${response.status}`)
        }
    } catch (error) {
        console.error('Error fetching exam JSON:', error);
        throw error;
    }
};

/**
 * Requests to add an upvote for a specific exam by a user.
 * @param {string} userId - The ID of the user adding the upvote.
 * @param {string} examId - The ID of the exam to upvote.
 * @returns {Promise<boolean>} Indicates whether the upvote addition was successful.
 */
export const requestAddUpvote = async (examId) => {
    try {
        const response = await api.post(`/hub/${examId}/upvote`);
        if (response.status === HttpStatusCode.Ok) {
            return true;
        } else {
            throw Error(`Could not add upvote | status code: ${response.status}`);
        }
    } catch (error) {
        console.error('Error adding upvote:', error);
        throw error;
    }
};
