import { Button, CircularProgress, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

const codeRegex = /^\d{6}$/;
const CodeForm = ({ onSubmit, loading }) => {
    const [resetCode, setResetCode] = useState('');
    const [isError, setIsError] = useState(false)

    const handleChange = (e) => {
        setIsError(!codeRegex.test(e.target.value))
        setResetCode(e.target.value)
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        if (isError)
            return
        // Validation logic can be added here
        onSubmit(resetCode);
    };

    return (
        <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '30px' }}>
            <Typography variant='h5' >Code Confirmation</Typography>
            <TextField
                required
                id="outlined-required"
                label="Code"
                placeholder='123456'
                value={resetCode}
                fullWidth
                error={isError}
                type="number"
                onChange={handleChange}
            />
            <Button variant='contained' type="submit" sx={{ display: 'block', m: 'auto', width: '100%' }}>
                {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Submit'}
            </Button>
        </form>
    );
};

export default CodeForm;
