import api from './api';
import { generateFakeViewsAndCovers } from '../utils/fakeData';


// Function to transform response data
const transformResponseData = (responseData) => {
    // Get fake posts data
    const fakeViewsAndCovers = generateFakeViewsAndCovers();

    // Map over the response data and replace view and cover fields
    const transformedData = responseData.map((post, index) => ({
        ...post,
        view: fakeViewsAndCovers[index].view,
        cover: fakeViewsAndCovers[index].cover
    }));

    return transformedData;
};

// Request function with transformation
const requestBlogPosts = async () => {
    try {
        const response = await api.get('/blog');
        const responseData = response.data;

        const transformedData = transformResponseData(responseData);
        return transformedData;
    } catch (error) {
        console.error('Error fetching blog posts:', error);
    }
};



const requestBlogPostById = async (id) => {
    try {
        const response = await api.get(`/blog/posts/${id}`);
        const postData = response.data;

        const transformedData = transformResponseData([postData]); //  todo in future: fix bug - same image for each blog (always index 0)
        return transformedData[0];
    } catch (error) {
        console.error('Error fetching post:', error);
        throw error;
    }
};



const requestAddPost = async (postData) => {
    try {
        const response = await api.post('/blog/posts', postData);
        if (response.status === 201) {
            return response.data;
        } else {
            throw new Error('Failed to add post');
        }
    } catch (error) {
        console.error('Error adding post:', error);
        throw error;
    }
};


export { requestBlogPosts, requestBlogPostById, requestAddPost };
