import React from 'react';
import { Grid, Skeleton } from '@mui/material';

const ExamSkeleton = ({ numberOfQuestions=1 }) => {
  const skeletonItems = Array.from({ length: numberOfQuestions }, (_, index) => (
    <Grid item xs={12} key={index} sx={{m:2}}>
      {/* Title */}
      <Skeleton variant="text" height={100} width="50%" sx={{ marginBottom: '10px' }} />
      {/* Question */}
      <Skeleton variant="text" height={40} width="40%" sx={{ marginBottom: '10px' }} />
      {/* Multiple Choice Options */}
      <Skeleton variant="text" height={30} width="30%" sx={{ marginBottom: '5px' }} />
      <Skeleton variant="text" height={30} width="30%" sx={{ marginBottom: '5px' }} />
      <Skeleton variant="text" height={30} width="30%" sx={{ marginBottom: '5px' }} />
      <Skeleton variant="text" height={30} width="30%" sx={{ marginBottom: '5px' }} />
    </Grid>
  ));

  return <Grid container spacing={2}>{skeletonItems}</Grid>;
};

export default ExamSkeleton;
