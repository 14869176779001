// DashboardContext.js
import React, { createContext, useContext, useState } from 'react';
import { useUser } from '../../hooks/useUser';
import { getAllOrgs, getOrgUsers } from '../../requests/organizations';

const OrgDashboardContext = createContext();

export const OrgDashboardProvider = ({ children }) => {
    const [orgDashboardData, setOrgDashboardData] = useState(null);
    const { isAdmin } = useUser()

    async function fetchOrgDashboardData() {
        if (orgDashboardData) return Promise.resolve(orgDashboardData)
        return isAdmin().then(async isAdminRes => {
            if (isAdminRes)
                return getAllOrgs().then(orgsRes => {
                    setOrgDashboardData(prevData => prevData ? { ...prevData, orgs: orgsRes } : { orgs: orgsRes });
                    return orgsRes;
                }).catch(error => {
                    console.error('Error fetching orgs:', error);
                    throw Error('Error fetching')
                })
            else
                throw Error('User is not admin!')
        })
    };

    async function fetchOrgUsers(orgId) {
        return getOrgUsers(orgId).then(users => {
            if (users && Array.isArray(users)) {
                // Save them in the org
                setOrgDashboardData(prevData =>
                    ({ ...prevData, orgs: prevData.orgs.map(org => org._id === orgId ? ({ ...org, users: users }) : org) })
                )
            }
        })
    }

    return (
        <OrgDashboardContext.Provider value={{ orgDashboardData, fetchOrgDashboardData, fetchOrgUsers }}>
            {children}
        </OrgDashboardContext.Provider>
    );
};

export const useOrgDashboard = () => useContext(OrgDashboardContext);
