import api from './api'

export async function readPDF(file) {
  const formData = new FormData()
  formData.append('pdfFile', file)

  try {
    const response = await api.post('/exports/pdf/read', formData)
    return response
  } catch (error) {
    console.error(error)
    return null
  }
}

export async function requestPDF(examId) {
  const apiUrl = `/exams/${examId}/pdf/download`
  return api.get(apiUrl).then(response => {
    return response.data
  }).catch(e => {
    console.error(e)
    return null
  })
}


export default { readPDF, requestPDF }
