import { useState, useMemo } from 'react';
import ShareIcon from '@mui/icons-material/Share';
import { Button } from '@mui/material';
import ShareDialog from './ShareDialog';
import { useLanguage } from '../../contexts/languageContext';
import { isMobile } from '../../utils';
import { iconButtonStyle } from '../../utils/customTheme';

function ShareButton({
    languageData: previewLanguageData,
    examId,
    forceDesktopStyle = false,
    forceMobileStyle = false,
}) {
    const { isRTL } = useLanguage();
    const [openShareDialog, setOpenShareDialog] = useState(false);

    const iconBtnStyle = useMemo(() => {
        return forceDesktopStyle
            ? iconButtonStyle.desktop(isRTL)
            : (forceMobileStyle
                ? iconButtonStyle.mobile
                : (isMobile
                    ? iconButtonStyle.mobile
                    : iconButtonStyle.desktop(isRTL)));
    }, [forceDesktopStyle, forceMobileStyle, isRTL]);

    const isIcon = !forceMobileStyle && (forceDesktopStyle || !isMobile);

    const handleShareClick = () => {
        setOpenShareDialog(true);
    };

    const handleCloseShareDialog = () => {
        setOpenShareDialog(false);
    };

    return (
        <>
            <Button
                variant={!isIcon ? 'outlined' : 'contained'}
                color='secondary'
                onClick={handleShareClick}
                sx={iconBtnStyle}
                startIcon={!isIcon && <ShareIcon />}
            >
                {isIcon && (previewLanguageData.share_exam ?? "Share")}
            </Button>
            <ShareDialog
                isRTL={isRTL}
                languageData={previewLanguageData?.share_dialog}
                open={openShareDialog}
                onClose={handleCloseShareDialog}
                examId={examId}
            />
        </>
    );
}

export default ShareButton;
