import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Link, Card, Stack, Avatar, Grid, Typography } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'

export default function PostCard({ post }) {
    if (
        !post ||
        !post._id ||
        !post.cover ||
        !post.title ||
        !post.view ||
        !post.createdAt
    ) {
        return null
    }

    const { _id, cover, title, view, createdAt } = post

    return (
        <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            sx={{ minWidth: '-webkit-fill-available' }}>
            <Card>
                <Box
                    sx={{
                        position: 'relative',
                        pt: 'calc(100% * 3 / 4)',
                    }}>
                    {renderCover(title, cover, _id)}
                </Box>

                <Box
                    sx={{
                        p: (theme) => theme.spacing(2),
                    }}>
                    {renderDate(createdAt)}
                    {renderTitle(title, _id)}
                    {renderInfo(view)}
                </Box>
            </Card>
        </Grid>
    )
}

PostCard.propTypes = {
    post: PropTypes.object.isRequired,
}

// Function to render the post title
function renderTitle(title, postId) {
    return (
        <Link
            color='inherit'
            variant='subtitle2'
            underline='hover'
            component={RouterLink}
            to={`/blog/${postId}`}
            sx={{
                height: 'auto',
                overflow: 'hidden',
                WebkitLineClamp: 2,
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
            }}>
            {title}
        </Link>
    )
}

// Function to render the view count
function renderInfo(viewCount) {
    return (
        <Stack
            direction='row'
            flexWrap='wrap'
            spacing={1.5}
            justifyContent='flex-end'
            sx={{
                mt: 3,
                color: 'text.disabled',
            }}>
            <Stack direction='row'>
                <VisibilityIcon sx={{ fontSize: 16, mr: 0.5 }} />
                <Typography variant='caption'>{viewCount}</Typography>
            </Stack>
        </Stack>
    )
}

// Function to render the cover image
function renderCover(title, coverUrl, postId) {
    return (
        <Link
            component={RouterLink}
            to={`/blog/${postId}`}
            sx={{
                textDecoration: 'none',
            }}>
            <Box
                component='img'
                alt={title}
                src={coverUrl}
                sx={{
                    top: 0,
                    width: 1,
                    height: 1,
                    objectFit: 'cover',
                    position: 'absolute',
                }}
            />
        </Link>
    )
}

// Function to render the creation date
function renderDate(createdAt) {
    return (
        <Typography
            variant='caption'
            component='div'
            sx={{
                mb: 2,
                color: 'text.disabled',
            }}>
            {createdAt}
        </Typography>
    )
}
