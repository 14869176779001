import { useLanguage } from "../../contexts/languageContext"
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

function SubmissionDialog({ open, closeDialog, submitExam }) {
    const { languageData, isRTL } = useLanguage();

    return (
        <Dialog open={open} onClose={closeDialog} sx={{ direction: isRTL ? 'rtl' : 'ltr' }}>
            <DialogTitle>{languageData?.quiz?.submit_dialog.title}</DialogTitle>
            <DialogContent>
                <p style={{ marginBottom: '15px' }}><strong>{languageData?.quiz?.submit_dialog.sub_title}</strong></p>
                {languageData?.quiz?.submit_dialog.content}
            </DialogContent>
            <DialogActions sx={{ gap: '8px' }}>
                <Button onClick={submitExam} variant='contained' color='primary'>{languageData?.preview?.submit_label}</Button>
                <Button onClick={closeDialog} variant='contained' color='secondary'>{languageData?.preview?.cancel_label}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default SubmissionDialog;