import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

const PrivacyAndTermsOfService = ({buttonText, operationText=""}) => {
  return (
    <Stack>
      <Typography
        variant="body2"
        textAlign="center"
        sx={{
          opacity: 0.8,
          color: (theme) =>
            theme.palette.mode === "light" ? "#06090A" : "#FFFFFF",
        }}
      >
        By {operationText} &quot;{buttonText}&quot; you agree to our&nbsp;
        <Link href="/tos" color="primary" target="_blank" rel="noopener noreferrer">
          Terms Of Service
        </Link>
        &nbsp;and&nbsp;
        <Link href="/privacy-policy" color="primary" target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </Link>
        .
      </Typography>
    </Stack>
  );
};

export default PrivacyAndTermsOfService;