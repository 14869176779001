import { createContext, useContext, useState } from 'react';
import { getHub } from '../requests/hub';

const hubContext = createContext();

export const useHub = () => {
    const context = useContext(hubContext);
    if (!context) {
        throw new Error('useHub must be used within a HubProvider');
    }
    return context;
};


export const HubProvider = ({ children }) => {
    const [hub, setHub] = useState(null)

    const fetchHub = async () => {
        if (hub) return Promise.resolve(hub)
        return getHub().then(res => {
            // Returns an array of exams in the hub
            if (res) setHub(res);
            return res;
        })
    }

    return (
        <hubContext.Provider value={{ hub, fetchHub }}>
            {children}
        </hubContext.Provider>
    );
}
