import React, { useState, useEffect } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
// MUI
import {
  IconButton,
  Grid,
  Typography,
  Paper,
  Divider,
  TextField,
  Toolbar,
  Alert,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
// import SaveAltIcon from '@mui/icons-material/SaveAlt';
import DeleteIcon from '@mui/icons-material/Delete'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
// Components
import ExamSkeleton from './ExamSkeleton'
import EditableQuestionTypeManager from './EditableQuestionTypeManager'
import ParamsToChips from './ParamsToChips'
import Header from '../../components/Header'
import ExamDialog from '../../components/ExamDialog'
import ErrorMessage from '../../components/ErrorMessage'
import AddQuestionDialog from './AddQuestionDialog'
import PreviewHeader from './PreviewHeader';
// import ModifyQuestionPopover from './ModifyQuestionPopover'
import PreviewButtons from './PreviewButtons'
import { GradeModal } from './GradeModal'
import Feedback from '../../components/Feedback'
// Utils
import { requestExamJson, saveExam } from '../../requests/exam'
import { requestModifyingQuestion, requestAddQuestion } from '../../requests/question'
import ROLES from '../../utils/roles'
import { questionsType, getQuestionByType } from '../../utils/examOptions'
import { generateId, isMobile } from '../../utils'
import { SnackbarTypes } from '../../utils/snackbarTypes';
// Hooks
import { useLocation } from 'react-router-dom'
import { HttpStatusCode } from 'axios'
import { useLanguage } from '../../contexts/languageContext'
import { useUser } from '../../hooks/useUser'
import { useDebounce } from '../../hooks/useDebounce'
import useSnackBar from '../../hooks/useSnackBar';
import useHistory from '../../hooks/useHistory'
import PreviewMenu from './PreviewMenu'
import { colors, paddingPageStyle } from '../../utils/customTheme';
import { useLobby } from '../../contexts/LobbyContext';


const PreviewPage = () => {
  const location = useLocation()
  const { isRTL, languageData } = useLanguage()
  const { user } = useUser()
  const { history, updateExamInHistory } = useHistory()
  const lobbyContext = useLobby();
  const { openSnackBar, SnackBarComponent } = useSnackBar();
  const debouncedSaveChanges = useDebounce(handleSaveChanges, 5000);

  const [examJson, setExamJson] = useState(null)
  const [editableExamJson, setEditableExamJson] = useState(null)
  const [isEditing, setIsEditing] = useState(false)

  const [openExamDialog, setOpenExamDialog] = useState(false)
  const [wasChanged, setWasChanged] = useState(false)

  const [openAddQuestionDialog, setOpenAddQuestionDialog] = useState(false)
  const [newQuestionIndex, setNewQuestionIndex] = useState(null)
  const [modifyingQuestionIndex, setModifyingQuestionIndex] = useState(null);

  const [error, setError] = useState({ display: false, msg: '' })
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCancelChangesInModal = () => {
    setOpenModal(false)
    handleCancelEdit()
  }


  useEffect(() => {
    const fetchExamJson = async () => {
      const examId = new URLSearchParams(location.search).get('id')
      if (!examId) {
        setError({
          display: true,
          msg: languageData.preview?.errors.no_preview,
        })
        return
      }

      try {

        const data = await requestExamJson(examId)
        const fixedExam = fixExamStructure(data)
        setExamJson(fixedExam)
        setEditableExamJson({ ...fixedExam })
        lobbyContext?.createdExam()
      } catch (error) {
        setExamJson(null)
        setEditableExamJson(null)
        setError({
          display: true,
          msg: languageData.preview?.errors?.no_preview ?? "No preview available",
        })
      }
    }

    fetchExamJson()

    // Clean up the error message after the component unmounts
    return () => {
      setError({ display: false, msg: '' })
    }
  }, [])

  const handleAddQuestion = async (questionType) => {
    if (!questionType) {
      setNewQuestionIndex(null)
      return
    }
    const newQuestion = getQuestionByType(questionType)

    // Insert the new question at the specified index
    requestAddQuestion(newQuestion, editableExamJson, examJson?.parameters?.language).then(newQuestion => {
      if (!newQuestion) {
        openSnackBar(SnackbarTypes.ERROR.field);
        return
      }
      if (!newQuestion?._id) newQuestion._id = generateId()
      setEditableExamJson(prevExam => {
        let updatedQuestions = [...prevExam.questions];
        updatedQuestions.splice(newQuestionIndex, 0, newQuestion);

        const newGrade = Math.round(100 / updatedQuestions.length)
        updatedQuestions = updatedQuestions.map(q => ({ ...q, grade: newGrade }))

        return { ...prevExam, questions: updatedQuestions };
      });
      setWasChanged(true);
    }).catch(e => {
      console.error(e);
    }).finally(() => {
      setNewQuestionIndex(null)
    })
  };


  const handleDeleteQuestion = (index) => {
    setEditableExamJson((prevExam) => {
      const updatedQuestions = [...prevExam.questions]
      updatedQuestions.splice(index, 1)
      updateQuestionsGrade(updatedQuestions)
      return { ...prevExam, questions: updatedQuestions }
    })
    setWasChanged(true)
  }

  function updateQuestionsGrade(updatedQuestions) {
    const newGrade = Math.round(100 / updatedQuestions.length)
    updatedQuestions.forEach(q => q.grade = newGrade)
  }
  const handleSubmitModalWithGrade = async () => {
    await saveChanges(editableExamJson)
    setOpenModal(false)
  }
  function getTotalGrade(questions) {
    let totalGrade = 0
    questions.forEach(question => {
      totalGrade += parseFloat(question.grade)
    })
    return parseFloat(totalGrade.toFixed(2))
  }
  async function handleSaveChangesFromEditPage() {
    let totalGrade = 0
    totalGrade = getTotalGrade(editableExamJson.questions)

    if (totalGrade !== 100) {
      handleOpenModal()
    }
    else {
      await saveChanges(editableExamJson)
    }
  }

  async function handleSaveChanges(exam = editableExamJson) {
    await saveChanges(exam)
  }

  async function saveChanges(exam = editableExamJson) {
    if (!exam.title) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setError({
        display: true,
        msg: languageData.preview?.errors.missing_title,
      })
      return Promise.reject(languageData.preview?.errors.missing_title)
    }
    if (hasEmptyFields(exam.questions)) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setError({
        display: true,
        msg: languageData.preview?.errors.missing_field,
      })
      return Promise.reject(languageData.preview?.errors.missing_field)
    }

    try {
      // We only save questions & title. (_id is mandatory for the db operation)
      const examToSave = { questions: exam.questions, title: exam.title, settings: exam?.settings || {} }
      const response = await saveExam(exam._id, examToSave)
      if (response.status === HttpStatusCode.Ok) {
        setExamJson({ ...exam })
        setEditableExamJson({ ...exam })
        updateExamInHistory(exam)
        openSnackBar(SnackbarTypes.SAVED_FOR_LATER_SUCCESS.field);
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setError({
          display: true,
          msg: languageData.preview?.errors.try_again,
        })
      }
    } catch (e) {
      setError({
        display: true,
        msg: languageData.preview?.errors.no_save,
      })
      openSnackBar(SnackbarTypes.SUBMIT_FAILED.field);
      console.error(e)
    }
  }

  const toggleEditingMode = async () => {
    try {
      if (isEditing) {
        if (wasChanged) await handleSaveChangesFromEditPage()
      }
      setIsEditing((prevState) => !prevState)
    } catch (e) {
      return
    }
  }

  const handleExamTitleChange = (e) => {
    setEditableExamJson((exam) => ({ ...exam, title: e.target.value }))
    setWasChanged(true)
  }

  const handleClosedQuestionChange = (
    index,
    newQuestion,
    newOptions,
    newGrade,
    newCorrectAnswers
  ) => {
    setEditableExamJson((prevExam) => {
      const updatedQuestions = prevExam.questions.map((questionItem, i) => {
        if (i === index) {
          return {
            ...questionItem,
            question: newQuestion,
            options: newOptions,
            grade: newGrade,
            correctAnswers: newCorrectAnswers,
          }
        }
        return questionItem
      })
      return {
        ...prevExam,
        questions: updatedQuestions,
      }
    })
    setWasChanged(true)
  }

  const handleOpenQuestionChange = (index, newQuestion, newExplanation, newGrade) => {
    setEditableExamJson((prevExam) => {
      const updatedQuestions = prevExam.questions.map((question, i) => {
        if (i === index) {
          return {
            ...question,
            question: newQuestion,
            explanation: newExplanation,
            grade: newGrade
          }
        }
        return question
      })

      return {
        ...prevExam,
        questions: updatedQuestions,
      }
    })
    setWasChanged(true)
  }

  const handleCancelEdit = (e) => {
    setEditableExamJson(examJson)
    setIsEditing(false)
    setWasChanged(false)
  }

  const handleExportClick = (e) => {
    setOpenExamDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenExamDialog(false)
  }

  const hideMessage = (setMsgState) => {
    return () => setMsgState({ display: false, msg: '' })
  }

  const handleSaveSettings = (newSettings) => {
    try {
      if (editableExamJson) {
        setEditableExamJson((prevExam) => {
          const updatedExam = {
            ...prevExam,
            settings: {
              ...prevExam.settings,
              ...newSettings,
            },
          };

          handleSaveChanges(updatedExam);
          return updatedExam;
        });

        setWasChanged(true);
      }
    } catch (error) {
      console.error('Error saving settings:', error);
      throw error;
    }
  };

  const handleModifyQuestion = (index, optionValue) => {
    setModifyingQuestionIndex(index);
    const question = editableExamJson.questions[index]
    const instruction = optionValue
    const language = examJson?.parameters?.language

    requestModifyingQuestion(question, instruction, language).then(newQuestion => {
      if (!newQuestion) {
        openSnackBar(SnackbarTypes.ERROR.field);
        return
      }
      if (!newQuestion._id) newQuestion._id = question?._id ?? generateId()
      if (!newQuestion.grade) newQuestion.grade = question?.grade
      setEditableExamJson(prevExam => {
        const newQuestions = [...prevExam.questions]
        newQuestions[index] = newQuestion
        return {
          ...prevExam,
          questions: newQuestions
        }
      })
    })
      .finally(() => {
        setModifyingQuestionIndex(null); // Reset the index
        debouncedSaveChanges()
      });
  };
  const handleGradeChanged = (index, newGrade) => {
    setEditableExamJson((prevExam) => {
      const updatedQuestions = prevExam.questions.map((questionItem, i) => {
        if (i === index) {
          return {
            ...questionItem,
            grade: newGrade,
          }
        }
        return questionItem
      })
      return {
        ...prevExam,
        questions: updatedQuestions,
      }
    })
    setWasChanged(true)
  }

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return
    }

    const items = Array.from(editableExamJson.questions)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    setEditableExamJson((prevExam) => ({
      ...prevExam,
      questions: items,
    }))

    if (!isEditing) debouncedSaveChanges();
  }
  const getBloomLevel = (label, languageData) => {
    const matchingEntry = languageData.bloomsQuestions.find(entry => entry.value === label);
    return matchingEntry ? matchingEntry : undefined;
  };

  /**
   * @param {string[]} selectedExams - ids of the exams
   */
  const handleMergeExams = async (selectedExams) => {
    try {
      let mergedQuestions = []; // Initialize merged JSON with data from the first exam

      for (let i = 0; i < selectedExams.length; i++) {
        const examId = selectedExams[i];
        const data = history.find(exam => exam._id === examId);
        mergedQuestions = mergedQuestions.concat(data.questions);
      }

      // Update the editable exam JSON with the merged questions
      setEditableExamJson(prevExam => {
        let updatedQuestions = [...prevExam.questions, ...mergedQuestions];

        const newGrade = Math.round(100 / updatedQuestions.length);
        updatedQuestions = updatedQuestions.map(q => ({ ...q, grade: newGrade }));

        const updatedExam = { ...prevExam, questions: updatedQuestions }
        handleSaveChanges(updatedExam)
        return updatedExam
      });
      setWasChanged(true)
    } catch (error) {
      console.error('Error merging exams:', error);
      throw error;
    }
  };

  if (!examJson || !languageData?.preview) {
    return (
      <>
        <Header />
        {error.display
          ? (<Grid item xs={12}>
            <ErrorMessage onHide={() => { }} isRTL={isRTL} message={error.msg} link='/history' buttonLabel={isRTL ? 'למבחנים שלי' : 'View My Exams'} />
          </Grid>)
          : <ExamSkeleton numberOfQuestions={5} />
        }
      </>
    )
  }

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <PreviewHeader openExport={handleExportClick} examId={examJson?._id} handleSaveSettings={handleSaveSettings} examSettings={examJson?.settings}
        />
        {user?.roles.includes(ROLES.TEACHER.label) && <Feedback />}
        <div dir={isRTL ? 'rtl' : 'ltr'} >
          <Grid container gap={'20px'} style={{ position: 'relative', padding: isMobile ? 0 : 20, ...paddingPageStyle }} >
            <Grid item xs={12} >
              {isMobile ?
                <>
                  <Toolbar sx={{ gap: '10px' }}>
                    <PreviewButtons
                      isEditing={isEditing}
                      isRTL={isRTL}
                      examId={examJson?._id}
                      toggleEditingMode={toggleEditingMode}
                      handleCancelEdit={handleCancelEdit}
                      languageData={languageData}
                      onMerge={handleMergeExams}
                      roles={user?.roles}
                    />
                  </Toolbar>
                  <Divider />
                </>
                :
                <PreviewMenu
                  isEditing={isEditing}
                  isRTL={isRTL}
                  examId={examJson?._id}
                  toggleEditingMode={toggleEditingMode}
                  handleCancelEdit={handleCancelEdit}
                  handleSaveSettings={handleSaveSettings}
                  languageData={languageData?.preview}
                  onMerge={handleMergeExams}
                  roles={user?.roles}
                  examSettings={examJson?.settings}
                />
              }
            </Grid>
            <Grid item xs={12} style={{ padding: '0px 20px', marginBottom: '30px' }}>
              <Paper elevation={0} style={{ padding: isMobile ? 0 : `0 50px 0 50px`, backgroundColor: 'transparent' }}>
                {isEditing ? (
                  <TextField
                    label={languageData?.preview?.exam_title}
                    fullWidth
                    variant='outlined'
                    margin='normal'
                    value={editableExamJson.title}
                    onChange={handleExamTitleChange}
                    error={!editableExamJson.title}
                  />
                ) : (
                  <div>
                    <Typography variant='h5' gutterBottom>
                      {editableExamJson.title}
                    </Typography>
                  </div>
                )}
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '98%' }}>
                  <div>
                    <Typography variant='body1' gutterBottom>
                      {languageData?.preview.total_questions + '  ' + editableExamJson.questions.length}
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                      {languageData.preview?.total_grade + '  ' + getTotalGrade(editableExamJson.questions)}
                    </Typography>
                  </div>
                  {!isEditing && (
                    <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                      {examJson && examJson.parameters && (
                        <ParamsToChips params={examJson.parameters} />
                      )}
                    </div>
                  )}
                </div>

                <Divider style={{ marginBottom: '20px' }} />

                {editableExamJson.questions.length === 0 &&
                  isEditing && (
                    <IconButton
                      onClick={() => {
                        setOpenAddQuestionDialog(true)
                        setNewQuestionIndex(0)
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                  )}

                <Droppable droppableId='exam-questions'>
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {editableExamJson.questions.map((question, index) => (
                        <Draggable key={question?._id} draggableId={question?._id} index={index}>
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.draggableProps} >
                              {isEditing && (
                                <IconButton onClick={() => {
                                  setOpenAddQuestionDialog(true)
                                  setNewQuestionIndex(index)
                                }}>
                                  {examJson && examJson.parameters && <AddIcon />}
                                </IconButton>
                              )}
                              <Paper style={{ padding: '20px 30px', marginBottom: '20px' }} >
                                {(index === modifyingQuestionIndex || index === newQuestionIndex) ? (
                                  <ExamSkeleton />
                                ) : (
                                  <>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                      <div {...provided.dragHandleProps}>
                                        <DragIndicatorIcon
                                          style={{ marginRight: isRTL ? '0px' : '10px', marginLeft: isRTL ? '10px' : '0px', color: '#aaa' }}
                                        />
                                      </div>
                                      <Typography variant="h5" >
                                        {languageData.preview?.question + ' ' + (index + 1) + ' - ' + question.grade + ' ' + languageData.preview?.points_label}
                                      </Typography>
                                      {/* {!isEditing && examJson && examJson.parameters && <ModifyQuestionPopover onSelect={(option) => handleModifyQuestion(index, option)} />} */}
                                      {isEditing &&
                                        <IconButton onClick={() => handleDeleteQuestion(index)}>
                                          <DeleteIcon color="error" />
                                        </IconButton>}
                                    </div>
                                    <EditableQuestionTypeManager
                                      type={question.type}
                                      allQuestionsProps={{
                                        languageData: languageData.preview,
                                        questionIndex: index,
                                        title: question.question,
                                        grade: question.grade,
                                        accuracy: question.accuracy,
                                        level: question.level,
                                        isEditing: isEditing,
                                        bloomLevel: getBloomLevel(question.bloom_level, languageData) ?? question.bloom_level,
                                        onGradeChange: handleGradeChanged,
                                      }}
                                      openQuestionProps={{
                                        explanation: question.explanation,
                                        source: question?.source_link,
                                        onChange: handleOpenQuestionChange,
                                      }}
                                      closeQuestionProps={{
                                        options: question.options,
                                        correctAnswer: question.correctAnswers?.[0],
                                        onChange: handleClosedQuestionChange,
                                      }}
                                      GraphQuestionProps={{
                                        explanation: question.explanation,
                                        functions: question.functions,
                                        onChange: handleOpenQuestionChange,
                                      }}
                                    />
                                  </>
                                )}
                              </Paper>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </Paper>
            </Grid>

            <AddQuestionDialog
              open={openAddQuestionDialog}
              onClose={() => setOpenAddQuestionDialog(false)}
              onAddQuestion={handleAddQuestion}
            />
            <ExamDialog
              open={openExamDialog}
              onClose={handleCloseDialog}
              id={examJson._id}
            />

          </Grid>
          <Alert severity="warning" >
            {languageData.preview?.ai_attention_alert}
          </Alert>
        </div>
      </DragDropContext >
      < GradeModal
        open={openModal}
        onClose={handleCancelChangesInModal}
        onSubmit={handleSubmitModalWithGrade}
        totalGrade={getTotalGrade(editableExamJson.questions)}
        languageData={languageData.preview}
      />
      <SnackBarComponent />
    </div>
  )
}

export default PreviewPage

/**
 * Fixes the id and grade per question (if necessary)
 * @param {{_id: string, title: string, questions: {_id: string, grade: float}[]}} examDocument
 */
function fixExamStructure(examDocument) {
  let saveIsNeeded = false
  const updatedExam = examDocument.questions.map((question) => {
    const newQuestion = { ...question }
    if (!question._id) {
      newQuestion._id = generateId()
      saveIsNeeded = true
    }
    if (!question.grade) {
      newQuestion.grade = Math.round(100 / examDocument.questions.length)
      saveIsNeeded = true
    }
    return newQuestion
  })
  examDocument.questions = updatedExam

  if (saveIsNeeded) {
    const examToSave = { questions: updatedExam }
    saveExam(examDocument._id, examToSave)
  }

  return examDocument
}

function hasEmptyFields(questions) {
  function containsInvalidValues(array) {
    return array.some(
      (value) => value === '' || value === undefined || value === null
    )
  }

  for (const item of questions) {
    if (item.type === questionsType.open || item.type === questionsType.graph) {
      if (!item.question || !item.explanation) {
        return true // Found an item with empty fields
      }
    } else {
      if (
        !item.question ||
        !item.correctAnswers ||
        item.correctAnswers.length === 0 ||
        containsInvalidValues(item.correctAnswers) ||
        !item.options ||
        item.options.length === 0 ||
        containsInvalidValues(item.options)
      )
        return true // Found an item with empty fields
    }
  }
  return false // No items with empty fields found
}