import React from 'react';
import { Box, Typography, Grid, useTheme, useMediaQuery } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import SettingsIcon from '@mui/icons-material/Settings';
import CreateIcon from '@mui/icons-material/Create';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { isMobile } from '../../utils';
import { colors } from '../../utils/customTheme';

const steps = [
    { title: 'Upload Content', icon: <UploadFileIcon />, details: ['Files & Presentations', 'Images & Videos', 'Free text', 'Past exams'] },
    { title: 'Setup', icon: <SettingsIcon />, details: ['Personalization', 'Questions types', 'Study Area', 'Skills to test'] },
    { title: 'Create Exam', icon: <CreateIcon />, details: ['Modify', 'Collaborate', 'Adjust Settings'] },
    { title: 'Take Exam ', icon: <AutoStoriesIcon />, details: ['Auto-Grade', 'Supervision', 'Open ended', 'Responsive'] },
    { title: 'Conclusions Dashboard', icon: <DashboardIcon />, details: ['Common mistakes', 'AI personal report', 'Instant feedback', 'Easy sharing'] },
];

const WorkflowStep = ({ title, icon, details }) => (
    <Grid item xs={12} sm={6} md={2}>
        <Box sx={{ textAlign: 'center', mb: 3 }}>
            <Box sx={{ display: 'inline-block', p: 1.5, backgroundColor: colors.onboarding, borderRadius: '50%' }}>
                {React.cloneElement(icon, { fontSize: 'medium', sx: { color: '#3A5F7D' } })}
            </Box>
            <Typography variant="h6" sx={{ mt: 2, mb: 1, color: 'white' }}>{title}</Typography>
            {details.map((detail, index) => (
                <Typography key={index} variant="body2" sx={{ color: 'white' }}>{detail}</Typography>
            ))}
        </Box>
    </Grid>
);

const WorkflowComponent = () => {
    return (
        <Box mt={10} sx={{ flexGrow: 1, p: 3, marginTop: '90px', backgroundColor: '#131921' }}>
            <Typography variant="h4" align="center" gutterBottom sx={{ p: 3, color: 'white' }}>
                How StudyWise Works
            </Typography>
            <Grid container spacing={isMobile ? 2 : 4} justifyContent="center" alignItems="center">
                {steps.map((step, index) => (
                    <React.Fragment key={index}>
                        <WorkflowStep {...step} />
                        {index < steps.length - 1 && (
                            <Grid item xs={12} sm={6} md={0.4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <ArrowForwardIosIcon sx={{ color: 'white', fontSize: 'medium' }} />
                            </Grid>
                        )}
                    </React.Fragment>
                ))}
            </Grid>
        </Box>
    );
};

export default WorkflowComponent;
