import React, { useEffect, useState } from 'react';
import { HttpStatusCode } from 'axios';
import { Helmet } from 'react-helmet-async';
import { Stack, Avatar, Button, CssBaseline, TextField, Link, Grid, Box, Typography, Container, Divider } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ContinueWithGoogleButton from '../components/GoogleAuth';
import ContinueWithMicrosoftButton from '../components/MicrosoftAuth'
import { useNavigate, useLocation } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { validateEmail, validatePassword } from '../utils/inputValidations';
//hooks:
import useSnackBar from '../hooks/useSnackBar';
import { useUser } from '../hooks/useUser';
import api from '../requests/api';
import { handleRedirectUrlAfterSignIn, handleSelectedtierIfExist } from '../utils';
import { tokensConfig } from './SignUp/tokensConfig';
import useHistory from '../hooks/useHistory';
import PrivacyAndTermsOfService from '../components/PrivacyAndTerms0fService';
import { homepage } from '../components/menu/MenuLinks';
import ROLES from '../utils/roles';
import { SnackbarTypes } from '../utils/snackbarTypes';

export default function SignUp() {
  // Hooks:
  const navigate = useNavigate();
  const userContext = useUser()
  const historyContext = useHistory()
  const location = useLocation();
  const { openSnackBar, SnackBarComponent } = useSnackBar();

  useEffect(() => {
    userContext.isLoggedIn().then(res => {
      if (res) {
        handleSelectedtierIfExist()
        handleRedirectUrlAfterSignIn(navigate, homepage)
      }
    })
  }, [])

  // States:
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [isFormValid, setisFormValid] = useState({ firstName: false, lastName: false, email: false, password: false });

  const queryParams = new URLSearchParams(location.search);
  let tokenData = null
  if (queryParams.get('teacher')) // check if the token is an IL teacher token
    tokenData = tokensConfig.find(tokenObj => tokenObj.name === "IL_TEACHER" && tokenObj.token === queryParams.get('teacher'))
  else if (queryParams.get('token'))
    tokenData = tokensConfig.find(tokenObj => tokenObj.key === 'token' && tokenObj.token === queryParams.get('token'))
  // ################# Form Functions #################
  const handleTextFieldChange = (event) => {
    const { name, value } = event.target;
    let newIsFormValid = { ...isFormValid };

    switch (name) {
      case 'firstName':
        newIsFormValid[name] = value.length > 0;
        break;
      case 'lastName':
        newIsFormValid[name] = value.length > 0;
        break;
      case 'email':
        newIsFormValid[name] = validateEmail(value);
        break;
      case 'password':
        newIsFormValid[name] = value.length >= 6;
        break;
      default:
        break;
    }

    setisFormValid(newIsFormValid);
  };

  const checkValidForm = (obj) => {
    const { firstName, lastName, email, password } = obj
    return firstName && lastName && email && password
  }

  const handleEmailChange = (event) => {
    const input = event.target.value;
    setEmailError(!validateEmail(input));
  };

  const handlePasswordChange = (event) => {
    const input = event.target.value;
    setPasswordError(!validatePassword(input));
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!checkValidForm(isFormValid)) {
      openSnackBar(SnackbarTypes.INVALID_CREDENTIALS.field);
      return;
    }
    setIsLoading(true);
    const data = new FormData(event.currentTarget);
    const payload = {
      user: {
        email: data.get('email'),
        password: data.get('password'),
        firstname: data.get('firstName'),
        lastname: data.get('lastName'),
      }
    }
    if (tokenData) {
      payload.user.roles = tokenData.roles
      payload.user.exams_left = tokenData.exams
    }

    await api.post(`/auth/signup`, payload).then(async response => {
      if (response.status === HttpStatusCode.Ok) {
        handleSelectedtierIfExist();
        historyContext.resetHistory()
        if (tokenData?.roles.includes(ROLES.IL_TEACHER.label)) {
          userContext.login(response.data)
          navigate('/start')
        } else {
          const encoded_email = btoa(data.get('email'));
          navigate(`/validation/stop?e=${encoded_email}`);
        }
      } else
        openSnackBar('ERROR')
    }).catch(err => {
      console.error(err)
      if (err.response.status === HttpStatusCode.Conflict) {
        navigate('/login');
        openSnackBar('CONFLICT')
      } else {
        openSnackBar('ERROR')
      }
    }).finally(() => {
      setIsLoading(false);
    })
  };

  return (
    <>
      <Helmet> <title>Exam Generator | Signup</title> <meta name="description" content={"Join ExamGenerator! Sign up for your free trial and unlock the power of AI-powered exam creation. Generate personalized, high-quality exams in seconds with diverse question types, customizable difficulty, and time-saving features. Focus on teaching, not test creation - Start your free trial now!"} /> </Helmet>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  onChange={handleTextFieldChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                  onChange={handleTextFieldChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  type='email'
                  label="Email Address"
                  name="email"
                  onChange={handleEmailChange}
                  error={emailError}
                  helperText={emailError ? 'Please enter a valid email' : ''}
                  autoComplete="email"
                  onInput={handleTextFieldChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  onInput={handleTextFieldChange}
                  name="password"
                  label="Password"
                  onChange={handlePasswordChange}
                  type="password"
                  error={passwordError}
                  helperText={
                    passwordError
                      ? 'Password must be at least 6 characters long'
                      : ''
                  }
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  {isLoading ? <CircularProgress color='inherit' size={24} /> : "Sign Up"}
                </Button>
                <Link href='forgot-password' variant='body2'>Forgot your password?</Link>
              </Grid>
              <Grid item xs={12}>
                <Divider>or</Divider>
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={2}>
                  <ContinueWithGoogleButton tokenData={tokenData} />
                  <ContinueWithMicrosoftButton tokenData={tokenData} />
                </Stack>
                <Box sx={{ mt: 2 }} />
                <PrivacyAndTermsOfService buttonText={"Signing Up"} />
              </Grid>

              <Grid container justifyContent="flex-end" mt={2}>
                <Grid item>
                  <Link href="login" variant="body2">
                    Already have an account? Log in
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <SnackBarComponent />
      </Container>
    </>
  );
}