import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Grid } from '@mui/material';

function ConvertPptDialog({ open, handleClose }) {
    const handleConversion = () => {
        window.open('https://cloudconvert.com/ppt-to-pptx', '_blank');
        handleClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle>Convert PPT File</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <DialogContentText>
                            The PPT format is old and needs to be converted to PPTX format. Click the button below to convert your file.
                        </DialogContentText>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container spacing={2} justifyContent="flex-end">
                    <Grid item>
                        <Button onClick={handleClose} color="error">
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={handleConversion} color="error" variant="contained">
                            Convert to PPTX
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

export default ConvertPptDialog;
