import React from 'react';
import { Box, Typography, Button, Grid, Container, Paper } from '@mui/material';
import { Assignment, ErrorOutline } from '@mui/icons-material';
import LandingHeader from '../Header';
import { isMobile } from '../../../utils';

const MistakenQuestions = () => {
    return (
        <Box>
            <LandingHeader />
            <Container maxWidth="auto" sx={{ mt: 10 }}>
                <Paper elevation={3} sx={{ p: 4 }}>
                    <Grid container spacing={4} alignItems="center">
                        {/* Full Width Column for Introductory Text */}
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                                {!isMobile && <Assignment color='primary' sx={{ fontSize: 40, mr: 1 }} />}
                                <Typography variant="h3" sx={{ flexGrow: 1, fontSize: '35px' }} component="h1" aria-label="Identify and Correct Mistaken Questions to Improve Test Accuracy">
                                    Identify and Correct Mistaken Questions to Improve Test Accuracy
                                </Typography>
                            </Box>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                Harness the power of advanced AI technology to detect, analyze, and correct errors in your test and exam questions. Our innovative tools are designed to enhance the precision and reliability of your assessments, helping you to deliver fair and accurate evaluations. By leveraging sophisticated algorithms, our platform can identify subtle inaccuracies that may otherwise go unnoticed, ensuring that your assessments effectively measure student understanding and knowledge.
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                With our AI-driven solutions, you can significantly improve the quality of your test questions. Our technology provides detailed insights into common mistakes and offers actionable recommendations to address these issues. This not only helps in refining your assessment materials but also contributes to better learning outcomes for your students by ensuring that they are evaluated on clear and correctly formulated questions.
                            </Typography>
                        </Grid>
                        {/* Features and Benefits Column */}
                        <Grid item xs={12}>
                            <Box sx={{ mb: 4 }}>
                                <Typography variant="h3" component="h2" sx={{ fontSize: '35px' }}>
                                    Why Address Mistaken Questions in Assessments?
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Assignment color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Identify and Fix Mistakes in Test Questions</Typography>
                                    </Box>
                                    Leverage our AI-powered platform to accurately pinpoint common errors in your test questions. By correcting these mistakes, you ensure that your assessments are both fair and effective. This process not only improves the reliability of your tests but also helps maintain high standards in your educational evaluations.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <ErrorOutline color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Enhance Learning Outcomes and Instructional Quality</Typography>
                                    </Box>
                                    Utilize our AI tools to analyze incorrect responses and refine your teaching strategies. By addressing common misconceptions and errors, you can tailor your instructional approach to better meet the needs of your students. This targeted feedback helps improve student comprehension and retention, ultimately leading to more successful learning experiences.
                                </Typography>
                            </Box>
                            <Typography variant="h3" component="h2" sx={{ fontSize: '35px' }}>
                                How to Utilize AI for Effective Mistaken Question Analysis?
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mt: 2, mb: 4 }}>
                                Apply our advanced AI-driven solutions to thoroughly analyze and correct mistakes in your tests and exams. Our platform provides comprehensive analysis capabilities that help you improve your assessment strategies. By identifying and addressing common errors, you can enhance the quality of your educational content, leading to better student performance and more accurate evaluations. Embrace our technology to optimize your assessment processes and elevate the overall quality of education.
                            </Typography>
                            <Box>
                                <Button variant="contained" color="primary" href="/signup">
                                    Sign Up for Free
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </Box>
    );
};

export default MistakenQuestions;
