import api from "./api";

export async function requestDownloadGIFT(examId) {
    let downloadUrl = null;
    let filename = 'exam.gift';
    
    try {
        const url = `/exams/${examId}/gift/download`
        const response = await api({
            method: 'get',
            url: url,
            responseType: 'blob',
        });
        const contentDisposition = response.headers['content-disposition'];
        if (contentDisposition?.includes("UTF-8''")) {
            // Decode filename for non-English chars
            const filenameToDecode = contentDisposition?.split("UTF-8''")[1];
            filename = decodeURIComponent(filenameToDecode);  // Decode filename from Content-Disposition
        } else {
            // Get filename for only English names
            filename = contentDisposition?.split('filename="')[1];
            if (filename === undefined) {
                filename = contentDisposition?.split('filename=')[1];
            }
            if (filename.endsWith('"'))
                filename = filename.slice(0, -1);
        }
        downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
    } finally {
        // Cleanup: Remove the link and revoke the download URL
        if (downloadUrl) {
            window.URL.revokeObjectURL(downloadUrl);
        }
        const linkToRemove = document.querySelector(`[download="${filename}"]`);
        if (linkToRemove) {
            document.body.removeChild(linkToRemove);
        }
    }
}