import React from 'react';
import { Container, Typography, List, ListItem, ListItemText, Box, Divider, Paper } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import AssistantIcon from '@mui/icons-material/Assistant';

const PerformaceReport = ({ reportData, languageData, isRTL }) => {
    if (!reportData) return null; // Handle empty report data
    const discListItemStyle = { listStyleType: 'disc', display: 'list-item', ...(isRTL ? { mr: 4, pr: 1, textAlign: 'right' } : { ml: 4, pl: 1, textAlign: 'left' }) }
    const iconListItemStyle = { gap: '10px', textAlign: isRTL ? 'right' : 'left' }
    const textPadding = { ...(isRTL ? { pr: 3 } : { pl: 3 }) }

    return (
        <Container id="report" component={Paper} elevation={3} sx={{ padding: 3, marginY: 2 }}>
            <Typography variant="h4" component="div" gutterBottom dir={isRTL ? 'rtl' : 'ltr'}>
                {languageData?.title ?? "Performance Report"}
            </Typography>
            <Box dir={isRTL ? 'rtl' : 'ltr'}>
                <Typography variant="h6" >
                    {languageData?.basic_info ?? "Basic Information:"}
                </Typography>
                <List >
                    <ListItem sx={discListItemStyle}>
                        <ListItemText primary={`${languageData?.student_name ?? 'Student Name:'} ${reportData.basicInfo.studentName}`} />
                    </ListItem>
                    <ListItem sx={discListItemStyle}>
                        <ListItemText primary={`${languageData?.exam_title ?? 'Exam Title:'} ${reportData.basicInfo.examTitle}`} />
                    </ListItem>
                    <ListItem sx={discListItemStyle}>
                        <ListItemText primary={`${languageData?.date ?? 'Date:'} ${reportData.basicInfo.date}`} />
                    </ListItem>
                </List>
            </Box>
            {reportData.performance && <>
                <Divider sx={{ marginY: 2 }} />
                <Box dir={isRTL ? 'rtl' : 'ltr'}>
                    <Typography variant="h6" gutterBottom >
                        {languageData?.student_performance ?? "Student Performance:"}
                    </Typography>
                    {reportData?.performance?.contentKnowledge && <>
                        <Typography variant="subtitle1" gutterBottom fontWeight={600}>
                            {languageData?.content_knowledge ?? "Content Knowledge:"}
                        </Typography>
                        <Typography sx={textPadding}>{reportData.performance.contentKnowledge}</Typography>
                    </>}
                    {reportData?.performance?.accuracy && <>
                        <Typography variant="subtitle1" gutterBottom fontWeight={600}>
                            {languageData?.accuracy ?? "Accuracy:"}
                        </Typography>
                        <Typography sx={textPadding}>{reportData.performance.accuracy}</Typography>
                    </>}
                    {reportData.performance.analysis && <>
                        <Typography variant="subtitle1" gutterBottom fontWeight={600}>
                            {languageData?.analysis ?? "Analysis:"}
                        </Typography>
                        <Typography sx={textPadding}>{reportData.performance.analysis}</Typography>
                    </>}
                </Box>
            </>}
            {reportData?.responseBreakdown && <>
                <Divider sx={{ marginY: 2 }} />
                <Box dir={isRTL ? 'rtl' : 'ltr'}>
                    <Typography variant="h6" gutterBottom>
                        {languageData?.response_breakdown ?? "Response Breakdown:"}
                    </Typography>
                    {reportData?.responseBreakdown?.strengths?.length > 0 && <>
                        <Typography variant="subtitle1" fontWeight={600}>
                            {languageData?.strengths ?? "Strengths:"}
                        </Typography>
                        <List>
                            {reportData.responseBreakdown.strengths.map((strength, index) => (
                                <ListItem key={index} sx={iconListItemStyle}>
                                    <CheckIcon color='success' />
                                    <ListItemText primary={strength} />
                                </ListItem>
                            ))}
                        </List>
                    </>}
                    {reportData?.responseBreakdown?.weaknesses?.length > 0 && <>
                        <Typography variant="subtitle1" fontWeight={600}>
                            {languageData?.weaknesses ?? "Weaknesses:"}
                        </Typography>
                        <List>
                            {reportData.responseBreakdown.weaknesses.map((weakness, index) => (
                                <ListItem key={index} sx={iconListItemStyle}>
                                    <ClearIcon color='error' />
                                    <ListItemText primary={weakness} />
                                </ListItem>
                            ))}
                        </List>
                    </>}
                    {reportData?.responseBreakdown?.commonErrors?.length > 0 && <>
                        <Typography variant="subtitle1" fontWeight={600}>
                            {languageData?.common_errors ?? "Common Errors:"}
                        </Typography>
                        <List>
                            {reportData.responseBreakdown.commonErrors.map((error, index) => (
                                <ListItem key={index} sx={iconListItemStyle}>
                                    <ClearIcon color='error' />
                                    <ListItemText primary={error} />
                                </ListItem>
                            ))}
                        </List>
                    </>}
                </Box>
            </>}
            {reportData?.feedback && <>
                <Divider sx={{ marginY: 2 }} />
                <Box dir={isRTL ? 'rtl' : 'ltr'}>
                    <Typography variant="h6" gutterBottom>
                        {languageData?.feedback ?? "Feedback:"}
                    </Typography>
                    {reportData?.feedback?.positiveFeedback?.length > 0 && <>
                        <Typography variant="subtitle1" fontWeight={600}>
                            {languageData?.positive_feedback ?? "Positive Feedback:"}
                        </Typography>
                        <List>
                            {reportData.feedback.positiveFeedback.map((feedback, index) => (
                                <ListItem key={index} sx={discListItemStyle}>
                                    <ListItemText primary={feedback} />
                                </ListItem>
                            ))}
                        </List>
                    </>}
                    {reportData?.feedback?.areasForImprovement?.length > 0 && <>
                        <Typography variant="subtitle1" fontWeight={600}>
                            {languageData?.areas_for_improvement ?? "Areas for Improvement:"}
                        </Typography>
                        <List>
                            {reportData.feedback.areasForImprovement.map((improvement, index) => (
                                <ListItem key={index} sx={discListItemStyle}>
                                    <ListItemText primary={improvement} />
                                </ListItem>
                            ))}
                        </List>
                    </>}
                    {reportData?.feedback?.presentation && <>
                        <Typography variant="subtitle1" gutterBottom fontWeight={600}>
                            {languageData?.presentation ?? "Presentation:"}
                        </Typography>
                        <Typography>{reportData.feedback.presentation}</Typography>
                    </>}
                </Box>
            </>}
            {reportData?.conclusion && <>
                <Divider sx={{ marginY: 2 }} />
                <Box dir={isRTL ? 'rtl' : 'ltr'}>
                    <Typography variant="h6" gutterBottom>
                        {languageData?.conclusion ?? "Conclusion:"}
                    </Typography>
                    {reportData?.conclusion?.summary && <>
                        <Typography variant="subtitle1" gutterBottom fontWeight={600}>
                            {languageData?.summary ?? "Summary:"}
                        </Typography>
                        <Typography sx={textPadding}>{reportData.conclusion.summary}</Typography>
                    </>}
                    {reportData?.conclusion?.recommendations.length > 0 && <>
                        <Typography variant="subtitle1" fontWeight={600}>
                            {languageData?.recommendations ?? "Recommendations:"}
                        </Typography>
                        <List>
                            {reportData.conclusion.recommendations.map((recommendation, index) => (
                                <ListItem key={index} sx={iconListItemStyle}>
                                    <AssistantIcon color="secondary" />
                                    <ListItemText primary={recommendation} dir={isRTL ? 'rtl' : 'ltr'} />
                                </ListItem>
                            ))}
                        </List>
                    </>}
                </Box>
            </>}
        </Container>
    );
};

export default PerformaceReport;