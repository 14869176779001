import { Dialog, DialogTitle, TextField, Button, DialogContent, Grid, Select, MenuItem, InputLabel, FormControl, Typography, Box, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { emailTypes } from "../../utils/emailTypes";

export function CreateEmailTemplateDialog({ open, onSubmit, handleClose, template: templateProp }) {
    const [template, setTemplate] = useState({ title: '', type: '', subject: '', content: '' })

    useEffect(() => {
        if (templateProp) {
            setTemplate(prevTemplate =>
            ({
                _id: templateProp._id,
                title: templateProp.title ?? prevTemplate.title,
                type: templateProp.type ?? prevTemplate.type,
                subject: templateProp.subject ?? prevTemplate.subject,
                content: templateProp.content ?? prevTemplate.content
            }))
        } else {
            setTemplate({ title: '', type: '', subject: '', content: '' })
        }
    }, [templateProp])

    /** @param {Event} e */
    const handleChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setTemplate(prevTemplate => ({ ...prevTemplate, [name]: value }))
    }

    const handleSave = (e) => {
        // validate the template
        const { title, type, subject, content } = template
        if (!title || !type || !subject || !content) {
            console.error('fields are missing');
            return
        }

        onSubmit(template)
        setTemplate({ title: '', type: '', subject: '', content: '' })
    }

    return (
        <Dialog open={open} onClose={handleClose} sx={{ height: '100vh', width: '100vw', '& .MuiDialog-paper': { maxWidth: '750px' } }}>
            <DialogContent>
                <DialogTitle textAlign='center' fontSize={30}>Add an email template</DialogTitle>
                <Grid container rowSpacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="body2" >
                            Insert <b>HTML content</b> to the email's content. <br />
                            Make sure you preview the mail before saving the template. <br /><br />
                            *You can insert variables into the content - for example &#123;user_email&#125;<br />
                            *Make sure it handled in the server!
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label='Email Title' name="title" variant='outlined' fullWidth value={template.title} onChange={handleChange} helperText="This will *NOT* be used in the email sent" />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="Email-template-type">Type</InputLabel>
                            <Select
                                labelId="Email-template-type"
                                name="type"
                                fullWidth
                                value={template.type}
                                onChange={handleChange}
                            >
                                {emailTypes.map((type, i) => (
                                    <MenuItem key={i} value={type.value}>{type.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField name="subject" label='Email Subject' variant='outlined' fullWidth multiline value={template.subject} onChange={handleChange} helperText="This is the email's subject" />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField name="content" label='Email Content' variant='outlined' fullWidth multiline rows={15} value={template.content} onChange={handleChange} helperText="HTML Content only!" FormHelperTextProps={{ sx: { color: 'red', fontWeight: 500 } }} />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={6}>
                                <Button sx={{ width: '90%', margin: 'auto' }} variant="outlined" onClick={handleClose}>Cancel</Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button sx={{ width: '90%', margin: 'auto' }} onClick={handleSave} color='primary' variant="contained">Save</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    {template && template.content &&
                        <Grid item xs={12} mt={5}>
                            <Typography variant="h4" textAlign={'center'}>Preview</Typography>
                            <Paper elevation={3} sx={{ marginTop: '20px' }}
                                dangerouslySetInnerHTML={{ __html: template.content }}
                            />
                        </Grid>
                    }
                </Grid>
            </DialogContent>
        </Dialog >
    )
}