import React from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

const ChipBox = ({ label, text, backgroundColor, color }) => {
    if (!label) {
        return null;
    }

    return (
        <Grid container mt={2} spacing={1}>
            <Grid item xs={12} md={12} lg={12}>
                <Box sx={{ border: '1px solid #ccc', borderRadius: '5px', padding: '8px' }}>
                    <>
                        <Chip
                            icon={<AutoFixHighIcon color={color} sx={{ fontSize: '1rem' }} />}
                            label={label}
                            sx={{
                                backgroundColor: backgroundColor ?? '#E9DBFC',
                                color: color,
                                padding: '8px',
                                '& .MuiChip-label': {
                                    fontWeight: 'bold',
                                    fontSize: '0.9rem',
                                },
                            }}
                        />
                        <Typography variant="body2">{text ?? ""}</Typography>
                    </>
                </Box>
            </Grid>
        </Grid>
    );
};

export default ChipBox;
