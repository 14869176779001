const validFileTypes = [
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-powerpoint',
    'application/pdf',
    'text/plain',
    "application/octet-stream",
]
export function isValidFileType(file) {
    return !file.name.toLowerCase().endsWith('.ppt') && (validFileTypes.includes(file.type) || file.name.toLowerCase().endsWith('.vtt'));
}