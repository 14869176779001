import React from 'react';
import { List, ListItem, ListItemIcon, Avatar, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import GradingIcon from '@mui/icons-material/Grading';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useNavigate } from 'react-router-dom';
import LikeButton from '../../components/Like Button/LikeButton';
import MergeButton from '../../components/Merge/MergeButton';
import ROLES from '../../utils/roles';
import SettingsButton from '../../components/Settings/SettingsButton';
import { colors } from '../../utils/customTheme';

const PreviewMenu = ({ isEditing, isRTL, examId, toggleEditingMode, handleCancelEdit, languageData: previewLanguageData, onMerge, roles }) => {
    const navigate = useNavigate();

    const handleGoToReview = () => {
        navigate(`/review?id=${examId}`);
    }

    return (
        <div style={{
            position: 'fixed',
            maxHeight: 'fit-content',
            top: 200,
            [isRTL ? 'left' : 'right']: -10,
            bottom: 0,
            backgroundColor: 'white',
            width: 'auto',
            [isRTL ? 'borderRight' : 'borderLeft']: '1px solid #ccc',
            borderBottom: '1px solid #ccc',
            borderTop: '1px solid #ccc',
            borderRadius: 10
        }}>
            <List>
                <ListItem button onClick={toggleEditingMode}>
                    <Tooltip title={!isEditing ? previewLanguageData.tooltips.edit : previewLanguageData.save_label} placement="left">
                        <Avatar sx={{ bgcolor: 'transparent', border: '1px solid', borderColor: isEditing ? 'green' : colors.main, color: !isEditing && colors.main }}>
                            {isEditing ? <DoneIcon color="success" /> : <EditIcon />}
                        </Avatar>
                    </Tooltip>
                </ListItem>
                {isEditing && (
                    <ListItem button onClick={handleCancelEdit}>
                        <Tooltip title={previewLanguageData.cancel_label} placement="left">
                            <Avatar sx={{ bgcolor: 'transparent', border: '1px solid red' }}>
                                <CancelOutlinedIcon color="error" />
                            </Avatar>
                        </Tooltip>
                    </ListItem>
                )}
                {!isEditing && (
                    <>
                        <ListItem>
                            <ListItemIcon>
                                <Tooltip title={previewLanguageData.tooltips.merge} placement="left">
                                    <MergeButton isRTL={isRTL} languageData={previewLanguageData} onMerge={onMerge} examId={examId} />
                                </Tooltip>
                            </ListItemIcon>
                        </ListItem>
                        <ListItem button onClick={handleGoToReview}>
                            <Tooltip title={previewLanguageData.tooltips.review} placement="left">
                                <Avatar sx={{ bgcolor: 'transparent', border: '1px solid', borderColor: colors.main, color: colors.main }}>
                                    <GradingIcon />
                                </Avatar>
                            </Tooltip>
                        </ListItem>
                        {roles?.includes(ROLES.TEACHER.label) && (
                            <ListItem>
                                <Tooltip title={previewLanguageData.tooltips.add_to_hub} placement="left">
                                    <ListItemIcon>
                                        <LikeButton examId={examId} forceMobileStyle />
                                    </ListItemIcon>
                                </Tooltip>
                            </ListItem>
                        )}
                    </>
                )}
            </List>
        </div>
    );
}

export default PreviewMenu;
