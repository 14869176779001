/**
 * @param {string} str - a string represntation of date (could be invalid)
 * @returns {boolean} true or false if the date is valid
 */
function isValidDate(str) {
    const date = new Date(str);
    return !isNaN(date.getTime());
}

/**
 * Takes a string represenstation of a *valid* date, convert it to date, then convert it to a string "dd/mm/yyyy" represenstation
 * @param {string} str - A string represenstation of a *valid* date
 * @returns {string} a string "dd/mm/yyyy" represenstation
 */
function convertToDDMMYYYY(str) {
    const date = new Date(str);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
}

/**
 * Takes a 
 * @param {string} str - ISOdate string represenstation 
 * @returns {string} a dd/mm/yyyy string represenstation if the date string param is valid
 */
export function convertISOToDDMMYYYY(str) {
    if (isValidDate(str)) {
        return convertToDDMMYYYY(str);
    } else {
        // Invalid date format
        return '';
    }
}
