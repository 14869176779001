import React, { useEffect } from 'react';
import { Alert } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Stack';
import Box from '@mui/material/Box';

const buttonStyles = {
  textTransform: 'capitalize',
  fontSize: 'small',
  width: 'max-content',
  height: '40px',
  backgroundColor: 'black',
  '&:hover': {
    backgroundColor: '#333',
  },
};

const ErrorMessage = ({ isRTL, message, link, buttonLabel, onHide }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onHide()
    }, 5000); // 5000 milliseconds = 5 seconds

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Box
        sx={{
          p: 1,
          backgroundColor: 'rgb(253, 237, 237)',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Alert severity="error" icon={<ErrorIcon />}>
          <Typography variant="subtitle1">
            <strong>
              &nbsp;&nbsp;{'' + message}
            </strong>
          </Typography>
        </Alert>
        {link && buttonLabel && (
          <Button href={link} variant="contained" sx={buttonStyles}>
            {buttonLabel}
          </Button>
        )}
      </Box>
    </>
  );
};

export default ErrorMessage;
