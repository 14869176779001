import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, Typography, Button } from '@mui/material';
import { useLanguage } from '../contexts/languageContext';

const Feedback = () => {
  const { isRTL } = useLanguage();
  const [open, setOpen] = useState(false);
  const feedbackFormLink = isRTL
    ? 'https://forms.gle/EvjFjMmMriET8YHh7'
    : 'https://forms.gle/29qgLsaipE6WgT9GA';

  const openFeedbackForm = () => {
    window.open(feedbackFormLink, '_blank');
    localStorage.setItem('feedbackGiven', 'true');
    setOpen(false);
  };

  useEffect(() => {
    const feedbackGiven = localStorage.getItem('feedbackGiven');
    if (!feedbackGiven) {
      const timer = setTimeout(() => {
        setOpen(true);
      }, 15000);
      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <Dialog
      sx={{ direction: isRTL ? 'rtl' : 'ltr' }}
      aria-label="feedback"
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogContent>
        <Typography variant="h6">
          {isRTL ? 'נשמח לשמוע משוב' : 'We would love to hear your feedback'}
        </Typography>
        <Typography variant="subtitle1">
          {isRTL
            ? 'לחצו על הכפתור למילוי השאלון כדי לעזור לנו להשתפר.'
            : 'Click the button to fill out the survey and help us improve.'}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={openFeedbackForm}
          style={{ marginTop: '1rem' }}
        >
          {isRTL ? 'מילוי שאלון' : 'Fill out the survey'}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default Feedback;
