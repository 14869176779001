import api from './api';

export const requestFocusPrompt = async (inputText, difficulty, language) => {
    try {
        const response = await api.post('/gen/focus', { input: inputText, difficulty: difficulty, language: language });
        return response.data.focus_prompt;
    } catch (error) {
        console.error('Error generating focus prompt:', error);
        throw error;
    }
};
