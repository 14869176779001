import React from 'react';
import { Grid, Typography } from '@mui/material';

const TermsOfService = () => {
    return (
        <Grid container spacing={2} sx={{ padding: '50px' }}>
            <Grid item xs={12}>
                <Typography variant="h4" align="center" sx={{ fontWeight: "bolder", fontSize: "40px" }}>
                    Terms of Service - StudyWise.io
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                    <p><strong>1. Acceptance of Terms</strong></p>
                    <p>Welcome to StudyWise.io! By using our website and services, you agree to comply with and be bound by these Terms of Service. If you do not agree to these terms, please refrain from using our website.</p>
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                    <p><strong>2. Use of Service</strong></p>
                    <p><strong>2.1. User Accounts:</strong> When you create an account on StudyWise.io, you are responsible for providing accurate and up-to-date information. You are also responsible for maintaining the confidentiality of your account credentials.</p>
                    <p><strong>2.2. Prohibited Activities:</strong></p>
                    <p>a. Violating any applicable laws or regulations.</p>
                    <p>b. Uploading, sharing, or generating content that is illegal, offensive, or violates the rights of others.</p>
                    <p>c. Attempting to gain unauthorized access to our systems or other users' accounts.</p>
                    <p>d. Using StudyWise.io for any commercial or marketing purposes without our prior written consent.</p>
                    <p><strong>2.3. Google Integration:</strong> When you choose to export exams to Google Forms, you grant StudyWise.io access to your Google account for this specific purpose. Your Google account data will only be used for exam export and authentication and will not be accessed or used for any other purpose.</p>
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                    <p><strong>3. Privacy</strong></p>
                    <p>Please review our <a href='/privacy-policy'>Privacy Policy</a> to understand how we collect, use, and protect your personal information.</p>
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                    <p><strong>4. Intellectual Property</strong></p>
                    <p><strong>4.1. Content Ownership:</strong> You retain ownership of the exams and content you generate using StudyWise.io. We do not claim ownership over your content.</p>
                    <p><strong>4.2. License:</strong> By using StudyWise.io, you grant us a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, and distribute your content solely for the purpose of providing our services.</p>
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                    <p><strong>5. Data Security</strong></p>
                    <p>We employ reasonable security measures to protect your information. However, you acknowledge that no data transmission over the internet is completely secure, and we cannot guarantee the security of your data.</p>
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                    <p><strong>6. Termination</strong></p>
                    <p>We reserve the right to terminate or suspend your account at our discretion, without notice, for any violations of these Terms of Service.</p>
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                    <p><strong>7. Changes to Terms</strong></p>
                    <p>We may update these Terms of Service from time to time. You will be notified of significant changes via email or a notice on our website.</p>
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                    <p><strong>8. Contact Information</strong></p>
                    <p>If you have any questions, concerns, or requests regarding these Terms of Service, please contact us at <a href="mailto:support@studywise.io">support@studywise.io</a>.</p>
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                    <p>By using StudyWise.io, you agree to the terms outlined in these Terms of Service. Thank you for using StudyWise.io!</p>
                    <p>StudyWise | <a href="https://studywise.io">www.studywise.io</a></p>
                </Typography>
            </Grid>

        </Grid>
    );
};

export default TermsOfService;
