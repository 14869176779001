import React from 'react';
import { Box, Typography, Button, Grid, Container, Paper } from '@mui/material';
import { Business, Group, Insights, School, Work } from '@mui/icons-material';
import LandingHeader from '../Header';
import { isMobile } from '../../../utils';

const OrganizationsUseCase = () => {
    return (
        <Box>
            <LandingHeader />
            <Container maxWidth="auto" sx={{ mt: 10 }}>
                <Paper elevation={3} sx={{ p: 4 }}>
                    <Grid container spacing={4} alignItems="center">
                        {/* Full Width Column for Introductory Text */}
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                                {!isMobile && <Business color='primary' sx={{ fontSize: 40, mr: 1 }} />}
                                <Typography variant="h3" sx={{ flexGrow: 1, fontSize: '35px' }} component="h1" aria-label="Enhance Your Organization's Training and Assessment with AI-Powered Tools">
                                    Enhance Your Organization's Training and Assessment with Advanced AI-Powered Tools
                                </Typography>
                            </Box>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                Elevate your organization's training and assessment processes with our cutting-edge AI-powered tools designed to revolutionize how you develop, deliver, and evaluate employee training programs. Our advanced platform is engineered to optimize every aspect of employee training, streamline assessments, and enhance performance analysis with unparalleled efficiency. Whether you are looking to build your team’s skills, assess employee performance, or improve your recruitment process, our AI-driven solutions offer the precision and innovation you need to stay ahead in today's competitive landscape.
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                Our comprehensive platform integrates state-of-the-art analytics and automation features to assist you in making data-driven decisions, designing highly effective training programs, and monitoring progress with ease. By leveraging AI, you can transform your organizational training and assessment practices, leading to better employee development, improved performance outcomes, and enhanced productivity. Discover how our AI-powered tools can help you achieve your organizational goals and drive success through intelligent, data-driven insights and recommendations.
                            </Typography>
                        </Grid>
                        {/* Features and Benefits Column */}
                        <Grid item xs={12} md={12}>
                            <Box sx={{ mb: 4 }}>
                                <Typography variant="h3" component="h2" sx={{ fontSize: '35px', display: 'flex', flexDirection: 'column' }}>
                                    Why Choose Our AI-Driven Platform for Organizational Training and Assessments?
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Group color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Advanced Team Development</Typography>
                                    </Box>
                                    Harness the power of our AI-driven tools to create customized assessments and training programs that cater to the unique needs of your team. Our platform enables you to design and implement targeted training initiatives that enhance team skills, knowledge, and overall performance, ensuring that your workforce is equipped to meet organizational challenges and achieve strategic objectives.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Business color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Comprehensive Performance Evaluation</Typography>
                                    </Box>
                                    Utilize our AI-driven analytics to conduct thorough evaluations of employee performance through detailed exams and assessments. Our platform automates the feedback process and tracks productivity metrics, providing you with actionable insights to make informed decisions that drive organizational efficiency and foster continuous improvement.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Insights color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">In-Depth Analytics and Insights</Typography>
                                    </Box>
                                    Gain valuable insights into the effectiveness of your training programs and assessment strategies with our advanced analytics tools. Our platform provides detailed reports and data analysis that guide strategic decisions and enhance training outcomes, helping you to refine your approach and achieve better results.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <School color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Effective Employee Training</Typography>
                                    </Box>
                                    Design and implement impactful training programs that promote employee development and professional growth. Our platform supports the creation of engaging and educational content tailored to your organizational goals, ensuring that your training initiatives are both effective and aligned with your strategic objectives.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Work color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Optimized Recruitment</Typography>
                                    </Box>
                                    Streamline your recruitment process with our automated assessment tools that efficiently identify top candidates. Our platform simplifies candidate evaluations, ensuring that you select the best fit for your organization while enhancing the overall efficiency of your hiring process.
                                </Typography>
                            </Box>
                            <Typography variant="h3" component="h2" sx={{ fontSize: '35px' }}>
                                How to Utilize Our AI-Powered Platform for Organizational Training and Assessments
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mt: 2, mb: 4 }}>
                                Maximize the benefits of our AI-powered platform to revolutionize your organizational training and assessment strategies. Our comprehensive tools enable you to design and manage exams, automate performance evaluations, and leverage advanced analytics for actionable insights. Transform your approach to employee development and performance management, ensuring that your organization remains competitive and achieves its strategic goals through intelligent and effective training solutions.
                            </Typography>
                            <Box>
                                <Button variant="contained" color="primary" href="/signup">
                                    Sign Up for Free
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </Box>
    );
};

export default OrganizationsUseCase;
