import { Button, CircularProgress, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { validateEmail } from '../../utils/inputValidations';

const EmailForm = ({ onSubmit, loading = false }) => {
    const [email, setEmail] = useState('');
    const [isError, setIsError] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isError)
            return
        // Validation logic can be added here
        onSubmit(email);
    };
    const handleChange = (e) => {
        setEmail(e.target.value)
        setIsError(!validateEmail(e.target.value))
    }

    return (
        <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '30px' }}>
            <Typography variant='h5' >Reset Password</Typography>
            <TextField
                required
                id="outlined-required"
                label="Email"
                placeholder='example@domain.com'
                value={email}
                fullWidth
                error={isError}
                onChange={handleChange}
            />
            <Button variant='contained' type="submit" sx={{ display: 'block', m: 'auto', width: '100%' }}>
                {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Submit'}
            </Button>
        </form>
    );
};

export default EmailForm;
