import React, { useState } from 'react';
import { Button, CircularProgress, TextField, Typography } from '@mui/material';
import { validatePassword } from '../../utils/inputValidations';

const NewPasswordForm = ({ onSubmit, loading }) => {
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [isError, setIsError] = useState(false)

    const handlePasswordChange = (e) => {
        setPassword(e.target.value)
        setIsError(!validatePassword(e.target.value))
    }

    const handleSubmitPassword = async (e) => {
        e.preventDefault();
        if (!validatePassword(password) || password !== passwordConfirmation)
            return
        // Send API request to update password for the provided email
        onSubmit(password)
    };

    return (
        <form onSubmit={handleSubmitPassword} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '30px' }}>
            <Typography variant='h5' >Reset Password</Typography>
            <TextField
                required
                id="outlined-required"
                label="New password"
                value={password}
                fullWidth
                onChange={handlePasswordChange}
            />
            <TextField
                required
                id="outlined-required"
                label="Confirm New password"
                value={passwordConfirmation}
                fullWidth
                error={password !== passwordConfirmation}
                onPaste={(e) => { e.preventDefault() }}
                onChange={(e) => setPasswordConfirmation(e.target.value)}
            />
            <Button variant='contained' type="submit" sx={{ display: 'block', m: 'auto', width: '100%' }}>
                {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Submit'}
            </Button>
        </form>
    );
};

export default NewPasswordForm;
