import React from 'react';
import { Box, Typography, Button, Grid, Container, Paper } from '@mui/material';
import { BarChart, TrendingUp, Assessment } from '@mui/icons-material';
import LandingHeader from '../Header';
import { isMobile } from '../../../utils';

const InsightsAnalytics = () => {
    return (
        <Box>
            <LandingHeader />
            <Container maxWidth="auto" sx={{ mt: 10 }}>
                <Paper elevation={3} sx={{ p: 4 }}>
                    <Grid container spacing={4} alignItems="center">
                        {/* Full Width Column for Introductory Text */}
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                                {!isMobile && <BarChart color='primary' sx={{ fontSize: 40, mr: 1 }} />}
                                <Typography variant="h3" sx={{ flexGrow: 1, fontSize: '35px' }} component="h1" aria-label="AI-Enhanced Insights and Analytics for Test Assessment">
                                    Unlock the Full Potential of AI-Enhanced Insights and Analytics for Comprehensive Test Assessments
                                </Typography>
                            </Box>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                Discover the transformative power of AI in optimizing your test assessments with our advanced insights and analytics tools. Our AI-driven solutions provide in-depth analysis and actionable data that help educators and administrators make informed decisions, enhance test effectiveness, and boost overall performance.
                            </Typography>
                        </Grid>
                        {/* Features and Benefits Column */}
                        <Grid item xs={12}>
                            <Box sx={{ mb: 4 }}>
                                <Typography variant="h3" component="h2" sx={{ fontSize: '35px' }}>
                                    Why Integrate AI into Your Insights and Analytics for Test Assessments?
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <BarChart color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Visualize Complex Test Data with Ease</Typography>
                                    </Box>
                                    Our AI tools convert intricate test data into easy-to-understand visualizations, including interactive charts and graphs. This allows educators and administrators to quickly interpret and act upon assessment results, making it simpler to identify trends and areas needing improvement.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <TrendingUp color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Track and Analyze Trends Over Time</Typography>
                                    </Box>
                                    By employing AI-driven analytics, you can monitor and analyze assessment trends over extended periods. This capability helps uncover patterns in student performance, track the effectiveness of teaching methods, and make data-backed adjustments to improve learning outcomes.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Assessment color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Comprehensive Performance Assessment</Typography>
                                    </Box>
                                    Utilize AI to evaluate performance metrics in detail. Our tools enable precise analysis of assessment data, facilitating refined test strategies and enhanced effectiveness in measuring educational success and student progress.
                                </Typography>
                            </Box>
                            <Typography variant="h3" component="h2" sx={{ fontSize: '35px' }}>
                                How to Maximize AI for Effective Test Insights and Analytics?
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mt: 2, mb: 4 }}>
                                Leverage our state-of-the-art AI analytics tools to gain a comprehensive understanding of test assessments. From detailed visualizations and trend tracking to performance analysis and actionable insights, our platform equips you with the information needed to optimize educational strategies and improve overall outcomes.
                            </Typography>
                            <Box>
                                <Button variant="contained" color="primary" href="/signup">
                                    Sign Up for Free
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </Box>
    );
};

export default InsightsAnalytics;
