import React, { useState, useEffect } from 'react';
import BlogView from './blog/view/blog-view';
import { requestBlogPosts } from '../../requests/blog';

export default function BlogPage() {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await requestBlogPosts();
                setPosts(response);
            } catch (error) {
                console.error('Error fetching blog posts:', error);
            }
        };

        fetchPosts();
    }, []);

    return <BlogView posts={posts} />;
}
