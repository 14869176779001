import api from "./api"

async function readTXT(file) {
    const formData = new FormData()
    formData.append('txtFile', file)

    try {
        const response = await api.post('/exports/txt/read', formData)
        return response
    } catch (error) {
        console.error(error)
        return null
    }
}

export default readTXT
