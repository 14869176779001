import { HttpStatusCode } from "axios"
import api from "./api"

export async function getTempToken(data) {
    return api.post('/issue-token', { data }).then(response => {
        if (response.status == HttpStatusCode.Created)
            return response.data.token
        else
            return null
    })
}
