import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Container, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

// Define styled components
const CustomAccordion = styled(Accordion)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    boxShadow: 'none', // Remove elevation or box shadow
    border: `1px solid ${theme.palette.divider}`, // Add border
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    '& .MuiAccordionSummary-content': {
        fontSize: theme.typography.body1.fontSize, // Match font size
    },
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    fontSize: theme.typography.body2.fontSize, // Match font size
}));

const faqs = [
    {
        question: 'How does Exam Generator AI create exams?',
        answer:
            'Exam Generator AI uses artificial intelligence to generate high-quality exams within seconds. You can input any text or file, including PDF and PowerPoint presentations, and the AI will create the exam content for you.',
    },
    {
        question: 'Can I download exams as a PDF?',
        answer:
            'Yes, after creating your AI-generated exams, you can easily download them as free PDF files for offline use or sharing.',
    },
    {
        question: 'Does Exam Generator AI support automatic grading?',
        answer:
            'Yes, Exam Generator AI supports automatic grading for the exams generated. This feature helps save time in evaluating responses and provides quick feedback to users.',
    },
    {
        question: 'How can I integrate exams with Google Forms?',
        answer:
            'Exam Generator AI allows you to seamlessly integrate your exams directly into Google Forms quizzes online. This makes it convenient for both creators and participants to use the platform they are familiar with.',
    },
    {
        question: 'Who can use Exam Generator AI?',
        answer:
            'Exam Generator AI is designed for educators, trainers, and anyone who needs to create exams efficiently. Whether you are in education, corporate training, or other fields, Exam Generator AI simplifies the exam creation process.',
    },
    {
        question: 'Why should I use Exam Generator AI?',
        answer:
            'Exam Generator AI provides a quick and efficient way to create high-quality exams using artificial intelligence. It offers the convenience of automatic grading, PDF download options, and seamless integration with Google Forms, streamlining the entire exam creation and distribution process.',
    },
    {
        question: 'Are there any restrictions on the types of files I can input?',
        answer:
            'No, Exam Generator AI allows you to input various file types, including text, PDF, and PowerPoint presentations. This flexibility ensures that you can easily create exams using the content you already have.',
    },
];

const FAQ = () => {
    return (
        <Container id="faq" style={{ padding: '64px 24px', margin: 'auto', fontFamily: 'Arial, sans-serif' }}>
            <Typography variant="h3" align="center" sx={{ mb: 4 }}>
                Any Questions?
            </Typography>
            {faqs.map((faq, index) => (
                <CustomAccordion key={index}>
                    <CustomAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`faq-panel-${index}`} id={`faq-header-${index}`}>
                        <Typography variant="h6">{faq.question}</Typography>
                    </CustomAccordionSummary>
                    <CustomAccordionDetails>
                        <Typography>{faq.answer}</Typography>
                    </CustomAccordionDetails>
                </CustomAccordion>
            ))}
        </Container>
    );
};

export default FAQ;
