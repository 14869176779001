import React from 'react';
import { Drawer, Box } from '@mui/material';
import VerticalLinearStepper from './VerticalLineStpper';

const StagesMenu = ({ stage }) => {
    return (
        <Drawer anchor="left" open={true} variant="persistent">
            <Box sx={{ padding: '70px 50px 50px 20px', maxWidth: '8px' }}>
                <VerticalLinearStepper activeStep={stage} />
            </Box>
        </Drawer>
    );
};

export default StagesMenu;
