import React from 'react';
import { Grid, Typography } from '@mui/material';

const PrivacyPolicy = () => {
    return (
        <Grid container spacing={2} sx={{ padding: '50px' }}>
            <Grid item xs={12}>
                <Typography variant="h4" align="center" sx={{ fontWeight: "bolder", fontSize: "40px" }}>
                    Privacy Policy - StudyWise.io
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                    <h5 style={{ fontSize: "30px" }}>1. Introduction</h5>
                    <p>Welcome to StudyWise.io! This Privacy Policy is designed to help you understand how we collect, use, store, and share information when you use our website to generate exams and export them to Google Forms. We value your trust and are committed to protecting your privacy. Please take a moment to review this policy and let us know if you have any questions or concerns.</p>
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                    <h5 style={{ fontSize: "30px" }}>2. Information We Collect</h5>
                    <p><strong>2.1. User-Provided Information:</strong> When you use Exam Generator, you may provide us with your name, email address, and other contact details to create and manage your account.</p>
                    <p><strong>2.2. Exam Data:</strong> We collect and store the exams you generate on our platform, including questions, answers, and other relevant data.</p>
                    <p><strong>2.3. Google User Data:</strong> To export exams to Google Forms, Exam Generator may request access to your Google account. This access is solely for exporting exams and requires your explicit consent.</p>
                    <p><strong>2.4. Data Retention Period:</strong> Exams and user data are retained permanently for personalization purposes, and are not subject to deletion. For further details, please refer to our <a href="/data-retention" target="_blank">Data Retention Policy.</a></p>
                    <p><strong>2.5. Third-Party Tools:</strong> We may use third-party tools, such as AI models, to enhance our services and analyze exam data for improvements.</p>
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                    <h5 style={{ fontSize: "30px" }}>3. How We Use Your Information</h5>
                    <p><strong>3.1. Exam Generation:</strong> We use the information you provide to create and customize exams based on your preferences.</p>
                    <p><strong>3.2. Google Integration:</strong> Your Google account data is used exclusively for exporting exams to Google Forms.</p>
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                    <h5 style={{ fontSize: "30px" }}>4. Data Security</h5>
                    <p><strong>4.1. We take data security seriously and employ reasonable measures to safeguard your information. This includes encryption, access controls, and regular security assessments to protect against unauthorized access, disclosure, or alteration of your data.</strong></p>
                    <p><strong>4.2. Explicit Consent:</strong> Before sharing any data with third-party tools, Exam Generator will seek your explicit consent. This consent will be requested through clear and transparent mechanisms within the user interface. You will have the option to grant or deny consent for data sharing with third-party tools. Please review our data usage policy <a href="https://developers.google.com/terms/api-services-user-data-policy">here</a> for more information on how your data is handled when integrating with Google services.</p>
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                    <h5 style={{ fontSize: "30px" }}>5. Sharing Your Information</h5>
                    <p><strong>5.1. We do not sell, trade, or share your personal information with third parties for their marketing purposes. We may share your information with service providers who assist us in delivering our services, but only to the extent necessary for them to perform their functions.</strong></p>
                    <p><strong>5.2. Third-Party Sharing:</strong> Exam Generator does not sell, trade, or share your personal information with third parties for their marketing purposes. However, we may share anonymized and aggregated data with third-party tools, including AI models, to improve and optimize our exam generation services.</p>
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                    <h5 style={{ fontSize: "30px" }}>6. Your Choices</h5>
                    <p>You have the right to control the information you provide to us. You can review, update, or delete your account information at any time. You also have the option to revoke access to your Google account data from our application.</p>
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                    <h5 style={{ fontSize: "30px" }}>7. Changes to this Privacy Policy</h5>
                    <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any significant changes via email or by prominently posting a notice on our website.</p>
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                    <h5 style={{ fontSize: "30px" }}>8. Contact Us</h5>
                    <p>If you have any questions, concerns, or requests regarding this Privacy Policy or how we handle your data, please contact us at <a href="mailto:support@studywise.io">support@studywise.io</a>.</p>
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                    <p>By using StudyWise.io, you agree to the terms outlined in this Privacy Policy. Thank you for trusting us with your information.</p>
                    <p>StudyWise | <a href="https://studywise.io">www.studywise.io</a></p>
                </Typography>
            </Grid>

        </Grid>
    );
};

export default PrivacyPolicy;
