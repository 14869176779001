import { HttpStatusCode } from 'axios';
import api from './api';

export const getAllUsers = async () => {
    const response = await api.get('/users/');
    if (response && response.status === HttpStatusCode.Ok) {
        return response.data;
    }
    return null;
}

export const getAllFeedbacks = async () => {
    const response = await api.get('/analytics/feedbacks');
    if (response && response.status === HttpStatusCode.Ok) {
        return response.data;
    }
    return null;
}

export const getAllClicks = async () => {
    const response = await api.get('/analytics/clicks');
    if (response && response.status === HttpStatusCode.Ok) {
        return response.data;
    }
    return null;
}
export const getAllLogs = async () => {
    const response = await api.get('/analytics/logs');
    if (response && response.status === HttpStatusCode.Ok) {
        return response.data;
    }
    return null;
}
export const getIsAdmin = async (uid) => {
    if (!uid) return false;
    try {
        const response = await api.get(`/users/${uid}/admin-status`);
        if (response && response.status === HttpStatusCode.Ok) {
            return true;
        }
        return false;
    } catch (e) {
        //failed
        return false;
    }
}
export const getDailyAnalytics = async () => {
    const response = await api.get('/analytics/daily');
    if (response && response.status === HttpStatusCode.Ok) {
        return response.data;
    }
    return null;
}

export async function editUser(uid, roles, examsLeft) {
    const response = await api.put(`/users/${uid}`, { roles, exams_left: examsLeft });
    if (response && response.status === HttpStatusCode.Ok) {
        return response.data;
    }
    return null;
}

export async function getUserHistory(uid) {
    // Works only for admins
    return api.get(`/users/${uid}/exams`).then(res => {
        if (res && res.status === HttpStatusCode.Ok) {
            return res.data;
        }
    }).catch(err => {
        console.error(err)
        return null
    })
}
