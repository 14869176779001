import React, { useRef, useState } from 'react'
import Button from '@mui/material/Button'
import { reportClick, buttonsIds } from '../../requests/analytics'
import { requestPDF } from '../../requests/pdf';
import { CircularProgress } from '@mui/material';
// import { isMobile } from '../../utils';

function DownloadPDF({ examId, label, openSnackBar }) {
  const [isLoading, setIsLoading] = useState(false)
  const pdfDataRef = useRef({ data_url: null, filename: null })

  const openPDF = async (data_url, fileName) => {
    if (!data_url)
      return openSnackBar('ERROR')

    const response = await fetch(data_url)
    const blob = await response.blob()

    const blobUrl = URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.href = blobUrl
    link.target = '_blank'
    link.download = fileName?.length ? fileName : 'exam.pdf'

    document.body.appendChild(link)
    link.click()

    // Clean up
    document.body.removeChild(link)
    URL.revokeObjectURL(blobUrl)
  }

  const handleDownload = async () => {
    if (pdfDataRef.current.data_url) {
      const { data_url, filename } = pdfDataRef.current
      return openPDF(data_url, filename)
    }

    setIsLoading(true)
    reportClick(buttonsIds.downloadWord)
    requestPDF(examId).then(pdfData => {
      if (pdfData) {
        pdfDataRef.current = pdfData
        const { data_url, filename } = pdfData
        openPDF(data_url, filename)
      } else {
        openSnackBar('ERROR')
      }
    })
      .catch(e => { openSnackBar('ERROR') })
      .finally(() => { setIsLoading(false) })
  };

  return (
    <Button
      sx={{ marginTop: '15px', textTransform: 'capitalize' }}
      variant='contained'
      color='error'
      onClick={handleDownload}
    >
      {isLoading ? <CircularProgress sx={{ color: 'white' }} /> : label}
    </Button>

  )
}

export default DownloadPDF
