import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../hooks/useUser'; // Adjust the import as necessary
import { useLoader } from '../hooks/useLoader'; // Adjust the import as necessary
import ROLES from '../utils/roles';
import { useLanguage } from '../contexts/languageContext';

const ProtectedRoute = ({ children, premiumRequired = false, specialRoleRequired = false }) => {
    const navigate = useNavigate();
    const { isLoggedIn, user } = useUser();
    const { openLoader, closeLoader } = useLoader();
    const { changeToHebrew } = useLanguage()

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });

        if (!user) {
            openLoader();
            isLoggedIn().then(userRes => {
                closeLoader()
                if (!userRes) {
                    sessionStorage.setItem('redirectUrl', window.location.pathname + window.location.search);
                    navigate('/login');
                } else {
                    checkUserAccess(userRes.roles);
                }
            }).catch(e => {
                sessionStorage.setItem('redirectUrl', window.location.pathname + window.location.search)
                navigate('/login')
            }).finally(() => closeLoader())
        } else {
            checkUserAccess(user.roles);
        }
    }, [window.location.pathname]);

    useEffect(() => {
        if (user?.roles?.some(role => ROLES.IL_TEACHER.label === role)) {
            changeToHebrew()
        }
    }, [user])

    /**
     * Checks the user's access based on the provided roles.
     *
     * @param {string[]} roles - The roles to check against.
     * @returns {void}
     */
    function checkUserAccess(roles) {
        if (premiumRequired && !ROLES.isPremiumRole(roles)) {
            navigate('/pricing');
        } else if (specialRoleRequired && !roles?.some(role => ROLES.specialRoles.includes(role))) {
            navigate('/');
        }
    }

    return user ? (<> {children} </>) : null;

};

export default ProtectedRoute;
