import { useState } from 'react';
import { Tooltip, Chip, TextField, Typography } from '@mui/material';
import { useLanguage } from '../../contexts/languageContext';
import { useDebounce } from '../../hooks/useDebounce';
import { getAccuracyColor, isMobile } from '../../utils';
import BarChartIcon from '@mui/icons-material/BarChart';
import SpeedIcon from '@mui/icons-material/Speed';
import { getBloomsLabel, getBloomsLevel, getBloomLevelColor } from '../../utils/bloom';

const EditableMultipleQuestion = ({ languageData, questionIndex, title, grade, options, correctAnswer, bloomLevel, accuracy, level, isEditing = false, onChange, handleGradeChange, gradeError }) => {
  const [gradeState, setGradeState] = useState(grade);
  const debouncedGradeChange = useDebounce(handleGradeChange, 500);

  const { isRTL } = useLanguage();
  const CHAR_CODE_A_LOWER = 97;
  const CHAR_CODE_ALEF = 1488;
  const getCharacter = (index) => {
    return isRTL ? String.fromCharCode(CHAR_CODE_ALEF + index) : String.fromCharCode(CHAR_CODE_A_LOWER + index)
  };

  const setQuestionTitle = (newQuestion) => {
    onChange(questionIndex, newQuestion, options, grade, [correctAnswer]);
  }
  const setOptions = (newOptions) => {
    onChange(questionIndex, title, newOptions, grade, [correctAnswer]);
  }
  const setQuestionCorrectAnswer = (newCorrectAnswer) => {
    onChange(questionIndex, title, options, grade, [newCorrectAnswer]);
  }

  const handleCurrentGradeChange = (event) => {
    setGradeState(event.target.value);
    debouncedGradeChange(event);
  };



  return (
    <div>
      {isEditing ? (
        <>
          <TextField
            label={languageData.grade_label}
            value={gradeState ?? ""}
            onChange={e => handleCurrentGradeChange(e)}
            fullWidth
            variant="outlined"
            margin="normal"
            error={gradeError}
          />
          <TextField
            label={languageData.question_title}
            value={title}
            onChange={(e) => setQuestionTitle(e.target.value)}
            fullWidth
            variant="outlined"
            margin="normal"
            error={!title}
          />
          {options.map((option, index) => (
            <TextField
              key={index}
              label={`${isRTL ? 'סעיף' : 'Option'} ${getCharacter(index)}.`}
              value={option}
              onChange={(e) => {
                const newOptions = [...options];
                newOptions[index] = e.target.value;
                setOptions(newOptions);
              }}
              fullWidth
              variant="outlined"
              margin="normal"
              error={!option}
            />
          ))}
          <TextField
            label={languageData.correct_answer}
            value={correctAnswer}
            onChange={(e) => setQuestionCorrectAnswer(e.target.value)}
            fullWidth
            variant="outlined"
            margin="normal"
            error={!correctAnswer}
          />
        </>
      ) : (
        <>
          {/* {(level && accuracy) && < Box sx={{ display: 'flex', alignItems: 'center', mt: 1, mb: 1 }}>
            <Tooltip arrow title="Indicates question's difficulty">
              <Chip
                icon={<BarChartIcon />}
                label={`${level}`}
                variant="outlined"
                color={getLevelColor(level)}
                sx={{ fontWeight: 'bold', p: 1, ...(isRTL ? { ml: 2 } : { mr: 2 }) }}
              />
            </Tooltip>
            <Tooltip arrow title="Indicates question's accuracy using AI">
              <Chip
                icon={<SpeedIcon />}
                label={`${accuracy}%`}
                variant="outlined"
                color={getAccuracyColor(accuracy)}
                sx={{ fontWeight: 'bold', p: 1 }}
              />
            </Tooltip>
          </Box>} */}
          {bloomLevel && (
            <Tooltip arrow title="Bloom's Taxonomy Level">
              <Chip
                label={getBloomsLabel(bloomLevel)}
                variant="outlined"
                color={getBloomLevelColor(getBloomsLevel(bloomLevel))}
                sx={{ fontWeight: 'bold', p: 1, ...(isRTL ? { ml: 2 } : { mr: 2 }) }}
              />
            </Tooltip>
          )}
          <Typography variant={isMobile ? "body1" : "h6"} gutterBottom sx={{ fontWeight: "bolder", margin: '10px 0px' }}>
            {title}
          </Typography>
          {options.map((option, index) => (
            <Typography key={index} variant="body1" gutterBottom>
              {`${getCharacter(index)}. ${option}`}
            </Typography>
          ))}
          <Typography variant="body1" sx={{ fontWeight: 'bold' }} gutterBottom>
            <span style={{ color: 'green' }}>{languageData.correct_answer} : </span> {correctAnswer}
          </Typography>

        </>
      )
      }
    </div >
  );
};

export default EditableMultipleQuestion;
