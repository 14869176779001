import React, { useEffect } from 'react';
import { Button, Container, Typography, Paper, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { Person, Assignment, QuestionAnswer } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const ThankYou = ({ examId, responseId, showResults, languageData, isRTL, examAnswers, studentDetails }) => {

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);


  const navigate = useNavigate();

  const handleViewResults = () => {
    sessionStorage.setItem('responseId', responseId);
    sessionStorage.setItem('examId', examId);
    navigate('/results');
  };

  return (
    <div dir={isRTL ? 'rtl' : 'ltr'}>
      <Container maxWidth="md" sx={{ textAlign: 'center', paddingTop: '50px' }}>
        <Typography variant="h4" gutterBottom>
          {languageData.completion_msgs.title}
        </Typography>
        {!showResults && (
          <Typography variant="subtitle1" gutterBottom pb={2}>
            {languageData.completion_msgs.subtitle}
          </Typography>
        )}

        {showResults && (
          <Button variant="contained" color="primary" sx={{ m: 2 }} onClick={handleViewResults}>
            {languageData.completion_msgs.view_results}
          </Button>
        )}

        <Paper elevation={3} sx={{ textAlign: isRTL ? 'right' : 'left', marginBottom: '20px', padding: '20px' }}>
          <Typography variant="h6" gutterBottom>
            {languageData.completion_msgs.personal_info}
          </Typography>
          <List>
            <ListItem sx={{ textAlign: isRTL ? 'right' : 'left' }}>
              <ListItemIcon>
                <Person color="primary" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <span>
                    <Typography component="span" variant="body1" fontWeight="bold">
                      {`${languageData.full_name}:`}
                    </Typography>
                    {` ${studentDetails.fullName}`}
                  </span>
                }
              />
            </ListItem>
            {studentDetails?.id &&<ListItem sx={{ textAlign: isRTL ? 'right' : 'left' }}>
              <ListItemIcon>
                <Assignment color="primary" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <span>
                    <Typography component="span" variant="body1" fontWeight="bold">
                      {`${languageData.id}:`}
                    </Typography>
                    {` ${studentDetails.id}`}
                  </span>
                }
              />
            </ListItem>}
          </List>
        </Paper>

        <Paper elevation={3} sx={{ textAlign: isRTL ? 'right' : 'left', marginBottom: '20px', padding: '20px' }}>
          <Typography variant="h6" gutterBottom>
            {languageData.completion_msgs.exam_answers}
          </Typography>
          <List>
            {examAnswers.map(({ questionId, answer }, index) => (
              <ListItem sx={{ textAlign: isRTL ? 'right' : 'left' }} key={questionId}>
                <ListItemIcon>
                  <QuestionAnswer color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <span>
                      <Typography component="span" variant="body1" fontWeight="bold">
                        {`${languageData.question_label} ${index + 1}:`}
                      </Typography>
                      {` ${answer}`}
                    </span>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      </Container>
    </div>
  );
};

export default ThankYou;
