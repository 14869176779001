import React from 'react';
import { Box, Button, Typography, Card, CardContent, Grid, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

const buttonStyle = {
    mt: 2,
    mb: 2,
    minWidth: '230px',
    minHeight: '50px',
    borderRadius: '10px',
    background: 'linear-gradient(45deg, #1561AC 30%, #186CC0 90%)',
    color: 'white',
    fontSize: '1.1rem',
    fontWeight: 'bold',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19)',
    '&:hover': {
        background: 'linear-gradient(45deg, #186CC0 30%, #1561AC 90%)',
    }
};

const SummaryStep = ({ selectedRole, selectedSubjects, institutionName, onStartFreeTrial, nextBtnLoading }) => {
    return (
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h5" align="center" gutterBottom sx={{ fontWeight: 'bold' }}>Summary</Typography>
            </Grid>

            {selectedRole && (
                <Grid item xs={12} md={6}>
                    <Card sx={{ backgroundColor: '#F9FAFB', borderRadius: 2, boxShadow: 1, p: 2 }}>
                        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}><strong>Role</strong></Typography>
                            <Typography variant="body1">{selectedRole}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            )}

            {selectedSubjects.length > 0 && (
                <Grid item xs={12} md={6}>
                    <Card sx={{ backgroundColor: '#F9FAFB', borderRadius: 2, boxShadow: 1, p: 2 }}>
                        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}><strong>Subjects</strong></Typography>
                            <Typography variant="body1">{selectedSubjects.join(', ')}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            )}

            {institutionName && (
                <Grid item xs={12} md={6}>
                    <Card sx={{ backgroundColor: '#F9FAFB', borderRadius: 2, boxShadow: 1, p: 2 }}>
                        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}><strong>Institution Name</strong></Typography>
                            <Typography variant="body1">{institutionName}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            )}

            <Grid item xs={12}>
                <Box textAlign="center" mt={2} mb={5}>
                    <Button
                        variant="contained"
                        onClick={onStartFreeTrial}
                        sx={buttonStyle}
                        startIcon={!nextBtnLoading && <AutoAwesomeIcon />}
                    >
                        {nextBtnLoading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Click Here To Start'}
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
};

SummaryStep.propTypes = {
    selectedRole: PropTypes.string.isRequired,
    selectedSubjects: PropTypes.arrayOf(PropTypes.string).isRequired,
    institutionType: PropTypes.string.isRequired,
    institutionName: PropTypes.string.isRequired,
    onStartFreeTrial: PropTypes.func.isRequired,
};

export default SummaryStep;
