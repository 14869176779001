import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";

import logoImage from "../assets/logo.png";
import { links, contactUs } from "../utils";
import { useNavigate } from "react-router-dom";
import { websiteLinks } from "../utils/configs";


const logoStyle = {
  width: "40px",
  height: "auto",
  cursor: "pointer",
  borderRadius: "100px",
  margin: "10px",
};

const scrollToSection = (sectionId) => {
  const sectionElement = document.getElementById(sectionId);
  const offset = 128;
  if (sectionElement) {
    const targetScroll = sectionElement.offsetTop - offset;
    sectionElement.scrollIntoView({ behavior: "smooth" });
    window.scrollTo({
      top: targetScroll,
      behavior: "smooth",
    });
  }
};

function Copyright() {
  return (
    <Typography variant="body2" color="#94A6B8" mt={1}>
      {"Copyright © "}
      <Link>StudyWise&nbsp;</Link>
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  const navigate = useNavigate();

  const handleSubmit = () => {
    window.open(websiteLinks.SCHOOL(''));
  };

  return (
    <div id="footer">
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: '#131921',
          color: '#94A6B8',
          alignItems: "center",
          minWidth: '-webkit-fill-available',
          gap: { xs: 4, sm: 6 },
          textAlign: { xs: "center", sm: "left" },
          py: 8
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            width: "100%",
            px: 5,
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 4,
              minWidth: { xs: "100%", sm: "60%" },
            }}
          >
            <Box sx={{ width: { xs: "100%", sm: "60%" } }}>
              <Box sx={{ ml: { xs: "0px", sm: "-15px" } }}>
                <img src={logoImage} style={logoStyle} alt="logo of sitemark" />
              </Box>
              <Typography variant="body1" color="text.secondary" mb={2} sx={{ color: '#94A6B8' }}>
                Special Offer for Teachers and Students!
              </Typography>
              <Stack direction="row" spacing={1} useFlexGap justifyContent={{ xs: "center", sm: "start" }}>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ minWidth: '150px', backgroundColor: '#007bff' }}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Stack>
            </Box>
          </Box>
          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography variant="body1" fontWeight={700} sx={{ color: 'white' }}>
              Product
            </Typography>
            <Link
              color="text.secondary"
              onClick={() => scrollToSection("features")}
              sx={{ color: '#94A6B8' }}
            >
              Features
            </Link>
            <Link
              color="text.secondary"
              onClick={() => scrollToSection("highlights")}
              sx={{ color: '#94A6B8' }}
            >
              Highlights
            </Link>
            <Link
              color="text.secondary"
              onClick={() => window.open('/api-docs', '_blank')}
              target="_blank"
              sx={{ color: '#94A6B8' }}
            >
              API Docs
            </Link>
            <Link
              color="text.secondary"
              onClick={() => scrollToSection("faq")}
              sx={{ color: '#94A6B8' }}
            >
              FAQs
            </Link>
          </Box>
          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography variant="body1" fontWeight={700} sx={{ color: 'white' }}>
              Company
            </Typography>
            <Link color="text.secondary" sx={{ color: '#94A6B8' }} href={links.LINKEDIN} target="_blank">
              About us
            </Link>
            <Link color="text.secondary" sx={{ color: '#94A6B8' }}>
              Careers (soon)
            </Link>
            <Link color="text.secondary" onClick={contactUs} sx={{ color: '#94A6B8' }}>
              Contact
            </Link>
          </Box>
          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography variant="body1" fontWeight={700} sx={{ color: 'white' }}>
              Legal
            </Typography>
            <Link color="text.secondary" href={links.TOS} target="_blank" sx={{ color: '#94A6B8' }}>
              Terms
            </Link>
            <Link color="text.secondary" href={links.PRIVACY} target="_blank" sx={{ color: '#94A6B8' }}>
              Privacy
            </Link>
            <Link color="text.secondary" href={links.DATA} target="_blank" sx={{ color: '#94A6B8' }}>
              Data Retention
            </Link>
            <Link color="text.secondary" href={links.ACCESSIBILITY} target="_blank" sx={{ color: '#94A6B8' }}>
              Accessibility
            </Link>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            pt: { xs: 4, sm: 4 },
            width: "100%",
            borderTop: "1px solid",
            borderColor: "#94A6B8",
            flexDirection: { xs: "column", sm: "row" },
            px: 5,
          }}
        >
          <div>
            <Link color="text.secondary" href={links.PRIVACY} target="_blank" sx={{ color: '#94A6B8' }}>
              Privacy Policy
            </Link>
            <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5, color: '#94A6B8' }}>
              &nbsp;•&nbsp;
            </Typography>
            <Link color="text.secondary" href={links.TOS} target="_blank" sx={{ color: '#94A6B8' }}>
              Terms of Service
            </Link>
            <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5, color: '#94A6B8' }}>
              &nbsp;•&nbsp;
            </Typography>
            <Link color="text.secondary" onClick={contactUs} target="_blank" sx={{ color: '#94A6B8' }}>
              info@studywise.io
            </Link>
            <Copyright />
          </div>
          <Stack
            direction="row"
            justifyContent="center"
            spacing={1}
            useFlexGap
            sx={{
              color: "text.secondary",
            }}
            pt={{ xs: 3, sm: 0 }}
          >
            <IconButton
              color="inherit"
              href={links.INSTAGRAM}
              target="_blank"
              aria-label="Instagram"
              sx={{ alignSelf: "center", color: '#94A6B8' }}
            >
              <InstagramIcon />
            </IconButton>
            <IconButton
              color="inherit"
              href={links.LINKEDIN}
              target="_blank"
              aria-label="LinkedIn"
              sx={{ alignSelf: "center", color: '#94A6B8' }}
            >
              <LinkedInIcon />
            </IconButton>
            <IconButton
              color="inherit"
              href="https://www.youtube.com/@ExamGenerator"
              target="_blank"
              aria-label="YouTube"
              sx={{ alignSelf: "center", color: '#94A6B8' }}
            >
              <YouTubeIcon />
            </IconButton>
          </Stack>
        </Box>
      </Container>
    </div>
  );
}
