// DashboardContext.js
import React, { createContext, useContext, useState } from 'react';
import { useUser } from '../../hooks/useUser';
import { saveEmailTemplate, getAllEmails, getEmailsTemplates, deleteEmailTemplate } from '../../requests/emails';

const EmailsDashboardContext = createContext();

export const EmailsDashboardProvider = ({ children }) => {
    /**@type {[{emails: Array, templates: Array}, Function]} */
    const [emailsDashboardData, setEmailsDashboardData] = useState(null);
    const { isAdmin } = useUser()

    async function fetchEmailsDashboardData() {
        if (emailsDashboardData) return Promise.resolve(emailsDashboardData)
        return isAdmin().then(async isAdminRes => {
            if (isAdminRes)
                return Promise.all([
                    getAllEmails(),
                    getEmailsTemplates()
                ]).then(response => {
                    const [allEmails, templates] = response
                    const newEmailsData = { emails: allEmails, templates: templates }
                    setEmailsDashboardData(newEmailsData);
                    return newEmailsData;
                }).catch(error => {
                    console.error('Error fetching emails:', error);
                    throw Error('Error fetching')
                })
            else
                throw Error('User is not admin!')
        })
    };

    async function saveTemplate(newTemplate) {
        return saveEmailTemplate(newTemplate).then(templateId => {
            if (templateId) {
                if (newTemplate._id) {
                    // template was updated (since it has id field)
                    setEmailsDashboardData(prevData => ({
                        ...prevData,
                        templates: prevData.templates.map(template => template._id === newTemplate._id ? newTemplate : template)
                    }))
                } else {
                    newTemplate._id = templateId
                    setEmailsDashboardData(prevData => ({ ...prevData, templates: [...prevData.templates, newTemplate] }))
                }
            } else {
                throw Error()
            }
        }).catch(e => {
            console.error(`Couldnt create email template. Error - ${e}`)
            return Promise.reject(e)
        })

    }

    async function deleteTemplate(templateId) {
        return deleteEmailTemplate(templateId).then(() => {
            setEmailsDashboardData(prevData => ({ ...prevData, templates: prevData.templates.filter(template => template._id !== templateId) }))
        }).catch(e => {
            console.error(`Couldnt delete email template. Error - ${e}`)
            return Promise.reject(e)
        })
    }

    return (
        <EmailsDashboardContext.Provider value={{ emailsDashboardData, fetchEmailsDashboardData, saveTemplate, deleteTemplate }}>
            {children}
        </EmailsDashboardContext.Provider>
    );
};

export const useEmailsDashboard = () => useContext(EmailsDashboardContext);
