import React from 'react';
import { Stack, Divider } from '@mui/material';
import './assets/css/font-awesome.css';
import './assets/css/templatemo-softy-pinko.css';
// Components
import LandingHeader from './Header';
import WelcomeArea from './WelcomeArea';
import FeaturesSmall from './FeaturesSmall';
import IconRow from './assets/IconRow';
import CounterParallax from './CounterParallax';
import PricingPlans from './PricingPlans';
import Footer from '../../components/Footer';
import FAQ from './FAQ';
import EducationCards from './EducationCards';
import { colors } from '../../utils/customTheme';
import { isMobile } from '../../utils';
import LandingVideo from './LandingVideo';
import WorkflowComponent from './WorkflowComponent';
import QuizCreationComponent from './QuizCreationComponent';
const LandingPage2 = () => {
    return (
        <div style={{ backgroundColor: colors.onboarding }} >
            <LandingHeader />
            <Stack spacing={isMobile ? 0 : 1}>
                <WelcomeArea />
                <IconRow />
                <LandingVideo />
                <FeaturesSmall />
                <QuizCreationComponent />
                {!isMobile && <WorkflowComponent />}
                <EducationCards />
                <CounterParallax />
                <FAQ></FAQ>
                {/* <PricingPlans /> */}
                {/* <TeachersCTA/> */}
                {/* <ContactUs></ContactUs> */}
                <Footer />
            </Stack>

            {/* Include JS files */}
            <script src="./js/jquery-2.1.0.min.js"></script>
            <script src="./js/popper.js"></script>
            <script src="./js/bootstrap.min.js"></script>
            <script src="./js/scrollreveal.min.js"></script>
            <script src="./js/waypoints.min.js"></script>
            <script src="./js/jquery.counterup.min.js"></script>
            <script src="./js/imgfix.min.js"></script>
            <script src="./js/custom.js"></script>
        </div>
    );
};

export default LandingPage2;
