import React from 'react';
import { Box, Typography, Button, Grid, Container, Paper } from '@mui/material';
import { School, AccessTime, Insights } from '@mui/icons-material';
import AcademiaProfessorsImg from '../../../assets/features/professors.jpg'; // Placeholder image
import LandingHeader from '../Header';
import { isMobile } from '../../../utils';

const AcademiaProfessorsUseCase = () => {
    return (
        <Box>
            <LandingHeader />
            <Container maxWidth="auto" sx={{ mt: 10 }}>
                <Paper elevation={3} sx={{ p: 4 }}>
                    <Grid container spacing={4}>
                        {/* Full Width Column for Introductory Text */}
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                                {!isMobile && <School color='primary' sx={{ fontSize: 40, mr: 1 }} />}
                                <Typography 
                                    variant="h3" 
                                    sx={{ flexGrow: 1, fontSize: '35px' }} 
                                    component="h1" 
                                    aria-label="Empower Academia Professors with AI-Driven Assessment Tools"
                                >
                                    Empower Academia Professors with Cutting-Edge AI-Driven Assessment Tools
                                </Typography>
                            </Box>
                            <Typography 
                                variant="body1" 
                                color="textSecondary" 
                                sx={{ mb: 4, lineHeight: 1.6 }}
                            >
                                Transform the way academia professors create, manage, and analyze tests with our advanced AI-powered platform. Designed to streamline the assessment process, our tools help educators develop comprehensive exams, optimize grading workflows, and gain actionable insights into student performance. Enhance your teaching methodologies and focus on delivering high-quality education with our innovative solutions.
                            </Typography>
                        </Grid>

                        {/* Features and Benefits Column */}
                        <Grid item xs={12}>
                            <Box sx={{ mb: 4 }}>
                                <Typography 
                                    variant="h3" 
                                    component="h2" 
                                    sx={{ mb: 2, fontSize: '35px' }}
                                >
                                    Why Academia Professors Should Choose Our AI-Powered Platform
                                </Typography>
                                <Typography 
                                    variant="body1" 
                                    color="textSecondary" 
                                    sx={{ mt: 2, lineHeight: 1.6 }}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <School color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">
                                            Advanced Test Creation
                                        </Typography>
                                    </Box>
                                    Utilize cutting-edge AI technology to design and develop exams and assessments that meet diverse academic needs. Our platform supports the creation of detailed, customized exams that align with various educational standards and objectives.
                                </Typography>
                                <Typography 
                                    variant="body1" 
                                    color="textSecondary" 
                                    sx={{ mt: 2, lineHeight: 1.6 }}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <AccessTime color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">
                                            Efficient Time Management
                                        </Typography>
                                    </Box>
                                    Streamline administrative tasks such as test creation and grading with our intuitive platform. This efficiency allows professors to dedicate more time to teaching, research, and student interaction.
                                </Typography>
                                <Typography 
                                    variant="body1" 
                                    color="textSecondary" 
                                    sx={{ mt: 2, lineHeight: 1.6 }}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Insights color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">
                                            In-Depth Assessment Insights
                                        </Typography>
                                    </Box>
                                    Gain valuable insights into student performance and the effectiveness of your assessments through our comprehensive analytics tools. Our platform provides detailed reports and metrics to help you understand and improve academic outcomes.
                                </Typography>
                            </Box>

                            <Typography 
                                variant="h3" 
                                component="h2" 
                                sx={{ mb: 2, fontSize: '35px' }}
                            >
                                How Academia Professors Can Maximize the Use of Our AI-Powered Platform
                            </Typography>
                            <Typography 
                                variant="body1" 
                                color="textSecondary" 
                                sx={{ mt: 2, mb: 4, lineHeight: 1.6 }}
                            >
                                Leverage our AI-powered platform to revolutionize your exam creation and management processes. Automate grading, optimize assessment strategies, and utilize detailed analytics to enhance your teaching methods and academic assessments. Our tools are designed to support educators in delivering high-quality education while efficiently managing their workload.
                            </Typography>
                            <Box>
                                <Button variant="contained" color="primary" href="/signup">
                                    Sign Up for Free
                                </Button>
                            </Box>
                        </Grid>

                        {/* Right Column (Image) */}
                        {/* <Grid item xs={12} md={6}>
                            <img src={AcademiaProfessorsImg} alt="AI-Driven Tools for Academia Professors" style={{ width: '100%', borderRadius: 8, border: '1px solid black' }} />
                        </Grid> */}
                    </Grid>
                </Paper>
            </Container>
        </Box>
    );
};

export default AcademiaProfessorsUseCase;
