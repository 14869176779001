import React from 'react';
import { Paper, Typography, Box } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

const StudentDetails = ({ student }) => {

  return (
    <Paper elevation={3} style={{ padding: '15px', marginTop: '20px', marginBottom: '20px' }}>
      <Typography variant="h6">
        <Box display="flex" alignItems="center">
          <AccountCircleOutlinedIcon color="primary" sx={{ margin: '4px' }} />
          {student.student_name || student.full_name || ''}
        </Box>
      </Typography>
      <Typography variant="h6">
        <Box display="flex" alignItems="center">
          <TaskAltIcon color="primary" sx={{ margin: '4px' }} />
          {parseInt(student.grade)}/{parseInt(student.maximumGrade ?? 100)}
        </Box>
      </Typography>
      <Typography variant="h6">
        <Box display="flex" alignItems="center">
          <AccessTimeIcon color="primary" sx={{ margin: '4px' }} />
          {student.date}
        </Box>
      </Typography>
    </Paper>
  );
};

export default StudentDetails;
