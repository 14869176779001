// UserContext.js
import React, { createContext, useState } from 'react';
import { getLoggedInUser } from '../requests/auth';
import { getIsAdmin } from '../requests/dashboard';
import ROLES from '../utils/roles';
import '../utils/typedefs/dashboard.js'

const UserContext = createContext();

const UserProvider = ({ children }) => {
  /**@type {[User, Function]} */
  const [user, setUser] = useState(null);
  const login = (user) => {
    setUser(user);
  };

  const logout = () => {
    setUser(null);
  };

  const decrementExamsLeft = () => {
    if (user)
      setUser(prevUser => ({ ...prevUser, exams_left: prevUser.exams_left - 1 }))
  }

  const isPremium = () => {
    if (user)
      return ROLES.isPremiumRole(user?.roles)
    else return false
  }

  const isLoggedIn = async () => {
    if (user && user._id) return Promise.resolve(user)
    return getLoggedInUser().then(user => {
      login(user)
      return user
    }).catch(e => {
      return null
    })
  }

  const isAdmin = async () => {
    let currentUser = user;

    if (!currentUser || !currentUser._id) {
      currentUser = await isLoggedIn();
    }

    if (currentUser && currentUser.is_admin !== undefined) {
      return Promise.resolve(currentUser.is_admin);
    }
    if (currentUser && currentUser._id) {
      try {
        const result = await getIsAdmin(currentUser._id);
        setUser(prevUser => {
          if (prevUser) {
            return { ...prevUser, is_admin: !!result };
          }
          return { is_admin: !!result };
        });
        return result;
      } catch (error) {
        return false;
      }
    }

    return false;
  }

  return (
    <UserContext.Provider value={{ user, login, logout, decrementExamsLeft, isLoggedIn, isAdmin, isPremium }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider, UserContext };
