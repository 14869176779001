import { useContext, Context } from 'react';
import { HistoryContext } from '../contexts/HistoryContext'; // Adjust the path accordingly

/**
 * @returns {Context}
 */
const useHistory = () => {
    const historyContext = useContext(HistoryContext);

    if (!historyContext) {
        throw new Error('useHistory must be used within a HistoryProvider');
    }

    return historyContext;
};

export default useHistory;
