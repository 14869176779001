import React, { useState } from 'react'
import { generateExam } from '../utils/API_Requests';
// Components
import { Button, Stack, Typography } from '@mui/material/'
import CircularProgress from '@mui/material/CircularProgress'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
// Hooks
import { useLanguage } from '../contexts/languageContext'
import useSnackBar from '../hooks/useSnackBar';
import { useLoader } from '../hooks/useLoader'
import { useUser } from '../hooks/useUser';
import { useNavigate } from 'react-router-dom';
import { goToStore } from '../utils';
import useHistory from '../hooks/useHistory';

const GenerateExamButton = ({
  inputText,
  inputField,
  questions,
  difficulty,
  examLanguage,
  isFile,
  focus,
  skills,
  exampleQuestion,
  payload,
  errorData,
  isClosedContent,
  roles
}) => {
  const { user, decrementExamsLeft } = useUser()
  const { addNewExamToHistory } = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { languageData } = useLanguage();
  const navigate = useNavigate();
  const { openLoader, closeLoader } = useLoader();
  const { openSnackBar, SnackBarComponent } = useSnackBar();
  const hasNoExams = (!user?.exams_left || user?.exams_left <= 0);

  const buttonLabel = languageData && languageData.generate_btn_label ? languageData.generate_btn_label : 'Generate Exam'

  const handleClick = async () => {
    if (hasNoExams) {
      goToStore(navigate)
      return
    }

    openLoader('GENERATE', questions);
    await generateExam(
      inputText[inputField],
      inputField,
      questions,
      difficulty,
      examLanguage,
      focus,
      skills,
      exampleQuestion,
      isFile,
      decrementExamsLeft,
      openSnackBar,
      setIsLoading,
      payload,
      navigate,
      isClosedContent,
      roles,
      addNewExamToHistory
    );
    closeLoader();
  };


  return (
    <Stack direction={'column'} display={'flex'} alignItems="center" style={{ margin: 20 }}>
      <Button
        sx={{ minWidth: '200px', maxWidth: '300px' }}
        variant='contained'
        color={hasNoExams ? 'secondary' : 'primary'}
        startIcon={hasNoExams ? <AutoAwesomeIcon /> : null}
        onClick={handleClick}
        disabled={!inputText[inputField] || errorData.disableBtn}
      >
        {isLoading ? <CircularProgress size={24} /> : buttonLabel}
      </Button>
      {errorData?.msg && <Typography variant='body2' sx={{ color: 'red', textAlign: 'center' }}>{errorData.msg}</Typography>}
      <SnackBarComponent />
    </Stack>
  );
};

export default GenerateExamButton;

