import api from "./api"
import { processResponsePromise } from "./utils"

export async function getAllEmails() {
    return processResponsePromise(api.get('/emails'))
}

export async function getEmailsTemplates() {
    return processResponsePromise(api.get('/emails/templates'))
}

export async function insertSentEmail(sentEmail) {
    return processResponsePromise(api.post('/emails', sentEmail))
}

export async function saveEmailTemplate(template) {
    return processResponsePromise(api.post('/emails/templates', template))
}

export async function deleteEmailTemplate(templateId) {
    return processResponsePromise(api.delete(`/emails/templates/${templateId}`))
}

export async function sendEmail(emails, templateId) {
    return processResponsePromise(api.post('/emails/send', { emails, templateId }))
}
