import React, { useState } from 'react';
import { Tooltip, Chip, TextField, Typography } from '@mui/material';
import { useDebounce } from '../../hooks/useDebounce';
import { useLanguage } from '../../contexts/languageContext';
import { getAccuracyColor, isMobile } from '../../utils';
import BarChartIcon from '@mui/icons-material/BarChart';
import SpeedIcon from '@mui/icons-material/Speed';
import { getBloomsLabel, getBloomsLevel, getBloomLevelColor } from '../../utils/bloom';

const EditableOpenQuestion = ({ languageData, questionIndex, title, grade, explanation, bloomLevel, source, accuracy, level, isEditing = false, onChange, handleGradeChange, gradeError }) => {
  const [gradeState, setGradeState] = useState(grade);
  const debouncedGradeChange = useDebounce(handleGradeChange, 500);

  const { isRTL } = useLanguage();

  const handleTitleChange = (newTitle) => {
    onChange(questionIndex, newTitle, explanation, grade)
  }

  const handleExplanationChange = (newExplanation) => {
    onChange(questionIndex, title, newExplanation, grade)
  }

  const handleCurrentGradeChange = (event) => {
    setGradeState(event.target.value);
    debouncedGradeChange(event)
  };


  return (
    <div>
      {isEditing ? (
        <>
          <TextField
            label={languageData.grade_label}
            onChange={handleCurrentGradeChange}
            value={gradeState}
            fullWidth
            variant="outlined"
            margin="normal"
            error={gradeError}
          />
          <TextField
            label={languageData.question_title}
            value={title ?? ""}
            onChange={e => handleTitleChange(e.target.value)}
            fullWidth
            multiline
            variant="outlined"
            margin="normal"
            error={!title}
          />
          <TextField
            label={languageData.explanation_label}
            value={explanation}
            onChange={e => handleExplanationChange(e.target.value)}
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            margin="normal"
            error={!explanation}
          />
        </>
      ) : (
        <>
          {/* {(level && accuracy) && < Box sx={{ display: 'flex', alignItems: 'center', mt: 1, mb: 1 }}>
            <Tooltip arrow title="Indicates question's difficulty">
              <Chip
                icon={<BarChartIcon />}
                label={`${level}`}
                variant="outlined"
                color={getLevelColor(level)}
                sx={{ fontWeight: 'bold', p: 1, ...(isRTL ? { ml: 2 } : { mr: 2 }) }}
              />
            </Tooltip>
            <Tooltip arrow title="Indicates question's accuracy using AI">
              <Chip
                icon={<SpeedIcon />}
                label={`${accuracy}%`}
                variant="outlined"
                color={getAccuracyColor(accuracy)}
                sx={{ fontWeight: 'bold', p: 1 }}
              />
            </Tooltip>
          </Box>} */}
          {bloomLevel && (
            <Tooltip arrow title="Bloom's Taxonomy Level">
              <Chip
                label={getBloomsLabel(bloomLevel)}
                variant="outlined"
                color={getBloomLevelColor(getBloomsLevel(bloomLevel))}
                sx={{ fontWeight: 'bold', p: 1, ...(isRTL ? { ml: 2 } : { mr: 2 }) }}
              />
            </Tooltip>
          )}
          <Typography variant={isMobile ? "body1" : "h6"} gutterBottom sx={{ fontWeight: "bolder", margin: '10px 0px' }}>
            {title}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <span style={{ color: 'green', fontWeight: 'bold' }}>{languageData.correct_answer} : </span> {explanation}
          </Typography>
          {/* <Grid container spacing={2}>
            <Grid item xs={12}>
              {source && source.trim() !== '' && (
                <Typography variant="body2" component="span">
                  {languageData.source + ': '}
                  <Link href={source} target="_blank" rel="noopener noreferrer">{source}</Link>
                </Typography>
              )}
            </Grid>
          </Grid> */}
        </>
      )}
    </div>
  );
};

export default EditableOpenQuestion;
