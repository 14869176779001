import React, { useEffect } from 'react';
import { Box, Stepper, Step, StepLabel } from '@mui/material';
import { useLanguage } from '../../contexts/languageContext';
import { stages } from '../../utils/configs';


export default function VerticalLinearStepper({ activeStep }) {
    const { languageData } = useLanguage();

    useEffect(() => {
        if (!languageData || !languageData.stages_menu) {
            // console.error('Language data is not available');
        }
    }, [languageData]);

    return (
        <Box>
            <Stepper activeStep={activeStep} orientation="vertical">
                {languageData && languageData.stages_menu && stages.map((step, index) => (
                    <Step key={step.id}>
                        <StepLabel>{''}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
}
