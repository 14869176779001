export const defaultRanges = [
    { range: '0-49', min: 0, max: 49 },
    { range: '50-55', min: 50, max: 55 },
    { range: '56-60', min: 56, max: 60 },
    { range: '61-65', min: 61, max: 65 },
    { range: '66-70', min: 66, max: 70 },
    { range: '71-75', min: 71, max: 75 },
    { range: '76-80', min: 76, max: 80 },
    { range: '81-85', min: 81, max: 85 },
    { range: '86-90', min: 86, max: 90 },
    { range: '91-95', min: 91, max: 95 },
    { range: '96-100', min: 96, max: 100 }
];