import React, { createContext, useContext, useState, useEffect } from 'react';
import api from '../requests/api';
import { useUser } from '../hooks/useUser';

const LanguageContext = createContext();

export function useLanguage() {
  return useContext(LanguageContext)
}

export const languageCodes = {
  english: 'en',
  hebrew: 'he'
}

const LanguageProvider = ({ children }) => {
  const { user } = useUser()
  const [language, setLanguage] = useState(languageCodes.english);
  const [languageData, setLanguageData] = useState({});
  const [tempToken, setTempToken] = useState(null);
  const isRTL = language === languageCodes.hebrew

  useEffect(() => {
    if (user || tempToken)
      fetchLanguageData();
  }, [language, user]);

  function ToggleLanguage(newLangCode = null, token = null) {
    if (token) setTempToken(token)
    let newLanguage = newLangCode ?? (language === languageCodes.english ? languageCodes.hebrew : languageCodes.english)
    setLanguage(newLanguage);

    // Update the URL query parameter
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('lang', newLanguage);
    const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
    window.history.pushState({}, '', newUrl);
  }

  const fetchLanguageData = async (token = null) => {
    const dataUrl = `/languages?code=${language}`;
    try {
      const headers = {}
      if (token) headers['X-Token'] = token;
      else if (tempToken) headers['X-Token'] = tempToken;
      const response = await api.get(dataUrl, { headers })
      setLanguageData(response.data);
      setTempToken(null)
    } catch (err) {
      console.error(err)
    }
  };

  const fetchLanguageWithToken = async (token) => {
    fetchLanguageData(token)
  }

  function changeToHebrew() {
    if (language !== languageCodes.hebrew)
      ToggleLanguage(languageCodes.hebrew);
  }

  return (
    <LanguageContext.Provider value={{ language, ToggleLanguage, languageData, isRTL, changeToHebrew, fetchLanguageWithToken }}>
      {children}
    </LanguageContext.Provider>
  );
};

export { LanguageContext, LanguageProvider };
