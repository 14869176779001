import { createTheme } from '@mui/material/styles';

export const colors = {
  main: '#3A5F7D',
  secondary: '#8884d8',
  success: '#019F73',
  onboarding: '#edf6ff',
  text: '#363e48',
  sub: '#3b3b3b',
  bg: '#F6F6F6'
};

export const theme = createTheme({
  typography: {
    fontFamily: [
      'Assistant',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      color: colors.text,
    },
    h2: {
      color: colors.text,
    },
    h3: {
      color: colors.text,
    },
    h4: {
      color: colors.text,
    },
    h5: {
      color: colors.text,
    },
  },
  palette: {
    primary: {
      main: colors.main,
    },
    secondary: {
      main: colors.secondary,
    },
    success: {
      main: colors.success,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
        },
        containedPrimary: {
          background: colors.main,
          borderRadius: 3,
          color: 'white',
          '&:hover': {
            backgroundColor: '#325169',
          },
          '&.Mui-disabled': {
            background: 'rgba(0, 0, 0, 0.12)',
            color: 'rgba(0, 0, 0, 0.26)',
          },
        },
        outlinedPrimary: {
          borderColor: colors.main,
          color: colors.main,
          '&:hover': {
            borderColor: '#2E4D6B',
            color: '#2E4D6B',
            backgroundColor: 'rgba(178, 101, 108, 0.08)',
          },
          '&.Mui-disabled': {
            borderColor: 'rgba(0, 0, 0, 0.12)',
            color: 'rgba(0, 0, 0, 0.26)',
          },
        },
        containedSecondary: {
          background: colors.secondary,
          borderRadius: 3,
          color: 'white',
          '&:hover': {
            backgroundColor: '#8A5FC2',
          },
          '&.Mui-disabled': {
            background: 'rgba(0, 0, 0, 0.12)',
            color: 'rgba(0, 0, 0, 0.26)',
          },
        },
        outlinedSecondary: {
          borderColor: colors.secondary,
          color: colors.secondary,
          '&:hover': {
            borderColor: '#8A5FC2',
            color: '#8A5FC2',
            backgroundColor: 'rgba(159, 119, 233, 0.08)',
          },
          '&.Mui-disabled': {
            borderColor: 'rgba(0, 0, 0, 0.12)',
            color: 'rgba(0, 0, 0, 0.26)',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: '#FAFAFA',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize'
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '14px'
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#216DB9',
          cursor: 'pointer',
          position: 'relative',
          textDecoration: 'none',
          '&::before': {
            content: '""',
            position: 'absolute',
            width: 0,
            height: '1px',
            bottom: 0,
            left: 0,
            backgroundColor: colors.secondary,
            opacity: 0.7,
            transition: 'width 0.3s ease, opacity 0.3s ease',
          },
          '&:hover::before': {
            width: '100%',
            opacity: 1,
          },
          '&:hover': {
            color: colors.secondary,
          },
        },
      },
    },
  },
});

export const iconButtonStyle = {
  mobile: {
    padding: '10px 0px',
    borderRadius: '20px',
    minWidth: '40px',
    // height: 'auto',
    '& .MuiButton-startIcon': {
      margin: 'auto'
    }
  },
  desktop: (isRTL) => ({
    '& .MuiButton-startIcon': {
      marginLeft: isRTL ? '8px' : null,
      marginRight: isRTL ? null : '8px',
    }
  })
}

export const paddingPageStyle = {
  margin: 'auto', maxWidth: '90rem',
}
