import React, { useEffect, useMemo, useState } from 'react';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import { Lightbulb, SentimentSatisfied, Group } from '@mui/icons-material';

const SelectionTags = ({ tags, initClickedTags = [], onSubmitTags, lang, isRTL }) => {
    const [disabledTags, setDisabledTags] = useState([]);
    const [clickedTags, setClickedTags] = useState([]);
    const [expandedTitle, setExpandedTitle] = useState(null);
    const titlesConfig = useMemo(() => [
        { title: lang?.cognetive ?? 'Cognitive Skills', icon: Lightbulb, color: '#FFBB33', category: 'cognitive' },
        { title: lang?.emotional ?? 'Emotional Skills', icon: SentimentSatisfied, color: '#66BB6A', category: 'emotional' },
        { title: lang?.social ?? 'Social Skills', icon: Group, color: '#42A5F5', category: 'social' }
    ], [lang]);
    // Memoize disabled tag check
    const isTagDisabled = useMemo(() => {
        const disabledSet = new Set(disabledTags);
        return (tag) => disabledSet.has(tag);
    }, [disabledTags]);
    // Memoize clicked tag check
    const isTagClicked = useMemo(() => {
        const clickedSet = new Set(clickedTags);
        return (tag) => clickedSet.has(tag);
    }, [clickedTags]);
    const labelLang = useMemo(() => isRTL ? 'he' : 'en', [isRTL])

    useEffect(() => {
        setClickedTags(initClickedTags)
        disableTags(initClickedTags)
    }, [initClickedTags])

    const handleTitleClick = (title) => {
        setExpandedTitle(expandedTitle === title ? null : title);
    };

    const disableTags = (selectedTagsIds) => {
        // Disable all tags when three tags are selected
        if (selectedTagsIds.length > 2) {
            const tagsToDisable = tags.filter((tag) => !selectedTagsIds.includes(tag._id)).map(tag => tag._id);
            setDisabledTags(tagsToDisable);
        } else {
            // Enable all tags when clickedTags.length is less than 2
            setDisabledTags([]);
        }
    }

    const handleTagClick = (clickedTagId) => {
        if (!isTagDisabled(clickedTagId)) {
            let updatedClickedTags;

            if (isTagClicked(clickedTagId)) {
                // Tag is already clicked, remove it from clickedTags
                updatedClickedTags = clickedTags.filter((tagId) => tagId !== clickedTagId);
            } else {
                // Tag is not clicked, add it to clickedTags
                updatedClickedTags = [...clickedTags, clickedTagId];
            }

            setClickedTags(updatedClickedTags);
            onSubmitTags([...updatedClickedTags]);

            disableTags(updatedClickedTags)
        }
    };

    return (
        <Box>
            {titlesConfig.map(({ title, icon: Icon, color, category }) => (
                <div key={title} style={{ marginBottom: '16px', borderBottom: '1px solid #ccc' }}>
                    <h3 style={{ cursor: 'pointer', fontSize: '16px', fontWeight: 'normal' }} onClick={() => handleTitleClick(title)}>
                        <Icon style={{ color, marginRight: '5px', verticalAlign: 'middle' }} /> {title}
                    </h3>
                    {expandedTitle === title && (
                        <div>
                            {tags.filter(tag => tag.category === category).map((tag) => (
                                <Chip
                                    key={tag.label[labelLang]}
                                    label={tag.label[labelLang]}
                                    color="primary"
                                    variant={isTagClicked(tag._id) ? "filled" : "outlined"}
                                    onClick={() => handleTagClick(tag._id)}
                                    disabled={isTagDisabled(tag._id)}
                                    style={{ margin: '0.2rem' }}
                                />
                            ))}
                        </div>
                    )}
                </div>
            ))}
        </Box>
    );
};

export default SelectionTags;
