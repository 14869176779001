import React, { useState } from 'react';
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, MenuItem, Select, Typography, Button } from '@mui/material';
import subjects from '../../utils/subjectsData';

const HubApproveDialog = ({ open, onClose, onApprove }) => {
    const [selectedSubject, setSelectedSubject] = useState('');

    const handleSubjectChange = (event) => {
        setSelectedSubject(event.target.value);
    };

    const handleApprove = () => {
        onApprove(selectedSubject);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Exam Hub Upload</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">
                            By approving, this exam will be uploaded to the HUB where people can view and use it to get inspired.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" color="textSecondary">
                            Please select the subject of the exam:
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Select
                            value={selectedSubject}
                            onChange={handleSubjectChange}
                            fullWidth
                            displayEmpty
                            placeholder="Select Subject"
                        >
                            {/* <MenuItem value="">Select Subject</MenuItem> */}
                            {subjects.map(subject => (
                                <MenuItem key={subject.subject} value={subject.subject}>
                                    <div style={{ padding: '8px' }}>
                                        {subject.icon}
                                    </div>
                                    {subject.subject}
                                </MenuItem>))}
                        </Select>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleApprove} variant='contained' color="primary">
                    Approve
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default HubApproveDialog;
