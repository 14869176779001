import { HttpStatusCode } from 'axios';
import React, { useEffect, useState } from 'react';
import { Avatar, Button, CssBaseline, TextField, Link, Grid, Box, Typography, Container, Divider } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
// import ContinueWithGoogleButton from '../components/GoogleAuth';
import { useNavigate, useLocation } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { validateEmail, validatePassword } from '../utils/inputValidations';
//hooks:
import useSnackBar from '../hooks/useSnackBar';
import { useUser } from '../hooks/useUser';
import { getOrg, postOrgSignup } from '../requests/organizations';
import { imgSrcPrefix } from '../components/B2B/UploadImage';
import { getTempToken } from '../requests/token';
import { homepage } from '../components/menu/MenuLinks';

export default function OrgSignUp() {
    // Hooks:
    const navigate = useNavigate();
    const userContext = useUser()
    const location = useLocation();
    const { openSnackBar, SnackBarComponent } = useSnackBar();
    const queryParams = new URLSearchParams(location.search);
    const orgId = queryParams.get('id')

    async function init() {
        if (!orgId)
            navigate('/signup')
        else {
            const res = await userContext.isLoggedIn()
            if (res)
                navigate(homepage)
            else {
                const token = await getTempToken(orgId)
                if (token) {
                    const org = await getOrg(orgId, token)
                    if (org) {
                        setOrg(org)
                        return
                    }
                }
                else
                    navigate('/signup')
            }
        }
    }
    // States:
    const [org, setOrg] = useState(null);

    useEffect(() => {
        init()
    }, [])

    const [isLoading, setIsLoading] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [isFormValid, setisFormValid] = useState({ firstName: false, lastName: false, email: false, password: false });

    // ################# Form Functions #################
    const handleTextFieldChange = (event) => {
        const { name, value } = event.target;
        let newIsFormValid = { ...isFormValid };

        switch (name) {
            case 'firstName':
                newIsFormValid[name] = value.length > 0;
                break;
            case 'lastName':
                newIsFormValid[name] = value.length > 0;
                break;
            case 'email':
                newIsFormValid[name] = validateEmail(value);
                break;
            case 'password':
                newIsFormValid[name] = value.length >= 6;
                break;
            default:
                break;
        }

        setisFormValid(newIsFormValid);
    };

    const checkValidForm = (obj) => {
        const { firstName, lastName, email, password } = obj
        return firstName && lastName && email && password
    }

    const handleSubmitInvalid = async (event) => {
        event.preventDefault();
        openSnackBar('FORM_INVALID')
    }

    const handleEmailChange = (event) => {
        const input = event.target.value;
        setEmailError(!validateEmail(input));
    };

    const handlePasswordChange = (event) => {
        const input = event.target.value;
        setPasswordError(!validatePassword(input));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        const data = new FormData(event.currentTarget);
        const payload = {
            user: {
                email: data.get('email'),
                password: data.get('password'),
                firstname: data.get('firstName'),
                lastname: data.get('lastName'),
                orgId: orgId
            }
        }

        postOrgSignup(payload, openSnackBar).then(user => {
            userContext.login(user)
            navigate(homepage);
        }).catch(err => {
            console.error(err)
            if (err.response.status === HttpStatusCode.Unauthorized) {
                openSnackBar('UNAUTHORIZED_SIGNUP')
            } else if (err.response.status === HttpStatusCode.Conflict) {
                openSnackBar('CONFLICT')
            } else {
                openSnackBar('ERROR')
            }
        }).finally(() => {
            setIsLoading(false);
        })
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '15px'
                }}
            >
                {org?.logo
                    ? <Avatar alt='logo' src={imgSrcPrefix + org.logo} sx={{ width: '200px', height: '150px', mt: 1 }} />
                    : <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}><LockOutlinedIcon /></Avatar>
                }
                {/* <Typography component="h1" variant="h5">
                    Sign up
                </Typography> */}
                {!org?.logo && org?.name &&
                    <Typography variant="body1">{org.name}</Typography>
                }
                <Box component="form" noValidate onSubmit={checkValidForm(isFormValid) ? handleSubmit : handleSubmitInvalid} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="given-name"
                                name="firstName"
                                required
                                fullWidth
                                id="firstName"
                                label="First Name"
                                onChange={handleTextFieldChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="lastName"
                                label="Last Name"
                                name="lastName"
                                autoComplete="family-name"
                                onChange={handleTextFieldChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                type='email'
                                label="Email Address"
                                name="email"
                                onChange={handleEmailChange}
                                error={emailError}
                                helperText={emailError ? 'Please enter a valid email' : ''}
                                autoComplete="email"
                                onInput={handleTextFieldChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                onInput={handleTextFieldChange}
                                name="password"
                                label="Password"
                                onChange={handlePasswordChange}
                                type="password"
                                error={passwordError}
                                helperText={
                                    passwordError
                                        ? 'Password must be at least 6 characters long'
                                        : ''
                                }
                                id="password"
                                autoComplete="new-password"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                {isLoading ? <CircularProgress color='inherit' size={24} /> : "Sign Up"}
                            </Button>
                        </Grid>
                        {/* <Grid item xs={12}>
                            <Divider>or</Divider>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={2}>
                                <ContinueWithGoogleButton />
                            </Stack>
                        </Grid> */}
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Link href='/login' variant="body2">
                                    Already have an account? Log in
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <SnackBarComponent />
        </Container>
    );
}