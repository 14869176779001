import React from 'react';
import { Switch, FormControlLabel, Tooltip } from '@mui/material';
import { useLanguage } from '../contexts/languageContext';

const ContentToggle = ({ isCloseContent, handleToggleChange }) => {
    const { languageData } = useLanguage();
    return (
        <Tooltip title={languageData?.content_toggle_title ?? "If checked, the exam will be based ONLY on the content provided"} arrow>
            <FormControlLabel
                control={
                    <Switch
                        checked={isCloseContent}
                        onChange={handleToggleChange}
                        name="contentToggle"
                        color="primary"
                    />
                }
                label={languageData?.content_toggle_label ?? "Limit Exam to Provided Content"}
            />
        </Tooltip>
    );
};

export default ContentToggle;
