import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@mui/material';
import { requestAddPost } from '../../requests/blog';

const AddPostDialog = ({ open, onClose }) => {
    const [postData, setPostData] = useState({
        title: '',
        content: '',
    });

    const handleClose = () => {
        setPostData({
            title: '',
            content: '',
        });
        onClose();
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPostData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async () => {
        // Check if title and content are not empty
        if (!postData.title.trim() || !postData.content.trim()) {
            alert('Title and content are required.');
            return;
        }

        try {
            await requestAddPost(postData);
            handleClose();
        } catch (error) {
            console.error('Error adding post:', error);
        }
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Add New Post</DialogTitle>
            <DialogContent>
                <TextField
                    fullWidth
                    label="Title"
                    name="title"
                    value={postData.title}
                    onChange={handleChange}
                    multiline
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label="Content"
                    name="content"
                    value={postData.content}
                    onChange={handleChange}
                    margin="normal"
                    multiline
                    rows={5}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSubmit} color="primary">Add</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddPostDialog;
