import React from 'react'
// Components
import FullWidthTextField from './FullWidthTextField'
import ROLES from '../utils/roles';
import { useLanguage } from '../contexts/languageContext';

function ExamGenerator({
  limit, userRoles, rows, placeholder, onInput, inputText, field
}) {
  const { languageData } = useLanguage()

  const TEXT_LIMIT = field === 'text' ? ROLES.getMaxLimit(userRoles) : limit
  const handleInputChange = (event) => {
    const inputValue = event.target.value;

    if (inputValue !== '') {
      onInput(inputValue);
    }
    else {
      onInput('');
    }
  };

  return (
    <div >
      <FullWidthTextField
        value={inputText}
        onChange={handleInputChange}
        limit={TEXT_LIMIT}
        rows={rows}
        placeholder={placeholder}
      />
      <div className='container'>
        <span className='char-count'>
          {inputText?.length} / {TEXT_LIMIT + ' ' + languageData?.chars_text}
        </span>
      </div>
    </div>
  )
}

ExamGenerator.defaultProps = {
  limit: 10000,
}

export default ExamGenerator
