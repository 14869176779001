import React, { useState, useEffect } from 'react';
import { Grid, Button, Chip, Typography, Box, Paper, TextField } from '@mui/material';
import StudentDetails from './StudentDetails';
import DisplayMultipleChoice from './DisplayMultipleChoice';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { EMPTY_STRING } from '../../utils';
import Graph from '../../components/Recharts/Graph';
import { questionsType } from '../../utils/examOptions';
import useSnackBar from '../../hooks/useSnackBar';
import { SnackbarTypes } from '../../utils/snackbarTypes';
import ChipBox from '../../components/ChipBox';

const ExamResponse = ({ isRTL, languageData, student: studentResponse, examJson, onSubmit }) => {
  const { openSnackBar, SnackBarComponent } = useSnackBar()
  //TODO: merge the feedbacks and grades into one object
  /**@type {[{feedback: string, question_id: string}[], Function]} */
  const [questionsFeedback, setFeedback] = useState(studentResponse?.answers.map(answer => ({ feedback: answer.teacher_feedback, question_id: answer.question_id })) ?? []);
  /**@type {[{grade: number, question_id: string}[], Function]} */
  const [grades, setGrades] = useState(studentResponse?.answers.map(answer => ({ grade: parseInt(answer.grade), question_id: answer.question_id })) ?? []);
  const [generalFeedback, setGeneralFeedback] = useState(studentResponse?.general_feedback ?? "");
  const [gradeErrors, setGradeErrors] = useState(Array(studentResponse.answers.length).fill(false));
  const [totalGrade, setTotalGrade] = useState(parseInt(studentResponse?.grade));
  const [maxGrade, setMaxGrade] = useState(examJson.questions.reduce((acc, curr) => acc + parseInt(curr.grade), 0));

  const resetResponse = (studentResponse) => {
    setTotalGrade(parseInt(studentResponse?.grade));
    setFeedback(studentResponse?.answers.map(answer => ({ feedback: answer.teacher_feedback, question_id: answer.question_id })) ?? []);
    setGrades(studentResponse?.answers.map(answer => ({ grade: parseInt(answer.grade), question_id: answer.question_id })) ?? []);
    setGeneralFeedback(studentResponse?.general_feedback ?? "");
    setGradeErrors(Array(studentResponse.answers.length).fill(false));
  };

  useEffect(() => {
    resetResponse(studentResponse);
  }, [studentResponse]);

  useEffect(() => {
    setTotalGrade(parseInt(studentResponse?.grade));
  }, []);

  const handleFeedbackChange = (index, event) => {
    const newFeedback = [...questionsFeedback];
    newFeedback[index] = { feedback: event.target.value, question_id: newFeedback[index].question_id };
    setFeedback(newFeedback);
  };

  const handleGradeChange = (index, event, questionGrade) => {
    let inputValue = event.target.value;
    const newGrades = [...grades];
    const newGradeErrors = [...gradeErrors];

    // Check if the input value is empty or an empty string
    if (!inputValue.trim()) {
      inputValue = EMPTY_STRING;
    }

    // Validate if the input is a positive number and smaller or equal to questionGrade
    if (/^\d*\.?\d+$/.test(inputValue) && parseFloat(inputValue) >= 0 && parseFloat(inputValue) <= questionGrade) {
      newGrades[index] = { grade: inputValue, question_id: grades[index].question_id };
      newGradeErrors[index] = false;

      const newTotalGrade = newGrades.reduce((acc, curr) => acc + parseFloat(curr.grade), 0);
      setTotalGrade(newTotalGrade);

    } else {
      newGrades[index] = { grade: EMPTY_STRING, question_id: grades[index].question_id };
      newGradeErrors[index] = true;
    }

    setGrades(newGrades);
    setGradeErrors(newGradeErrors);
  };

  const handleGeneralFeedbackChange = (event) => {
    setGeneralFeedback(event.target.value);
  };

  const handleSubmit = (isSaving) => {
    // check again the grades
    const validGrade = examJson.questions.every(question => {
      const review_grade = grades.find(g => g.question_id === question._id)?.grade
      return review_grade <= question.grade
    })
    if (!validGrade) {
      openSnackBar(SnackbarTypes.GRADE_ERROR.field)
      return
    }
    onSubmit({
      questionsFeedback,
      grades,
      generalFeedback,
      isSaving
    });
  };

  if (!studentResponse) {
    return <Typography>No student data available.</Typography>;
  }

  return (
    <Grid container spacing={2} >
      <Grid item xs={10} sm={5} md={5} lg={5}>
        <StudentDetails student={{ ...studentResponse, grade: totalGrade, maximumGrade: maxGrade }} />
      </Grid>
      {examJson?.questions.map((question, index) => {
        const studentAnswer = studentResponse.answers.find(answer => answer.question_id === question._id);
        const answerColor = question?.correctAnswers
          ? question?.correctAnswers.includes(studentAnswer?.answer)
            ? 'success' : 'error' : 'primary';
        return (
          <Grid item xs={12} key={question._id}>
            <Paper sx={{ padding: '16px', marginBottom: '16px' }}>

              <Typography variant="h5">{`${languageData?.question_label ?? ""} ${index + 1}`}</Typography>
              <Typography variant="body1" gutterBottom sx={{ fontWeight: "bolder" }}>{question.question}</Typography>
              {question.type === questionsType.graph && question.functions && <Graph fnStrings={question.functions} />}

              {(question.type === questionsType.multiple || question.type === questionsType.fill_in) && (
                <DisplayMultipleChoice
                  options={question.options}
                  correctAnswers={question.correctAnswers}
                  studentAnswer={studentAnswer?.answer}
                />
              )}
              {(question.type === questionsType.open || question.type === questionsType.graph) && (
                <Typography variant="body1" gutterBottom>
                  <u>{languageData?.answer_label + ":"}</u>
                  <br />
                  {studentAnswer?.answer}
                </Typography>
              )}
              {
                question?.explanation && (
                  <ChipBox label={languageData?.ai} text={question?.explanation} color={'#9C27B0'} backgroundColor={'#E4D2FC'} />
                )
              }
              <Grid container alignItems="center" spacing={1} pt={2}>

                <Grid item xs={12} sm={10} md={10} lg={12} pb={2}>
                  <TextField
                    label={languageData.teacher_feedback}
                    variant="outlined"
                    fullWidth
                    multiline
                    value={questionsFeedback[index]?.feedback ?? ""}
                    onChange={(event) => handleFeedbackChange(index, event)}
                    placeholder="Enter your feedback here"
                    margin="normal"
                    rows={3}
                  />
                </Grid>
                <Grid item xs={3} sm={1} md={1} lg={1}>
                  <TextField
                    label={languageData.grade}
                    variant="outlined"
                    type="text"
                    value={grades[index]?.grade ?? ""}
                    onChange={(event) => handleGradeChange(index, event, question.grade)}
                    error={gradeErrors[index]}
                    placeholder="Enter answer grade"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="body2">{`/ ${parseInt(question.grade)}`}</Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid >
        );
      })}
      <Grid item xs={12}>
        <Paper sx={{ padding: '16px', marginBottom: '16px' }}>
          <Typography variant="h6">{languageData.general_feedback}</Typography>
          <TextField
            rows={3}
            label={languageData.general_feedback_label}
            variant="outlined"
            fullWidth
            multiline
            value={generalFeedback}
            onChange={handleGeneralFeedbackChange}
            placeholder="Enter general feedback"
            margin="normal"
          />
        </Paper>
      </Grid>
      <Grid item >
        <Paper
          sx={{
            padding: '16px',
            marginBottom: '16px',
            marginLeft: '16px',
            position: 'fixed',
            bottom: 0,
            left: 0,
            zIndex: 1000,
          }}
        >
          <Button variant="contained" onClick={() => handleSubmit(false)}>
            {languageData.submit}
          </Button>
          <Button variant="text" onClick={() => handleSubmit(true)}>
            {languageData.save}
          </Button>
        </Paper>

      </Grid>
      <SnackBarComponent />
    </Grid >
  );
};

export default ExamResponse;