import React from 'react';
import { Box, Typography, Button, Grid, Container, Paper } from '@mui/material';
import { Assignment, Lightbulb, LocalCafe, Tune } from '@mui/icons-material';
import TestPng from '../../../assets/features/quiz.png'; // Placeholder image
import LandingHeader from '../Header';
import { isMobile } from '../../../utils';

const AIQuiz = () => {
    return (
        <Box>
            <LandingHeader />
            <Container maxWidth="auto" sx={{ mt: 10 }}>
                <Paper elevation={3} sx={{ p: 4 }}>
                    <Grid container spacing={4}>
                        {/* Full Width Column for Introductory Text */}
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                                {!isMobile && <Assignment color='primary' sx={{ fontSize: 40, mr: 1 }} />}
                                <Typography 
                                    variant="h3" 
                                    sx={{ flexGrow: 1, fontSize: '35px' }} 
                                    component="h1" 
                                    aria-label="AI-Powered Question Generator for Quizzes and Exams"
                                >
                                    Revolutionize Your Assessment Creation with Our AI-Powered Question Generator
                                </Typography>
                            </Box>
                            <Typography 
                                variant="body1" 
                                color="textSecondary" 
                                sx={{ mb: 4, lineHeight: 1.6 }}
                            >
                                Transform the way you create quizzes, tests, and exams with our state-of-the-art AI-powered question generator. Our platform uses advanced AI technology to streamline your assessment creation process, enabling you to generate high-quality, relevant questions efficiently. Say goodbye to manual question drafting and experience a new era of quiz and exam development.
                            </Typography>
                        </Grid>

                        {/* Benefits Column */}
                        <Grid item xs={12}>
                            <Box sx={{ mb: 4 }}>
                                <Typography 
                                    variant="h3" 
                                    component="h2" 
                                    sx={{ mb: 2, fontSize: '35px' }}
                                >
                                    Benefits of Using Our AI-Powered Question Generator
                                </Typography>
                                <Typography 
                                    variant="body1" 
                                    color="textSecondary" 
                                    sx={{ mt: 2, lineHeight: 1.6 }}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Lightbulb color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6" >
                                            Generate Innovative Question Ideas
                                        </Typography>
                                    </Box>
                                    Harness the power of AI to come up with creative and innovative question ideas for your quizzes and exams. Avoid the repetitive task of drafting questions manually and let our AI platform suggest diverse and engaging questions tailored to your content.
                                </Typography>
                                <Typography 
                                    variant="body1" 
                                    color="textSecondary" 
                                    sx={{ mt: 2, lineHeight: 1.6 }}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <LocalCafe color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6" >
                                            Boost Efficiency and Save Time
                                        </Typography>
                                    </Box>
                                    Save valuable time with our AI-driven question generator. Input your content, specify the type of questions you need, and let our AI efficiently handle the rest. This automation allows you to focus on other critical aspects of your educational process.
                                </Typography>
                                <Typography 
                                    variant="body1" 
                                    color="textSecondary" 
                                    sx={{ mt: 2, lineHeight: 1.6 }}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Tune color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">
                                            Create Diverse Question Variants
                                        </Typography>
                                    </Box>
                                    Our AI technology enables you to generate multiple variants of questions from the same content. This feature supports diverse assessment styles and ensures a comprehensive evaluation of knowledge.
                                </Typography>
                            </Box>

                            <Typography 
                                variant="h3" 
                                component="h2" 
                                sx={{ mb: 2, fontSize: '35px' }}
                            >
                                How to Maximize the Benefits of AI-Generated Questions
                            </Typography>
                            <Typography 
                                variant="body1" 
                                color="textSecondary" 
                                sx={{ mt: 2, mb: 4, lineHeight: 1.6 }}
                            >
                                Integrate our AI-powered question generator into your assessment development workflow to enhance your quiz and exam creation process. Tailor questions to your specific requirements, adapt to various educational needs, and create effective assessments with ease.
                            </Typography>
                            <Box>
                                <Button variant="contained" color="primary" href="/signup">
                                    Sign Up for Free
                                </Button>
                            </Box>
                        </Grid>

                        {/* Right Column (Image) */}
                        {/* <Grid item xs={12} md={6}>
                            <img src={TestPng} alt="AI Quiz Generator" style={{ width: '100%', borderRadius: 8, border: '1px solid black' }} />
                        </Grid> */}
                    </Grid>
                </Paper>
            </Container>
        </Box>
    );
};

export default AIQuiz;
