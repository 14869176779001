import React, { useState } from 'react'
import GetAppIcon from '@mui/icons-material/GetApp';
import { CircularProgress, Button } from '@mui/material';
import { requestDownloadGIFT } from '../../requests/GIFT';
import { isMobile } from '../../utils';
import { buttonsIds, reportClick } from '../../requests/analytics';

function DownloadGIFT({ examId, label, openSnackBar }) {
    const [isLoading, setIsLoading] = useState(false)

    const handleDownload = async () => {
        setIsLoading(true)
        reportClick(buttonsIds.downloadGIFT) 
        requestDownloadGIFT(examId).catch(e => {
            openSnackBar('ERROR')
        }).finally(() => {
            setIsLoading(false)
        })
    };

    return (
        <div>
          <Button
            sx={{ textTransform: 'capitalize', backgroundColor: '#FA8011', minWidth: '200px', color:'#313131'}}
            startIcon={<GetAppIcon />}
            onClick={handleDownload}
            disabled={isMobile}
          >
            {isLoading ? <CircularProgress sx={{ color: 'white' }} /> : label}
          </Button>
          {isMobile && <p style={{ fontSize: 14, color: '#bbbbbb' }}>Works only on desktop</p>}
        </div>
      );
}

export default DownloadGIFT;