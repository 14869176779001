import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete'
import SearchIcon from '@mui/icons-material/Search'

PostSearch.propTypes = {
    posts: PropTypes.array.isRequired,
}

export default function PostSearch({ posts }) {
    const navigate = useNavigate()

    const handlePostSelection = (event, value) => {
        if (value) {
            navigate(`/blog/${value._id}`)
        }
    }

    return (
        <Autocomplete
            autoHighlight
            popupIcon={null}
            slotProps={{
                paper: {
                    sx: {
                        [`& .${autocompleteClasses.option}`]: {
                            typography: 'body2',
                        },
                    },
                },
            }}
            options={posts}
            getOptionLabel={(post) => post.title}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            onChange={handlePostSelection}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder='Search post...'
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position='start'>
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    sx={{ margin: '1rem 0' }}
                />
            )}
        />
    )
}
