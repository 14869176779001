import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import QRDialog from './QRDialog';
import { useState } from 'react';
import { Button } from '@mui/material';

function GenerateQRButton({ url }) {
    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    }
    return (
        <>
            <Button
                variant="contained"
                color='success'
                startIcon={<QrCodeScannerIcon />}
                onClick={handleClick}
            >
                Generate QR Code
            </Button >
            <QRDialog url={url} open={open} handleClose={handleClose} />
        </>
    )
}

export default GenerateQRButton;