// UserContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { getExamSkills } from '../requests/skills';
import { useUser } from '../hooks/useUser'

const SkillsContext = createContext();

const useSkills = () => {
    return useContext(SkillsContext)
}

const SkillsProvider = ({ children }) => {
    const [skills, setSkills] = useState(null);
    const { user } = useUser();
    // Get Skills
    useEffect(() => {
        if (user && !skills)
            getExamSkills().then(skillsRes => {
                if (skillsRes)
                    setSkills(skillsRes)
            })
    }, [user])


    return (
        <SkillsContext.Provider value={{ skills }}>
            {children}
        </SkillsContext.Provider>
    );
};

export { SkillsProvider, SkillsContext, useSkills };
