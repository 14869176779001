import PropTypes from 'prop-types';
import { TableHead, TableRow, TableCell, TableSortLabel } from "@mui/material";
import { capitalizeFirstLetter } from "../../utils";
import { orders } from '../../utils';

function EnhancedTableHead({ order, orderBy, onRequestSort, headCells, sortableFields}) {
    const createSortHandler = (property) => {
        onRequestSort(property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell, i) => (
                    <TableCell
                        sortDirection={orderBy === headCell ? order : false}
                    >
                        {sortableFields?.some(field => field === headCell)
                            ?
                            <TableSortLabel
                                active={orderBy === headCell}
                                direction={orderBy === headCell ? order : orders.ascending}
                                onClick={() => createSortHandler(headCell)}
                            >
                                <TableCell key={i} sx={{ fontWeight: 'bold' }}>{capitalizeFirstLetter(headCell)}</TableCell>
                            </TableSortLabel>
                            :
                            <TableCell key={i} sx={{ fontWeight: 'bold' }}>{capitalizeFirstLetter(headCell)}</TableCell>
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf([orders.ascending, orders.descending]).isRequired,
    orderBy: PropTypes.string.isRequired,
};

export default EnhancedTableHead;
