import { useRef, useEffect } from 'react';

export function useDebounce(callback, delay = 500) {
  const timeoutRef = useRef(null);

  useEffect(() => {
    // Clear any existing timeout on effect clean-up
    return () => {
      if (timeoutRef.current)
        clearTimeout(timeoutRef.current);
    };
  }, []);

  return (...args) => {
    // Clear any existing timeout before setting a new one
    if (timeoutRef.current)
      clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      callback(...args);
    }, delay);
  };
}
