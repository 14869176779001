import api from "./api"
import { convertISOToDDMMYYYY } from '../utils/dateHelpers';
import { HttpStatusCode } from "axios"

// retuens a promise that resolves to the response from the server
const requestSubmitExam = async (examId, answers, studentDetails, token) => {
    try {
      const res = await api.post(`/exams/${examId}/responses`, { answers, studentDetails }, { headers: { "X-Token": token } });
        if (res.status === HttpStatusCode.Ok) {
          return res;
        }
        else {
            console.error('Exam response submission failed');
            return Promise.reject(`Request failed with status ${res.status}`);
        }
      }
      catch (error) {
          return Promise.reject(error);
      }
}
const requestStudentsResponses = async (exam_id) => {
    try {
      const res = await api.get(`/exams/${exam_id}/responses`);
      if (res.status === HttpStatusCode.Ok) {
        return res.data?.responses
      } else {
        console.error('Students fetch failed');
        return Promise.reject(`Request failed with status ${res.status}`);
      }
    } catch (error) {
      return Promise.reject(error);
    }
};

const submitReview = async (reviewData, exam_id, response_id) => {
    return await api.post(`/exams/${exam_id}/responses/${response_id}/review`, reviewData)
    .then(response => {
      return true;
    })
    .catch(error => {
      console.error('Error saving review:', error);
      return false;
    })
}

const getExamForQuiz = async (id, token) => {
  if (!id) return Promise.reject('No exam id')

  return api.get(`/exams/${id}/quiz`, { headers: { "X-Token": token } }).then(res => {
    if (res.status === HttpStatusCode.Ok) {
      // Change date format:
      if (res.data.date)
        res.data.date = convertISOToDDMMYYYY(res.data.date)
      return res.data;
    } else {
      console.error('Exam fetch failed');
      return Promise.reject(`Request failed with status ${res.status}`);
    }
  }).catch(err => {
    console.error(err)
    return null
  })
}

const requestAIReport = async (response_id, language, difficulty) => {
  if (!response_id) return Promise.reject('No response id')
  if (!language) return Promise.reject('No language')
  if (!difficulty) return Promise.reject('No difficulty')
  return await api.post(`/gen/ai-report/${response_id}`, { language, difficulty })
    .then(response => {
      return response.data.report;
    })
    .catch(error => {
      console.error('Error fetching AI report:', error);
      return null;
    })

}

const getExamResponse = async (examId, responseId, token) => {
  if (!examId || !responseId || !token) return Promise.reject('Missing parameters');

  return await api.get(`/exams/${examId}/responses/${responseId}`, { headers: { "X-Token": token } })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error('Error fetching response:', error);
      return null;
    })
}

export { requestSubmitExam, requestStudentsResponses, submitReview, getExamForQuiz, requestAIReport, getExamResponse }