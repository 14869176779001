import React, { useState } from 'react';
import { Box, Alert, Button, Typography, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ctas } from '../utils/configs';

const useCTA = () => {
  const [openCTA, setOpenCTA] = useState(false);
  const [link, setLink] = useState('/exam');
  const [content, setContent] = useState('');
  const [buttonLabel, setButtonLabel] = useState('Go to Link');
  const [newTab, setNewTab] = useState(false);
  const [isRTL, setIsRTL] = useState(false);

  const openCTAWithProps = ({ type }) => {
    setOpenCTA(true);

    if (ctas[type]) {
      setLink(ctas[type].link || '/exam');
      setContent(ctas[type].content || '');
      setButtonLabel(ctas[type].buttonLabel || 'Go to Link');
      setNewTab(ctas[type].newTab || false)
      setIsRTL(ctas[type].isRTL || false)
    }
  };

  const CTA = () => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
      if (newTab)
        window.open(link, '_blank');
      else
        navigate(link)
    };

    return (
      <div dir={isRTL ? 'rtl' : 'ltr'}>
        {openCTA && <Box sx={{ backgroundColor: '#E5F6FD', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Alert sx={{ width: '70%' }} severity="info" >
            <Grid item xs={6}>
              <Typography variant="subtitle2" component="div">
                {content}
              </Typography>
            </Grid>
          </Alert>
          <Button onClick={handleButtonClick} color="primary" sx={{ m: 1, fontWeight: '700' }}>
            {buttonLabel}
          </Button>
        </Box>}
      </div>
    );
  };

  return { CTA, openCTAWithProps };
};

export default useCTA;
