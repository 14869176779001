import React, { useState, useEffect, useMemo } from 'react';
import { scroller } from 'react-scroll';
// MUI
import { Button, Typography } from '@mui/material';
// Components
import ExamResponse from './ExamResponse';
import Header from '../../components/Header'
import StudentTable from './StudentTable';
import ExamAnalytics from './ExamAnalytics';
import FrequentlyMistakenQuestions from './FrequentlyMistakenQuestions ';
import DistributionGraph from '../../components/DistributionGraph/DistributionGraph';
import PerformaceReport from './PerformaceReport';
// Utils
import { requestExamJson } from '../../requests/exam';
import { requestAIReport, requestStudentsResponses, submitReview } from '../../requests/quiz';
import useSnackBar from '../../hooks/useSnackBar';
import { SnackbarTypes } from '../../utils/snackbarTypes';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../contexts/languageContext';
import { convertISOToDDMMYYYY } from '../../utils/dateHelpers';
import { paddingPageStyle } from '../../utils/customTheme';
import { useUser } from '../../hooks/useUser';
import { useLobby } from '../../contexts/LobbyContext';


const ReviewPage = () => {
  const [examJson, setExamJson] = useState(null);
  const [students, setStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const { isRTL, languageData } = useLanguage()
  const { openSnackBar, SnackBarComponent } = useSnackBar();
  const navigate = useNavigate()
  const { user } = useUser()
  const lobbyContext = useLobby()
  const [reportState, setReportState] = useState({ report: null, loading: false, response_id: null });

  let examId = ''
  useEffect(() => {
    if (user) {
      const fetchExamJson = async () => {
        examId = new URLSearchParams(location.search).get("id");
        if (!examId) {
          openSnackBar(SnackbarTypes.FETCH_FAILED.field);
          console.error("No exam id");
          return;
        }
        try {
          const data = await requestExamJson(examId);
          // move to preview if questions ids are missing
          data.questions.forEach(question => {
            if (!question._id) {
              navigate(`/preview?id=${examId}`)
              return
            }
          })

          setExamJson(data);
        } catch (error) {
          setExamJson(null)
          console.error(error)
        }
      };

      fetchExamJson();
    }
  }, []);

  useEffect(() => {
    if (user) {
      const fetchStudentsResponses = async () => {
        try {
          examId = new URLSearchParams(location.search).get("id");
          if (!examId) {
            openSnackBar(SnackbarTypes.FETCH_FAILED.field);
            console.error("No exam id");
            return;
          }
          let responses = await requestStudentsResponses(examId);
          responses = responses.map(res => ({ ...res, date: convertISOToDDMMYYYY(res.date) }))
          setStudents(responses);
          if (responses.length > 0)
            lobbyContext.autoGrade();
        } catch (error) {
          console.error('Error fetching students:', error);
          openSnackBar(SnackbarTypes.FETCH_FAILED.field);
        }
      };

      fetchStudentsResponses();
    }
  }, [selectedStudent]);

  const handleStudentClick = (student) => {
    setSelectedStudent(student);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleSubmitReview = async ({ questionsFeedback, grades, generalFeedback, isSaving }) => {
    const reviews = processFeedbackAndGrades(questionsFeedback, grades);

    if (!examId) examId = new URLSearchParams(location.search).get("id");
    const reviewData = {
      examId,
      responseId: selectedStudent._id,
      reviews,
      generalFeedback,
      reviewSubmitted: !isSaving,
      saveForLater: isSaving,
    };

    // For UI
    const updatedStudents = students.map(student => {
      if (student._id === selectedStudent._id) {
        return {
          ...student,
          reviewSubmitted: !isSaving,
          saveForLater: isSaving,
        };
      }
      return student;
    });
    setStudents(updatedStudents);

    try {
      const response = await submitReview(reviewData, reviewData.examId, reviewData.responseId);
      if (response)
        openSnackBar(SnackbarTypes.SUBMIT_SUCCESS.field);
      else
        openSnackBar(SnackbarTypes.SUBMIT_FAILED.field);
      lobbyContext.reviewedExam();
      setSelectedStudent(null)
    }
    catch (error) {
      console.error('Error saving review:', error);
      openSnackBar(SnackbarTypes.SUBMIT_FAILED.field);
    }
  };

  const handleShareLink = () => {
    const examId = new URLSearchParams(location.search).get("id");
    const host = window.location.host;
    const link = `${host}/quiz?id=${examId}`;
    navigator.clipboard.writeText(link)
    openSnackBar(SnackbarTypes.COPIED_LINK.field)
  }

  const handleGoBack = () => {
    setSelectedStudent(null)
  }

  const handleGoToHistory = () => {
    navigate("/history")
  }

  const handleGoToPreview = () => {
    const examId = new URLSearchParams(location.search).get("id");

    navigate(`/preview?id=${examId}`)
  }

  const handleRequestReport = (responseId) => {
    // TODO: report must be reviewed! (also check in the server side)
    const { language, difficulty } = examJson?.parameters || {}
    setReportState(prevReportState => ({ ...prevReportState, loading: true, response_id: responseId }))
    requestAIReport(responseId, language, difficulty).then(report => {
      setReportState(prevReportState => ({ ...prevReportState, report: report }))
      setTimeout(() => scroller.scrollTo('report', { duration: 800, delay: 0, smooth: 'easeInOutQuart' }), 800)

    })
      .catch(e => {
        console.error(e)
        openSnackBar(SnackbarTypes.AI_REPORT_FAILED.field);
      })
      .finally(() => setReportState(prevReportState => ({ ...prevReportState, loading: false })))
  }

  const grades = useMemo(() => students.map(student => student.grade), [students])

  return (
    <div>
      <Header />
      <div style={{ ...paddingPageStyle, maxWidth: '70rem' }}>
        {students.length === 0 ? (
          <div style={{ textAlign: 'center', gridColumn: '1 / span 2' }}>
            <Typography variant="h5" style={{ marginTop: '10vh', marginBottom: '5vh' }}>{languageData?.review?.no_students ?? ""}</Typography>
            <Button variant="contained" onClick={handleShareLink}>{languageData?.review?.share ?? ""}</Button>
            <Button variant="text" onClick={handleGoToHistory}>{languageData?.review?.go_back}</Button>
          </div>
        ) : (
          <div style={{ margin: 10 }}>
            <div dir={isRTL ? 'rtl' : 'ltr'} style={{ margin: 'auto' }}>
              {!selectedStudent &&
                <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Typography sx={{ mt: 3, px: 2 }} variant="h4">{examJson?.title}</Typography>
                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                    <Button variant="outlined" onClick={handleShareLink}>{languageData?.review?.share ?? ''}</Button>
                    <Button variant="text" onClick={handleGoToPreview} style={{ marginLeft: '10px' }}>{languageData?.preview_btn_label ?? ''}</Button>
                  </div>
                  <ExamAnalytics students={students} />
                  <DistributionGraph numbers={grades} />
                  <FrequentlyMistakenQuestions students={students} examJson={examJson} />
                </div>}
              {selectedStudent && (
                <div style={{ maxWidth: '85rem', margin: 'auto', paddingBottom: '50px' }}>
                  <Button variant="contained" onClick={handleGoBack}>{languageData?.review?.go_back}</Button>
                  <ExamResponse
                    student={selectedStudent}
                    examJson={examJson}
                    onSubmit={handleSubmitReview}
                    languageData={languageData?.review?.response}
                    isRTL={isRTL}
                  />
                </div>
              )}
            </div>
            {!selectedStudent && <StudentTable students={students} selectedStudent={selectedStudent} onStudentClick={handleStudentClick} onRequestReport={handleRequestReport} reportState={reportState} />}
            <PerformaceReport reportData={reportState.report} languageData={languageData?.performance_report} isRTL={isRTL} />
          </div>
        )}
      </div>

      <SnackBarComponent />
    </div>
  );
};

export default ReviewPage;

const processFeedbackAndGrades = (feedback, grades) => {
  let reviews = [];
  for (let i = 0; i < feedback.length; i++) {
    const questionId = feedback[i]?.question_id;
    const feedbackText = feedback[i]?.feedback ?? "";

    // Find the corresponding grade based on question_id
    const gradeObj = grades.find(grade => grade.question_id === questionId);
    const gradeValue = gradeObj ? gradeObj.grade : 0; // If grade is found, use its value, otherwise 0

    reviews.push({ question_id: questionId, feedback: feedbackText, grade: gradeValue });
  }

  return reviews;
};