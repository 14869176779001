import React from 'react';
import MathIcon from '@mui/icons-material/Functions';
import ScienceIcon from '@mui/icons-material/Science';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import HistoryIcon from '@mui/icons-material/History';
import PublicIcon from '@mui/icons-material/Public';
import PaletteIcon from '@mui/icons-material/Palette';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import ComputerIcon from '@mui/icons-material/Computer';
import LanguageIcon from '@mui/icons-material/Language';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import BrushIcon from '@mui/icons-material/Brush';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import TheatersIcon from '@mui/icons-material/Theaters';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import BuildIcon from '@mui/icons-material/Build';
import BusinessIcon from '@mui/icons-material/Business';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const subjects = [
    { subject: 'Math', icon: <MathIcon /> },
    { subject: 'Science', icon: <ScienceIcon /> },
    { subject: 'Literature', icon: <MenuBookIcon /> },
    { subject: 'History', icon: <HistoryIcon /> },
    { subject: 'Geography', icon: <PublicIcon /> },
    { subject: 'Art', icon: <PaletteIcon /> },
    { subject: 'Music', icon: <MusicNoteIcon /> },
    { subject: 'Physical Education', icon: <DirectionsRunIcon /> },
    { subject: 'Computer Science', icon: <ComputerIcon /> },
    { subject: 'Language', icon: <LanguageIcon /> },
    { subject: 'Sports', icon: <SportsSoccerIcon /> },
    { subject: 'Design', icon: <BrushIcon /> },
    { subject: 'Literacy', icon: <LocalLibraryIcon /> },
    { subject: 'Performing Arts', icon: <TheatersIcon /> },
    { subject: 'Health', icon: <HealthAndSafetyIcon /> },
    { subject: 'Creativity', icon: <EmojiObjectsIcon /> },
    { subject: 'Engineering', icon: <BuildIcon /> },
    { subject: 'Business', icon: <BusinessIcon /> },
    { subject: 'Other', icon: <MoreHorizIcon /> },
];

export default subjects;
