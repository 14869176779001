export const containerStyle = {
    width: '100%',
    textAlign: 'center',
    padding: '10px',
    // backgroundColor: '#eefdff',
    // boxShadow: '5px 5px 12px rgba(0, 0, 0, 0.2)'
}

export const uploadFormStyle = disabled => ({
    height: '200px',
    margin: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: `2px dashed #0077b6`,
    borderRadius: '10px',
    '&:hover': {
        backgroundColor: '#eff9ff',
    },
    opacity: disabled ? 0.5 : 1,
    cursor: disabled ? 'not-allowed' : 'pointer',
})

export const fileStyle = isValidFile => ({
    backgroundColor: isValidFile ? '#d5ebff' : '#ffcdd2',
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '10px',
    height: 'auto',
    width: '100%',
    gap: '15px',
})
export const fileDetailsStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
    width: '100%'
}