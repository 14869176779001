import { HttpStatusCode } from 'axios';
import api from './api';

async function requestModifyingQuestion(question, instruction, language) {
    return api.post('/gen/question/modify', { question, instruction, language }).then(response => {
        return response.status === HttpStatusCode.Ok ? response.data : null
    }).catch(err => {
        console.error(err)
        return null
    })
}
async function requestAddQuestion(questionType, exam, language){
    return api.post('/gen/question/add', { questionType, exam, language }).then(response => {
        return response.status === HttpStatusCode.Ok ? response.data : null
        
    }).catch(err => {
        console.error(err)
        return null
    })
}

export { requestModifyingQuestion, requestAddQuestion }