import PropTypes from 'prop-types';
import { Grid } from "@mui/material"
import AnalyticNumeric from "./AnalyticsNumeric"

const CustomAnalyticNumeric = props => {
    return <AnalyticNumeric {...props} countProps={{ variant: 'h6' }} />
}

const UserAnalytics = ({ userData }) => {
    const statistics = calculateStatistics(userData);
    const { totalExams, totalInputTokens, totalOutputTokens, totalDuration, mostUsedModel, avgInputTokens, avgOutputTokens, avgDuration, lastWeekExams, avgLastWeekInputTokens, avgLastWeekOutputTokens, avgLastWeekDuration } = statistics
    const inputCost = mostUsedModel.includes('4') ? 0.01 : 0.001
    const outputCost = mostUsedModel.includes('4') ? 0.03 : 0.0020
    return (
        <Grid container>
            <Grid item xs={6} sm={6} md={4} lg={3}>
                <CustomAnalyticNumeric title="Total Exams" count={totalExams} newExtra={'.'} />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={3}>
                <CustomAnalyticNumeric title="Total Input Tokens" count={totalInputTokens} newExtra={`Approx ${((totalInputTokens / 1000) * inputCost).toFixed(3)}$`} />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={3}>
                <CustomAnalyticNumeric title="Total Output Tokens" count={totalOutputTokens} newExtra={`Approx ${((totalOutputTokens / 1000) * outputCost).toFixed(3)}$`} />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={3}>
                <CustomAnalyticNumeric title="Total Duration" count={totalDuration} newExtra={'.'} />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={3}>
                <CustomAnalyticNumeric title="Most Used Model" count={mostUsedModel} newExtra={'.'} />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={3}>
                <CustomAnalyticNumeric title="Avg Input Tokens" count={avgInputTokens} newExtra={`Approx ${((avgInputTokens / 1000) * inputCost).toFixed(3)}$`} />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={3}>
                <CustomAnalyticNumeric title="Avg Output Tokens" count={avgOutputTokens} newExtra={`Approx ${((avgOutputTokens / 1000) * outputCost).toFixed(3)}$`} />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={3}>
                <CustomAnalyticNumeric title="Avg Duration" count={avgDuration} newExtra={'.'} />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={3}>
                <CustomAnalyticNumeric title="Last 7-Days Exams" count={lastWeekExams} newExtra={'.'} />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={3}>
                <CustomAnalyticNumeric title="Avg Last 7-Days Input Tokens" count={avgLastWeekInputTokens} newExtra={`Approx ${((avgLastWeekInputTokens / 1000) * inputCost).toFixed(3)}$`} />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={3}>
                <CustomAnalyticNumeric title="Avg Last 7-Days Output Tokens" count={avgLastWeekOutputTokens} newExtra={`Approx ${((avgLastWeekOutputTokens / 1000) * outputCost).toFixed(3)}$`} />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={3}>
                <CustomAnalyticNumeric title="Avg Last 7-Days Duration" count={avgLastWeekDuration} newExtra={'.'} />
            </Grid>
        </Grid>
    )
}


export default UserAnalytics;

UserAnalytics.proptypes = {
    userAnalytics: PropTypes.shape({
        email: PropTypes.string,
        exams: PropTypes.arrayOf({
            _id: PropTypes.string,
            properties: PropTypes.shape({ duration: PropTypes.number, input_tokens: PropTypes.number, output_tokens: PropTypes.number, model: PropTypes.string }),
            date: PropTypes.string
        }),
    })
}

/**
 * Calculate various statistics based on user data.
 * @param {{email: string, exams: {_id: string, properties:{duration: number, input_tokens: number, output_tokens: number, model: string}, date: string}[]}} userData
 * @returns {Object} Calculated statistics
 */
function calculateStatistics(userData) {
    let totalExams = 0;
    let totalInputTokens = 0;
    let totalOutputTokens = 0;
    let totalDuration = 0;
    let modelCount = {};
    let lastWeekExams = 0;
    let lastWeekInputTokens = 0;
    let lastWeekOutputTokens = 0;
    let lastWeekDuration = 0;
    let currentDate = new Date();
    const weekMilis = 7 * 24 * 60 * 60 * 1000;
    // Helper function to convert dd/mm/yyyy format to a Date object
    function parseDate(dateString) {
        const [day, month, year] = dateString.split('/');
        return new Date(`${year}-${month}-${day}`);
    }

    userData.exams.forEach(exam => {
        // Update total statistics
        totalExams++;
        totalInputTokens += exam?.properties?.input_tokens ?? 0;
        totalOutputTokens += exam?.properties?.output_tokens ?? 0;
        totalDuration += exam?.properties?.duration ?? 0;

        // Update model count
        if (exam?.properties?.model) modelCount[exam.properties.model] = (modelCount[exam.properties.model] || 0) + 1;

        // Check if the exam date is within the last week
        if (currentDate - parseDate(exam.date) <= weekMilis) {
            lastWeekExams++;
            lastWeekInputTokens += exam?.properties?.input_tokens ?? 0;
            lastWeekOutputTokens += exam?.properties?.output_tokens ?? 0;
            lastWeekDuration += exam?.properties?.duration ?? 0;
        }
    });

    // Calculate averages
    const avgInputTokens = parseFloat((totalInputTokens / totalExams).toFixed(1));
    const avgOutputTokens = parseFloat((totalOutputTokens / totalExams).toFixed(1));
    const avgDuration = parseFloat((totalDuration / totalExams).toFixed(1));
    const avgLastWeekInputTokens = parseFloat((lastWeekInputTokens / lastWeekExams).toFixed(1));
    const avgLastWeekOutputTokens = parseFloat((lastWeekOutputTokens / lastWeekExams).toFixed(1));
    const avgLastWeekDuration = parseFloat((lastWeekDuration / lastWeekExams).toFixed(1));
    totalInputTokens = parseFloat(totalInputTokens.toFixed(1));
    totalOutputTokens = parseFloat(totalOutputTokens.toFixed(1));
    totalDuration = parseFloat(totalDuration.toFixed(1));

    // Find the most used model
    let mostUsedModel = Object.keys(modelCount)?.reduce((a, b) => modelCount[a] > modelCount[b] ? a : b, 'None');

    return {
        totalExams,
        totalInputTokens,
        totalOutputTokens,
        totalDuration,
        mostUsedModel,
        avgInputTokens,
        avgOutputTokens,
        avgDuration,
        lastWeekExams,
        avgLastWeekInputTokens,
        avgLastWeekOutputTokens,
        avgLastWeekDuration
    };
}
