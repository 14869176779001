import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Grid, Button, Typography, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getExamForQuiz, getExamResponse } from '../../requests/quiz';
import { SnackbarTypes } from '../../utils/snackbarTypes';
import useSnackBar from '../../hooks/useSnackBar';
import { useLanguage } from '../../contexts/languageContext';
import { getTempToken } from '../../requests/token';
import StudentDetails from '../Review/StudentDetails';
import { questionsType } from '../../utils/examOptions';
import ChipBox from '../../components/ChipBox';
import Graph from '../../components/Recharts/Graph';
import Header from '../../components/Header';
import DisplayMultipleChoice from '../Review/DisplayMultipleChoice';
import { convertISOToDDMMYYYY } from '../../utils/dateHelpers';
import { useUser } from '../../hooks/useUser';
import ROLES from '../../utils/roles';

const ResultsPage = () => {
    const [response, setResponse] = useState(null);
    const [exam, setExam] = useState(null);
    const token = useRef(null);
    const navigate = useNavigate();

    const { isRTL, languageData, fetchLanguageWithToken } = useLanguage();
    const { openSnackBar, SnackBarComponent } = useSnackBar();
    const { user, isLoggedIn } = useUser()
    const resultsLanguageData = languageData?.review?.response;

    const examId = sessionStorage.getItem('examId');
    const responseId = sessionStorage.getItem('responseId');
    const empty_field = '----';

    const questionsFeedback = useMemo(() => response?.answers.map(answer => ({ feedback: answer.teacher_feedback || empty_field, question_id: answer.question_id })) ?? [], [response]);
    const grades = useMemo(() => response?.answers.map(answer => ({ grade: parseInt(answer.grade), question_id: answer.question_id })) ?? [], [response]);
    const generalFeedback = response?.general_feedback ?? empty_field;
    const totalGrade = parseInt(response?.grade);
    const maxGrade = useMemo(() => exam?.questions.reduce((acc, curr) => acc + parseInt(curr.grade), 0), [exam]);

    useEffect(() => {
        getTempToken({ responseId, examId }).then(tempToken => {
            if (tempToken) {
                token.current = tempToken;
                fetchLanguageWithToken(tempToken);
                getExamResponse(examId, responseId, tempToken).then(res => {
                    if (res) {
                        setResponse(res);
                    } else {
                        openSnackBar(SnackbarTypes.ERROR.field);
                    }
                }).catch(e => {
                    openSnackBar(SnackbarTypes.ERROR.field);
                });
                getExamForQuiz(examId, tempToken).then(res => {
                    if (res) {
                        setExam(res);
                    } else {
                        openSnackBar(SnackbarTypes.ERROR.field);
                    }
                }).catch(e => {
                    openSnackBar(SnackbarTypes.ERROR.field);
                });
            } else {
                openSnackBar(SnackbarTypes.ERROR.field);
            }
        }).catch(e => {
            openSnackBar(SnackbarTypes.ERROR.field);
        });
    }, []);

    useEffect(() => {
        isLoggedIn().then(loggedIn => {
            if (loggedIn) {
                // pass
            }
        });
    }, [isLoggedIn]);

    const handleCreateAnotherExam = () => {
        navigate('/exam');
    };

    return (exam && response && resultsLanguageData) && (
        <div>
            <Header token={token.current} />
            <div dir={isRTL ? 'rtl' : 'ltr'} style={{ maxWidth: '75rem', margin: 'auto', padding: '0 20px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={10} sm={5} md={5} lg={5}>
                        <StudentDetails student={{ ...response, grade: totalGrade, maximumGrade: maxGrade, date: convertISOToDDMMYYYY(response.date) }} />
                    </Grid>
                    {exam?.questions.map((question, index) => {
                        const studentAnswer = response.answers.find(answer => answer.questionId === question._id || answer.question_id === question._id);

                        return (
                            <Grid item xs={12} key={question._id}>
                                <Paper sx={{ padding: '16px', marginBottom: '16px' }}>
                                    <Typography variant="h5">{`${resultsLanguageData?.question_label ?? ""} ${index + 1}`}</Typography>
                                    <Typography variant="body1" gutterBottom sx={{ fontWeight: "bolder" }}>{question.question}</Typography>
                                    {question.type === questionsType.graph && question.functions && <Graph fnStrings={question.functions} />}
                                    {(question.type === questionsType.multiple || question.type === questionsType.fill_in) && (
                                        <DisplayMultipleChoice
                                            options={question.options}
                                            correctAnswers={question.correctAnswers}
                                            studentAnswer={studentAnswer?.answer}
                                        />
                                    )}
                                    {(question.type === questionsType.open || question.type === questionsType.graph) && (
                                        <Typography variant="body1" gutterBottom>
                                            <u>{resultsLanguageData?.answer_label + ":"}</u>
                                            <br />
                                            {studentAnswer?.answer}
                                        </Typography>
                                    )}
                                    {question?.explanation && (
                                        <ChipBox label={resultsLanguageData?.ai} text={question?.explanation} color={'#9C27B0'} backgroundColor={'#E4D2FC'} />
                                    )}
                                    <Grid container flexDirection='column' alignItems="start" pt={2}>
                                        {questionsFeedback[index]?.feedback !== empty_field && (
                                            <Grid item xs={12} sm={10} md={10} lg={12} pb={2}>
                                                <Typography variant="h6">{resultsLanguageData.teacher_feedback}</Typography>
                                                <Typography variant="body1">{questionsFeedback[index]?.feedback}</Typography>
                                            </Grid>
                                        )}
                                        <Grid item xs={3} sm={1} md={1} lg={1}>
                                            <Typography variant="h6">{resultsLanguageData.grade}</Typography>
                                            <div style={{ display: 'flex', flexDirection: 'row', direction: 'ltr' }}>
                                                <Typography variant="body1">{grades[index]?.grade ?? ''}</Typography>
                                                <Typography variant="body1">{' ' + `/ ${parseInt(question.grade)}`}</Typography>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid >
                        );
                    })}
                    {generalFeedback !== empty_field && (
                        <Grid item xs={12}>
                            <Paper sx={{ padding: '16px', marginBottom: '16px' }}>
                                <Typography variant="h6">{resultsLanguageData.general_feedback_label}</Typography>
                                <Typography variant="body1">{generalFeedback}</Typography>
                            </Paper>
                        </Grid>
                    )}
                    {user?.roles?.includes(ROLES.STUDENT.label) && (
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" sx={{ mb: 2 }} onClick={handleCreateAnotherExam}>
                                + Create another exam
                            </Button>
                        </Grid>
                    )}
                    <SnackBarComponent />
                </Grid >
            </div>
        </div>
    );
};

export default ResultsPage;
