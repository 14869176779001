import React, { useState } from 'react';
import { Button } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import HubApproveDialog from './HubApproveDialog';
import { isMobile } from '../../utils';
import { iconButtonStyle } from '../../utils/customTheme';
import { useLanguage } from '../../contexts/languageContext';
import { addExamToHub } from '../../requests/hub'
import useSnackBar from '../../hooks/useSnackBar';
import { SnackbarTypes } from '../../utils/snackbarTypes';

const LikeButton = ({ title, examId, forceMobileStyle = true }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const { isRTL } = useLanguage();
    const { openSnackBar, SnackBarComponent } = useSnackBar()
    const iconBtnStyle = forceMobileStyle ? iconButtonStyle.mobile : iconButtonStyle.desktop(isRTL)

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleApprove = (subject) => {
        addExamToHub(examId, subject)
        openSnackBar(SnackbarTypes.APPROVE_EXAM_TO_HUB.field)
        handleCloseDialog();
    };

    return (
        <>
            <Button
                title={title}
                variant='outlined'
                color='success'
                startIcon={<ThumbUpIcon />}
                sx={iconBtnStyle}
                onClick={handleOpenDialog}
            >
            </Button>
            <HubApproveDialog
                open={openDialog}
                onClose={handleCloseDialog}
                onApprove={handleApprove}
            />
            <SnackBarComponent />
        </>
    );
};

export default LikeButton;
