import React, { useEffect, useMemo, useState } from 'react';
import { Alert, Grid } from '@mui/material';
import SubjectList from './SubjectList';
import ExamHubList from './ExamHubList';
import Header from '../../components/Header'
import PreviewDialog from './PreviewDialog';
import subjects from '../../utils/subjectsData';
import { useHub } from '../../hooks/useHub';
import { isMobile } from '../../utils';

const Hub = () => {
    const [selectedSubject, setSelectedSubject] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedExamId, setSelectedExamId] = useState(null);
    const { hub, fetchHub } = useHub()

    useEffect(() => {
        fetchHub()
    }, [])

    const handleSubjectSelect = (subject) => {
        setSelectedSubject(subject);
    };

    const handleOpenPreviewDialog = (examId) => {
        setOpenDialog(true);
        setSelectedExamId(examId)
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleUpvoteClick = (examId) => {
        // Find the index of the exam with the given examId
        const index = filteredExams.findIndex(exam => exam.exam_id === examId);
        if (index !== -1) {
            // Create a copy of the exam object at the found index
            const updatedExam = { ...filteredExams[index] };
            // Increment the upvote count by 1
            updatedExam.upvotes_count += 1;
            // Update the exam and invoke useMemo
            filteredExams[index] = updatedExam;
        }
    };


    const filteredExams = useMemo(() => selectedSubject ? hub.filter(exam => exam.subject === selectedSubject.subject) : hub, [hub, selectedSubject]);
    const subjectsWithExams = useMemo(() => [...new Set(filteredExams?.map(exam => exam.subject))], [filteredExams]);
    const filteredSubjects = useMemo(() => subjects.filter(subject => subjectsWithExams.includes(subject.subject)), [subjects, subjectsWithExams]);

    return (
        <>
            <Header />
            <div style={{ padding: '10px' }}>
                <Grid container spacing={3} justifyContent={'center'}>
                    <Grid item xs={12} md={10}>
                        {!selectedSubject &&
                            <>
                                <Alert severity="info" sx={{ width: isMobile ? 'auto' : 'max-content', marginY: '10px' }}>
                                    All exams are created by teachers. You can upvote so others will know it's good.
                                </Alert>
                                <SubjectList subjects={filteredSubjects} exams={filteredExams} onSubjectSelect={handleSubjectSelect} />
                            </>
                        }
                    </Grid>
                    <Grid item xs={12} md={10}>
                        {selectedSubject && <ExamHubList examHubItems={filteredExams} handleUpvote={handleUpvoteClick} onBack={() => handleSubjectSelect(null)} onOpen={handleOpenPreviewDialog} />}
                    </Grid>
                </Grid >
            </div>
            {(openDialog && selectedExamId) && <PreviewDialog open={openDialog} handleClose={handleCloseDialog} examId={selectedExamId} />}
        </>
    );
};

export default Hub;
