import React from 'react';
import { Container, Grid, Box, Typography, Button, Stack } from '@mui/material';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import SchoolIcon from '@mui/icons-material/School';
import BusinessIcon from '@mui/icons-material/Business';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const FeaturesSmall = () => {
    const iconSize = '2rem';
    const navigate = useNavigate();

    const content = [
        {
            icon: <SchoolIcon sx={{ color: 'primary.main', fontSize: iconSize }} />,
            title: "Educators",
            subtitle: "Enhance your teaching with AI-powered quizzes, assessments, and exam generation. Save time on content creation and grading, and increase student engagement."
        },
        {
            icon: <AutoStoriesIcon sx={{ color: 'primary.main', fontSize: iconSize }} />,
            title: "Students",
            subtitle: "Achieve smarter learning through AI-generated quizzes and tailor-made Online Google Forms. Save time, improve retention, and excel in your exams effortlessly."
        },
        {
            icon: <BusinessIcon sx={{ color: 'primary.main', fontSize: iconSize }} />,
            title: "Business",
            subtitle: "Streamline your assessment processes to boost knowledge and evaluate skills efficiently. Whether it's for training delivery, course development, or certification."
        }
    ];

    const moveToSignup = () => {
        navigate('/signup');
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', padding: 60 }}>
            <Container>
                <Box textAlign="center" mb={6} >
                    <Typography variant="h4" gutterBottom>
                        Who's it for?
                    </Typography>
                </Box>
                <Grid container spacing={6}>
                    {content.map((item, index) => (
                        <Grid item xs={12} md={4} key={index}>
                            <Stack direction={'row'} alignItems="center" spacing={1} >
                                {item.icon}
                                <Typography variant="h4" style={{ fontWeight: 'bold' }}>
                                    {item.title}
                                </Typography>
                            </Stack>
                            <Typography variant="body1" style={{ marginBottom: '1.5rem', marginTop: '1.0rem', color: '#757575' }}>
                                {item.subtitle}
                            </Typography>
                            <Button
                                onClick={moveToSignup}
                                variant="outlined"
                                color="primary"
                                endIcon={<ArrowForwardIcon />}
                            >
                                Get Started
                            </Button>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </div>
    );
};

export default FeaturesSmall;
