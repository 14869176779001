import React from 'react';
import { Tooltip, AppBar, Toolbar, IconButton, Button } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SettingsButton from '../../components/Settings/SettingsButton';
import ShareButton from '../../components/Share/ShareButton';
import { useLanguage } from '../../contexts/languageContext';
import { colors } from '../../utils/customTheme';
import ChooseLanguageBtn from '../../components/ChooseLanguageBtn';



const PreviewHeader = ({ openExport, examId, handleSaveSettings, examSettings }) => {
    const { isRTL, languageData, ToggleLanguage, language } = useLanguage();

    const handleViewExam = () => {
        window.open(`/quiz?id=${examId}`, '_blank');
    };

    const handleOpenExport = () => {
        openExport();
    };

    return (
        <AppBar elevation={1} position="sticky" sx={{ bgcolor: 'white' }}>
            <Toolbar sx={{ justifyContent: 'flex-end' }}>
                <Tooltip title={languageData.preview.tooltips.settings} placement="bottom">
                    <span>
                        <SettingsButton onSubmit={handleSaveSettings} examSettings={examSettings} />
                    </span>
                </Tooltip>
                <Tooltip title={languageData.preview.tooltips.view_exam} placement="bottom">
                    <IconButton onClick={handleViewExam}>
                        <PlayArrowIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={languageData.preview.tooltips.export_exam} placement="bottom">
                    <Button variant="contained" color="secondary" sx={{ mx: 1 }} onClick={handleOpenExport}>
                        {languageData.preview?.export_exam}
                    </Button>
                </Tooltip>
                <Tooltip title={languageData.preview.tooltips.share_exam} placement="bottom">
                    <span>
                        <ShareButton forceDesktopStyle isRTL={isRTL} languageData={languageData?.preview} examId={examId} />
                    </span>
                </Tooltip>
                <ChooseLanguageBtn
                    handleToggleLanguage={() => ToggleLanguage()}
                    language={language}
                />
            </Toolbar>
        </AppBar>
    );
};

export default PreviewHeader;
