import { useUser } from "../../hooks/useUser";
import PricingPlans from "./PricingPlans";

function Pricing() {
    const { user } = useUser()

    return (
        <PricingPlans email={user?.email} />
    )
}

export default Pricing;