import Cookies from 'js-cookie';

const accessTokenCookieKey = 'access_token_cookie'
const csrfTokenCookieKey = 'csrf_access_token'

export const getAccessToken = () => {
    return Cookies.get(accessTokenCookieKey);
};
export const getCSRFToken = () => {
    return Cookies.get(csrfTokenCookieKey);
};

export const getJWTHeaders = () => {
    const accessToken = getAccessToken();
    const CSRFToken = getCSRFToken();
    let headers = {}

    if (accessToken) {
        headers = {
            ...headers,
            'Authorization': `Bearer ${accessToken}`,
        };
    }

    if (CSRFToken) {
        headers = {
            ...headers,
            'X-CSRF-TOKEN': CSRFToken,
        };
    }
    return headers;
};

export const removeJWT = () => {
    Cookies.remove(accessTokenCookieKey);
    Cookies.remove(csrfTokenCookieKey);
};