import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import VideoLibraryOutlinedIcon from '@mui/icons-material/VideoLibraryOutlined';
import GroupWorkOutlinedIcon from '@mui/icons-material/GroupWorkOutlined';
import DynamicFeedOutlinedIcon from '@mui/icons-material/DynamicFeedOutlined';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';

export const getMenuLinks = (languageData, language) => {
    if (languageData && languageData.menu) {
        return [
            { title: languageData.menu.lobby, path: '/lobby', target: '_self', icon: <DashboardCustomizeOutlinedIcon /> },
            { title: languageData.menu.exam, path: '/exam', target: '_self', icon: <AddCircleOutlineOutlinedIcon /> },
            { title: languageData.menu.history, path: '/history', target: '_self', icon: <HistoryOutlinedIcon /> },
            { title: languageData.menu.hub, path: '/explore', target: '_self', icon: <GroupWorkOutlinedIcon /> },
            // { title: languageData.menu.blog, path: '/blog', target: '_self', icon: <DynamicFeedOutlinedIcon /> }, // Added blog link
            // { title: languageData.menu.feedback, path: 'https://forms.gle/DMWGPM5XhENQ9H6b7', target: '_blank', icon: <AddCommentOutlinedIcon /> },
            { title: languageData.menu.howto, path: '/tutorials', target: '_blank', icon: <VideoLibraryOutlinedIcon /> },
        ];
    } else {
        return [];
    }
};
export const homepage = "/lobby"
export const withMenuPages = ['/exam', '/history', '/dashboard', '/dashboard/org', '/preview', '/review', '/explore', '/pricing', '/blog', '/dashboard/emails', '/tutorials', '/lobby']
