import React from 'react';
import { Box, Typography, Button, Grid, Container, Paper } from '@mui/material';
import { School, Group, Comment, Feedback } from '@mui/icons-material';
import LandingHeader from '../Header';
import { isMobile } from '../../../utils';

const ClassInteractionsUseCase = () => {
    return (
        <Box>
            <LandingHeader />
            <Container maxWidth="auto" sx={{ mt: 10 }}>
                <Paper elevation={3} sx={{ p: 4 }}>
                    <Grid container spacing={4} alignItems="center">
                        {/* Full Width Column for Introductory Text */}
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                                {!isMobile && <School color='primary' sx={{ fontSize: 40, mr: 1 }} />}
                                <Typography variant="h3" sx={{ flexGrow: 1, fontSize: '35px' }} component="h1" aria-label="Enhance Class Interactions">
                                    Enhance Class Interactions with AI-Powered Tools - Soon
                                </Typography>
                            </Box>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                Discover how our cutting-edge AI-powered tools can revolutionize classroom interactions and enhance the learning experience. Our platform is designed to boost student engagement, streamline classroom management, and facilitate effective learning through innovative technology. By integrating advanced AI features, educators can manage tests, assignments, and assessments more efficiently while fostering a more interactive and supportive learning environment.
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                Our AI tools offer a comprehensive suite of features that enable teachers to track student progress, provide real-time feedback, and support collaborative learning. This ensures that students receive personalized attention and support, helping them excel in their academic pursuits. With our platform, educators can create engaging and dynamic class experiences that drive student success and improve overall educational outcomes.
                            </Typography>
                        </Grid>
                        {/* Features and Benefits Column */}
                        <Grid item xs={12}>
                            <Box sx={{ mb: 4 }}>
                                <Typography variant="h3" component="h2" sx={{ mb: 2, fontSize: '35px' }}>
                                    Why Choose Our Platform for Interactive Class Management?
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Group color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">AI-Enhanced Collaborative Learning</Typography>
                                    </Box>
                                    Our platform's AI-enhanced tools are specifically designed to support collaborative learning and group activities. By facilitating interactions among students, our tools help create a more engaging and participatory classroom environment. This collaborative approach not only improves student engagement but also enhances their learning outcomes by encouraging teamwork and communication.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Comment color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Interactive Discussions and Real-Time Feedback</Typography>
                                    </Box>
                                    Promote active learning through AI-powered interactive discussions and real-time feedback. Our platform allows educators to conduct live polls, facilitate discussions, and provide instant feedback on tests and assignments. This real-time interaction helps keep students engaged and informed about their performance, leading to improved learning experiences and better academic results.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Feedback color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Instant AI-Driven Feedback for Continuous Improvement</Typography>
                                    </Box>
                                    Our AI-driven feedback system provides immediate and actionable insights on student performance. This allows educators to quickly address areas where students may need additional support, helping them improve their skills and knowledge more effectively. With instant feedback, students can better understand their strengths and weaknesses, leading to continuous improvement and academic success.
                                </Typography>
                            </Box>
                            <Typography variant="h3" component="h2" sx={{ mb: 2, fontSize: '35px' }}>
                                How to Leverage Our AI Tools for Effective Class Interactions
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mt: 2, mb: 4 }}>
                                Leverage our AI-powered platform to create engaging and interactive class experiences. Our tools enable educators to design and manage tests and assignments with ease, facilitate dynamic discussions, and provide real-time feedback to students. By integrating these features into your classroom, you can enhance student participation, streamline administrative tasks, and create a more effective and engaging learning environment.
                            </Typography>
                            <Box>
                                <Button variant="contained" color="primary" href="/signup">
                                    Sign Up for Free
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </Box>
    );
};

export default ClassInteractionsUseCase;
