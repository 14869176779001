import { useEffect, useState } from 'react';
import uploadSvg from './upload.svg';
import fileSvg from './file.svg';
import { Box, Container, FormGroup, List, ListItem, Popover, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { isValidFileType } from '../../utils/fileHelpers';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ConvertPptDialog from '../ConvertPptDialog';
import { containerStyle, uploadFormStyle, fileStyle, fileDetailsStyle } from './styles'

const allowedFileExtensions = ['.pdf', '.ppt', '.pptx', '.txt', '.vtt', '.docx'];

function UploadFiles({ children, files, onUpload, onRemove, maxFiles: maxFilesProp, isPremiumUser = false, disabled = false }) {
    const [isDisabled, setIsDisabled] = useState(disabled);
    const [isAmountError, setIsAmountError] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const maxFiles = maxFilesProp ?? (isPremiumUser ? 3 : 1); // Use the given max files, otherwise based on user premium status

    useEffect(() => {
        setIsDisabled(files?.length >= maxFiles);
    }, [files?.length]);

    useEffect(() => { setIsDisabled(disabled) }, [disabled])

    const handleChangeFiles = (event) => {
        const newFiles = event.target.files
        // Check if exceeding the limit
        if (newFiles.length > maxFiles - files?.length) {
            setIsAmountError(true)
            setTimeout(() => setIsAmountError(false), 3000)
            return; // Prevent further processing if exceeding limit
        }
        const formattedNewFiles = [];
        for (const file of newFiles) {
            if (file.name.toLowerCase().endsWith('.ppt'))
                setOpenDialog(true);

            formattedNewFiles.push({
                name: file.name,
                sizeText: formatFileSize(file.size),
                file: file,
                isValidType: isValidFileType(file),
                alertMsg: ''
            })
        }
        onUpload(formattedNewFiles)
    }

    const handleRemoveFile = (index, fileName) => {
        // ? This function is called in order to remove a file from the input element
        onRemove(index);
        removeFileFromElement(fileName);
    };

    const removeFileFromElement = (fileName) => {
        // Create a new DataTransfer object
        const dataTransfer = new DataTransfer();
        const inputElement = document.getElementById('file-input');

        // Add all files except the one to be removed
        Array.from(inputElement.files).forEach((file, i) => {
            if (file.name !== fileName) {
                dataTransfer.items.add(file);
            }
        });

        // Set the files property of the input element
        inputElement.files = dataTransfer.files;
    }

    return (
        <Container sx={containerStyle}>
            <input id="file-input" type="file" name='file' hidden multiple accept={allowedFileExtensions.join(', ')} onChange={handleChangeFiles} disabled={isDisabled} />
            <label htmlFor="file-input">
                <FormGroup sx={uploadFormStyle(isDisabled)}>
                    <img src={uploadSvg} alt="Upload" height={100} />
                    <Typography variant="body1" sx={{ color: '#555' }}>Browse files to upload</Typography>
                    <Typography variant="caption" sx={{ color: '#555', fontWeight: '700' }}> Up to {maxFiles} file{maxFiles > 1 ? 's' : ''} allowed</Typography>
                </FormGroup>
                {children}
                {isAmountError && <Typography variant="body2" sx={{ color: '#e33', fontWeight: '600' }}> You can upload up to {maxFiles} file{maxFiles > 1 ? 's' : ''}!</Typography>}
            </label>
            <List>
                {files?.map((file, index) => (
                    <ListItem key={index}>
                        <Box sx={fileStyle(file.isValidType)}>
                            <Box sx={fileDetailsStyle}>
                                <img src={fileSvg} alt="File" height={25} />
                                <Box>
                                    <Typography variant="body2">{file.name}</Typography>
                                    <Typography variant="caption">{file?.sizeText ?? ''}</Typography>
                                </Box>
                            </Box>
                            <ClearIcon sx={{ cursor: 'pointer', transition: 'transform 0.3s ease-in-out', '&:hover': { transform: 'rotate(90deg)' } }} onClick={() => handleRemoveFile(index, file.name)} />
                        </Box>
                    </ListItem>
                ))}
            </List>
            <ConvertPptDialog open={openDialog} handleClose={() => setOpenDialog(false)} />
        </Container >
    );
}

export default UploadFiles;


function formatFileSize(bytes) {
    if (bytes < 1024) return `${bytes} B`;
    if (bytes < 1048576) return `${(bytes / 1024).toFixed(1)} KB`;
    return `${(bytes / 1048576).toFixed(1)} MB`;
}