import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import { Typography } from "@mui/material";

const GradeModal = ({ open, onClose, onSubmit, totalGrade, languageData }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {languageData.grade_modal_title}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {languageData.grade_notify + totalGrade}
        </Typography>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
          <Button onClick={onClose} color="error" style={{ marginRight: 8 }}>
            {languageData.cancel_label}
          </Button>
          <Button onClick={onSubmit} variant="contained" color="success">
            {languageData.submit_label}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export { GradeModal };
