// UserContext.js
import React, { createContext, useEffect, useState } from 'react';
import { deleteExamFromHistory, getPaginatedHistory, getRemainingHistory, requestExamJson } from '../requests/exam';
import { HttpStatusCode } from 'axios';
import { UserHistory } from '../utils/typedefs'
import { useUser } from '../hooks/useUser';
import { convertISOToDDMMYYYY } from '../utils/dateHelpers'

const HistoryContext = createContext();

const HistoryProvider = ({ children }) => {
    const [history, setHistory] = useState(null);
    const { user } = useUser()
    const [historyFetched, setHistoryFetched] = useState(false);

    useEffect(() => {
        if (!user) {
            setHistory(null);
            setHistoryFetched(false);
        }
    }, [user]);

    useEffect(() => {
        if (user && !historyFetched) {
            fetchHistory();
        }
    }, [user, historyFetched])

    const fetchHistory = async () => {
        const userId = user?._id
        if (!userId) return Promise.reject('User not found')

        return getPaginatedHistory(userId).then(userHistory => {
            const preparedHistory = prepareHistoryByDate(userHistory)
            setHistory(preparedHistory ?? [])
            setHistoryFetched(true); // Mark history as fetched
            fetchRemainingHistory(); // Trigger background fetch of the remaining exams
            return preparedHistory
        }).catch(err => {
            console.error(err);
            return Promise.reject(err)
        })
    }

    const fetchRemainingHistory = async () => {
        const userId = user?._id
        if (!userId) return Promise.reject('User not found')

        return getRemainingHistory(userId).then(history => {
            if (history.length) {
                const preparedHistory = prepareHistoryByDate(history)
                setHistory(prevHistory => [...prevHistory, ...preparedHistory]);
                return preparedHistory
            }
        });
    }

    const deleteFromHistory = async (id) => {
        return deleteExamFromHistory(id).then(result => {
            if (result.status === HttpStatusCode.Ok) {
                // delete exam
                setHistory(prevFullHistory => {
                    return prevFullHistory.filter(exam => exam._id !== id)
                })
                return Promise.resolve();
            } else
                return Promise.reject('Error deleting exam')
        }).catch(err => {
            console.error(err)
            return Promise.reject(err)
        })
    }

    const addNewExamToHistory = (examId) => {
        try {
            requestExamJson(examId).then(exam => {
                if (exam) {
                    setHistory(prevHistory => {
                        if (prevHistory && prevHistory.length) {
                            return [exam, ...prevHistory];
                        } else {
                            return [exam];
                        }
                    });
                }
            }).catch(error => {
                console.error(error);
            });
        } catch (error) {
            console.error(error);
        }
    }

    const updateExamInHistory = (examToUpdate) => {
        if (history && history.length)
            setHistory(prevHistory => prevHistory.map(exam => exam._id === examToUpdate._id ? examToUpdate : exam))
    }

    const resetHistory = () => setHistory(null)

    return (
        <HistoryContext.Provider value={{ history, deleteFromHistory, fetchHistory, addNewExamToHistory, updateExamInHistory, resetHistory }}>
            {children}
        </HistoryContext.Provider>
    );
};

export { HistoryProvider, HistoryContext };


function prepareHistoryByDate(userHistory) {
    // Filter items with undefined date
    const validItems = userHistory.filter(item => item.date).map(exam => ({ ...exam, date: convertISOToDDMMYYYY(exam.date) ?? '' }));

    // Sort by date (converting "dd/mm/yyyy")
    validItems.sort((a, b) => {
        if (!a.date || !b.date) // move to the last of the array
            return -1
        const dateA = a.date.split('/').reverse().join('/'); // Convert to "yyyy/mm/dd"
        const dateB = b.date.split('/').reverse().join('/'); // Convert to "yyyy/mm/dd"
        return new Date(dateB) - new Date(dateA);
    });

    // Include items with undefined dates at the end
    const sortedHistory = validItems.concat(userHistory.filter(item => !item.date));

    return sortedHistory;
}