import React from 'react';
import Button from '@mui/material/Button';
import LanguageIcon from '@mui/icons-material/Language';

function ChooseLanguageBtn({ handleToggleLanguage, language }) {
    return (
        <Button variant="text" onClick={handleToggleLanguage}>
            <LanguageIcon sx={{ marginRight: 1 }} />
            {language}
        </Button>
    );
}

export default ChooseLanguageBtn;
