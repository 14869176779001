import React from 'react';
import { Card, CardContent, Typography, Checkbox } from '@mui/material';

const ExamList = ({ exams, selectedExams, handleToggleExam }) => {
    const handleClickExam = (event, examId) => {
        event.currentTarget.style.backgroundColor = selectedExams.includes(examId) ? 'transparent' : '#e8f3ff'
        handleToggleExam(examId)
    }
    return (
        <div>
            {exams.map(exam => {
                const checked = selectedExams.includes(exam._id)
                return (
                    // TODO: consider sending the whole exam instead of its id (prevent finding him on the merge operation)
                    <Card key={exam._id} onClick={e => handleClickExam(e, exam._id)} style={{ cursor: 'pointer', marginBottom: '10px', backgroundColor: checked ? '#e8f3ff' : 'transparent' }}>
                        <CardContent sx={{ display: 'flex', gap: '10px' }}>
                            <Checkbox checked={checked} />
                            <div>
                                {exam.title && <Typography variant="h6" component="div">
                                    {exam.title}
                                </Typography>}
                                {exam.date && <Typography color="text.secondary">
                                    {exam.date}
                                </Typography>}
                            </div>
                        </CardContent>
                    </Card>
                )
            }
            )}
        </div>
    );
};

export default ExamList;
