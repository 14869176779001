import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material';
import { questionsTypesOptions } from '../../utils/examOptions';
import { useLanguage } from '../../contexts/languageContext';

const AddQuestionDialog = ({ open, onClose, onAddQuestion }) => {
  const [questionType, setQuestionType] = useState(null);

  const { isRTL, languageData } = useLanguage()

  const handleAddQuestion = (type) => {
    onAddQuestion(type);
    onClose();
  };
  const handleClose = () => {
    handleAddQuestion(null);
  }

  return (
    <Dialog open={open} onClose={handleClose} dir={isRTL ? 'rtl' : 'ltr'} >
      <DialogTitle>{languageData?.add_dialog?.title}</DialogTitle>
      <DialogContent>
        <FormControl component="fieldset">
          <FormLabel component="legend">{languageData?.add_dialog?.subtitle}</FormLabel>
          <RadioGroup
            aria-label="questionType"
            name="questionType"
            value={questionType}
            onChange={(e) => setQuestionType(e.target.value)}
          >
            {questionsTypesOptions.map(option => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio />}
                label={isRTL ? option.hebrewLabel : option.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{languageData?.add_dialog?.cancel}</Button>
        <Button variant="contained" onClick={() => handleAddQuestion(questionType)} disabled={!questionType}>{languageData?.add_dialog?.add}</Button>
      </DialogActions>
    </Dialog >
  );
};

export default AddQuestionDialog;
