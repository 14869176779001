import React, { useState } from 'react';
import Chip from '@mui/material/Chip';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useLanguage } from '../../contexts/languageContext';

const MAX_CHARS = 200
const ParamsToChips = ({ params }) => {
  const { isRTL, languageData } = useLanguage()
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState('');
  const [isCopied, setIsCopied] = useState(false);

  const handleChipClick = (value) => {
    if (value.length) {
      setValue(value);
      setAnchorEl(document.activeElement);
      setIsCopied(false);
    }
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleCopyText = () => {
    navigator.clipboard.writeText(value);
    setIsCopied(true);
  };

  const openPopover = Boolean(anchorEl);

  return (
    <>
      {params.prompt && <Chip
        label={languageData?.preview?.view_prompt}
        variant="outlined"
        style={{ marginRight: '8px', marginBottom: '8px', cursor: 'pointer' }}
        onClick={() => handleChipClick(params.prompt)}
      />}
      {params.focus && <Chip
        label={languageData?.preview?.view_focus}
        variant="outlined"
        style={{ marginRight: '8px', marginBottom: '8px', cursor: 'pointer' }}
        onClick={() => handleChipClick(params.focus)}
      />}

      {(params.prompt || params.focus) && <Popover
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        slotProps={{ paper: { sx: { direction: isRTL ? 'rtl' : 'ltr', display: 'flex', flexDirection: 'column', padding: '15px' } } }}
      >
        <Button
          variant='contained'
          color='success'
          sx={{ margin: 'auto', minWidth: '150px', width: '25%' }}
          onClick={handleCopyText}
          disabled={isCopied}
        >
          {isCopied ? 'Copied' : 'Copy'}
        </Button>
        <Typography sx={{ p: 2, maxWidth: '50vw', wordWrap: 'break-word' }}>{value.substring(0, MAX_CHARS) + (value.length > MAX_CHARS ? '...' : '')}</Typography>
      </Popover>}
    </>
  );
};

export default ParamsToChips;
