import React from 'react';
import { Box, Typography, Button, Grid, Container, Paper } from '@mui/material';
import { Quiz, Assessment, Speed, BarChart } from '@mui/icons-material';
import QuizzesAssessmentsImg from '../../../assets/features/quiz.png'; // Placeholder image
import LandingHeader from '../Header';
import { isMobile } from '../../../utils';

const QuizzesAssessmentsUseCase = () => {
    return (
        <Box>
            <LandingHeader />
            <Container maxWidth="auto" sx={{ mt: 10 }}>
                <Paper elevation={3} sx={{ p: 4 }}>
                    <Grid container spacing={4} alignItems="center">
                        {/* Full Width Column for Introductory Text */}
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                                {!isMobile && <Quiz color='primary' sx={{ fontSize: 40, mr: 1 }} />}
                                <Typography variant="h3" sx={{ flexGrow: 1, fontSize: '35px' }} component="h1" aria-label="Advanced Quiz and Assessment Maker">
                                    Advanced Quiz and Assessment Maker: AI-Powered Tools for Effective Evaluation
                                </Typography>
                            </Box>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                Enhance the effectiveness of your educational evaluations with our sophisticated AI-driven tools for quizzes and assessments. Our platform is designed to streamline the creation, management, and analysis of quizzes, helping educators and institutions achieve higher levels of accuracy and efficiency. Whether you need to develop customized quizzes or gain deep insights into assessment performance, our tools provide a comprehensive solution to meet your educational needs.
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                Our advanced quiz maker enables educators to build a diverse question bank, tailor quizzes to specific learning objectives, and deliver assessments that engage students. With automation features and in-depth analytics, you can easily monitor student progress and make data-driven decisions to enhance teaching strategies and learning outcomes.
                            </Typography>
                        </Grid>
                        {/* Features and Benefits Column */}
                        <Grid item xs={12}>
                            <Box sx={{ mb: 4 }}>
                                <Typography variant="h3" component="h2" sx={{ fontSize: '35px' }}>
                                    Why Choose Our AI-Powered Platform for Quizzes and Assessments?
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Assessment color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Comprehensive Question Bank for Assessments</Typography>
                                    </Box>
                                    Access an extensive library of pre-built questions and create custom quizzes tailored to your curriculum with our advanced quiz maker. Our platform ensures that you have the right questions to evaluate student understanding effectively.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Speed color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Quick and Easy Test Setup</Typography>
                                    </Box>
                                    Our intuitive interface allows for rapid setup of quizzes and assessments, reducing the time required to create and deploy evaluations. The automated tools streamline the entire process, making it hassle-free.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <BarChart color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">In-depth Analytics for Exams</Typography>
                                    </Box>
                                    Gain valuable insights into student performance with our detailed analytics and reporting features. Track results, analyze trends, and identify areas for improvement to refine your assessments and instructional methods.
                                </Typography>
                            </Box>
                            <Typography variant="h3" component="h2" sx={{ fontSize: '35px' }}>
                                How to Utilize Our Platform for Creating and Managing Quizzes and Assessments
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mt: 2, mb: 4 }}>
                                Utilize our AI-powered platform to revolutionize the way you create and manage quizzes and assessments. Design quizzes with ease, automate grading processes, and leverage detailed analytics to enhance the educational experience. Our tools support educators in delivering effective assessments that drive student success.
                            </Typography>
                            <Box>
                                <Button variant="contained" color="primary" href="/signup">
                                    Sign Up for Free
                                </Button>
                            </Box>
                        </Grid>
                        {/* Right Column (Image) */}
                        {/* <Grid item xs={12} md={6}>
                            <img src={QuizzesAssessmentsImg} alt="Advanced Quiz Maker and Assessment Tools" style={{ width: '100%', borderRadius: 8, border: '1px solid black' }} />
                        </Grid> */}
                    </Grid>
                </Paper>
            </Container>
        </Box>
    );
};

export default QuizzesAssessmentsUseCase;
