import { HttpStatusCode } from 'axios';
import api from './api';

export const saveOnboardingData = async (data) => {
  try {
    const response = await api.post(`/users/onboarding`, data);
    if (response.status === HttpStatusCode.Ok) {
      return true; // Indicate successful save
    } else {
      throw new Error('Failed to save onboarding data');
    }
  } catch (error) {
    throw error;
  }
};

export const getOnboardingData = async () => {
  try {
    const response = await api.get(`/users/onboarding`);
    if (response.status === HttpStatusCode.Ok) {
      return response.data;
    } else {
      throw new Error('Failed to save onboarding data');
    }
  } catch (error) {
    throw error;
  }
};
