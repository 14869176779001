import React from 'react';
import { Box, Typography, Button, Grid, Container, Paper } from '@mui/material';
import { Assessment, AssignmentTurnedIn, Chat } from '@mui/icons-material';
import LandingHeader from '../Header';
import { isMobile } from '../../../utils';

const SkillsKnowledgeAssessment = () => {
    return (
        <Box>
            <LandingHeader />
            <Container maxWidth="auto" sx={{ mt: 10 }}>
                <Paper elevation={3} sx={{ p: 4 }}>
                    <Grid container spacing={4}>
                        {/* Full Width Column for Introductory Text */}
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                                {!isMobile && <Assessment color='primary' sx={{ fontSize: 40, mr: 1 }} />}
                                <Typography variant="h3" sx={{ flexGrow: 1, fontSize:35 }} component="h1" aria-label="Skills and Knowledge Assessment Tools">
                                    Advanced Skills and Knowledge Assessment Tools to Enhance Learning
                                </Typography>
                            </Box>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4, lineHeight: 1.6 }}>
                                Elevate your assessment strategies with our comprehensive skills and knowledge assessment tools. Designed to optimize and streamline the process, our solutions provide deep insights into learning outcomes, automated grading, and actionable feedback. Perfect for educators and organizations looking to improve the efficiency and effectiveness of their assessment processes.
                            </Typography>
                        </Grid>
                        {/* Benefits Section */}
                        <Grid item xs={12}>
                            <Box sx={{ mb: 4 }}>
                                <Typography variant="h3" component="h2" sx={{ mb: 2, fontSize:33 }}>
                                    Key Benefits of Our Skills and Knowledge Assessment Solutions
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mb: 2, lineHeight: 1.6 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Assessment color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">In-Depth Assessment Capabilities</Typography>
                                    </Box>
                                    Conduct thorough skills and knowledge assessments with detailed analytics. Our tools offer rich insights that help you understand learning gaps and strengths, enabling targeted improvements and better educational outcomes.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mb: 2, lineHeight: 1.6 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <AssignmentTurnedIn color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Efficient Automated Grading</Typography>
                                    </Box>
                                    Streamline your grading process with automated tools that ensure accurate, consistent, and timely feedback. Save valuable time and focus on enhancing the learning experience while our system handles grading and evaluation.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mb: 4, lineHeight: 1.6 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Chat color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Actionable Feedback and Advanced Analytics</Typography>
                                    </Box>
                                    Deliver meaningful feedback and monitor performance with our advanced analytics. Gain insights into student progress and identify areas for improvement to tailor your teaching strategies effectively.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box>
                                <Typography variant="h3" component="h2" sx={{ mb: 2, fontSize:33 }}>
                                    How to Maximize the Use of Our Assessment Tools
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mb: 4, lineHeight: 1.6 }}>
                                    Utilize our platform to create customized assessments, receive instant feedback, and track results efficiently. Improve learning outcomes by integrating our tools into your educational strategies and benefiting from precise, actionable data.
                                </Typography>
                                <Button variant="contained" color="primary" href="/signup">
                                    Sign Up for Free
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </Box>
    );
};

export default SkillsKnowledgeAssessment;
