import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { isMobile } from '../../utils';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useNavigate } from 'react-router-dom';
import PrivacyAndTermsOfService from '../../components/PrivacyAndTerms0fService';
// import bannerImage from './assets/images/new-banner.png';



const styles = {
  box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 8,
    marginTop: isMobile ? 6 : 2,
    minHeight: '100vh'
  },
  gradientText: {
    fontSize: isMobile ? '3rem' : '4rem',
    background: '-webkit-linear-gradient(left, #1976D2, #2F5B89)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    display: 'inline',
    fontWeight: 'bold',
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
  },
  regularText: {
    fontSize: isMobile ? '2rem' : '3rem',
    display: 'inline-grid',
    fontWeight: 'bold',
  },
  gradientButton: {
    background: 'linear-gradient(45deg, #1976D2 30%, #2F5B89 90%)',
    borderRadius: 3,
    textTransform: 'capitalize',
    fontSize: '16px',
    fontWeight: 'bold',
    border: 0,
    color: 'white',
    height: 48,
    padding: '0 30px',
    marginTop: 5,
  },
  icon: {
    marginLeft: 8,
  },
};

export default function WelcomeArea() {
  const navigate = useNavigate();

  const handleSignUpClick = () => {
    navigate('/signup');
  };


  return (
    <div id='welcome'>
      <Box sx={styles.box}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          gutterBottom
        >
          <Typography variant={isMobile ? "h4" : "h3"} style={styles.gradientText}>Why not let AI </Typography>
          <br /> {/* Add a line break */}
          <Typography variant={isMobile ? "h5" : "h4"} style={styles.regularText}>
            Generate and Auto-Grade Exams
          </Typography>
        </Typography>
        <Typography variant="h6" align="center" color="text.secondary">
          AI-powered skills and knowledge assessment software,<br /> helping business and educational users worldwide.
        </Typography>
        <Button
          variant="contained"
          endIcon={<PlayArrowIcon />}
          sx={styles.gradientButton}
          onClick={handleSignUpClick}
        >
          Get Started. It's Free
        </Button>
        <Box sx={{ mt: 2 }} />
        <PrivacyAndTermsOfService
          buttonText={"Get Started"}
          operationText={"clicking"}
        />
      </Box>
    </div >
  );
}
