import React from 'react';
import { Card, CardContent, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, Divider } from '@mui/material';
import '../App.css'
import { isMobile } from '../utils';

// todo: add copy to clipboard for code snippets


const ApiDocs = () => {
    return (
        <div style={{ padding: isMobile ? 20 : 40 }}>
            <div style={{ maxWidth: '75rem', margin: 'auto' }}>

                <Typography variant={isMobile ? "h5" : "h4"}>Public API Documentation</Typography><br />
                <Grid container spacing={4}>
                    {/* API Key Information */}
                    <Grid item xs={12}>
                        <Card sx={{ overflow: 'scroll', '&::-webkit-scrollbar': { display: 'none' }, msOverflowStyle: 'none', scrollbarWidth: 'none' }}>
                            <CardContent>
                                <Typography variant="h5">API Key Information</Typography>
                                <Divider />
                                <Typography variant="body1" sx={{ mt: 1, mb: 1 }}>
                                    <strong>API Key:</strong> To authenticate your requests, you must include your API key in the request headers. The API key should be provided as follows:
                                </Typography>
                                <Typography variant="body1" sx={{ mt: 1, mb: 1 }}>
                                    <code>X-API-KEY: YOUR_API_KEY</code>
                                </Typography>
                                <Typography variant="body1" sx={{ mt: 1, mb: 1 }}>
                                    Replace <code>YOUR_API_KEY</code> with the API key you received upon registration. This key must be included in the headers of all API requests to ensure proper authentication.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Create an exam */}
                    <Grid item xs={12}>
                        <Card sx={{ overflow: 'scroll', '&::-webkit-scrollbar': { display: 'none' }, msOverflowStyle: 'none', scrollbarWidth: 'none' }}>
                            <CardContent>
                                <Typography variant="h5">Create an Exam</Typography>
                                <Divider />
                                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Endpoint: <code>/gen/exam</code></Typography>
                                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Method: <code>POST</code></Typography>
                                <Typography variant="body1"><strong>Description:</strong> Create an Exam using AI.</Typography>
                                <Typography variant="body1">The model is chosen by the exam parameters.</Typography>
                                <br />
                                <Typography variant="body1">This is an <strong>async request</strong>, which means you will receive a <code>202 Accepted</code> response with the <code>task_id</code>. Then the server will start processing the request.</Typography>
                                <br />
                                <Typography variant="body1">In order to fetch the response, you will need to ask for the task's status:</Typography>
                                <ul>
                                    <li>&emsp;&#9679;&emsp; If the task is still running a <code>202 Accepted</code> response will be sent with "Running" as data.</li>
                                    <li>&emsp;&#9679;&emsp; If the task has completed a <code>200 OK</code> response will be sent along with the <code>exam_id</code> of the exam created.</li>
                                    <li>&emsp;&#9679;&emsp; If the task fails due to a <code>502 Bad Gateway</code> error, this indicates the AI model's server failed. A <code>500 Internal Server Error</code> will be sent for other failures.</li>
                                </ul>

                                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Headers:</Typography>
                                <Typography variant="body1"><code>X-API-KEY: YOUR_API_KEY</code></Typography>

                                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Parameters:</Typography>
                                <Typography variant="body1">Pass parameters in the request body as JSON.</Typography>
                                <TableContainer sx={{ mt: 3, mb: 3 }} component={Paper}>
                                    <Table>
                                        <TableHead sx={{ backgroundColor: '#E4F3FE' }}>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Type</TableCell>
                                                <TableCell>Required</TableCell>
                                                <TableCell >Default</TableCell>
                                                <TableCell sx={{ minWidth: 320 }}>Description</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>text</TableCell>
                                                <TableCell align='center'>string</TableCell>
                                                <TableCell align='center'>Yes</TableCell>
                                                <TableCell align='center'>-</TableCell>
                                                <TableCell>The text used to generate exam from. <b>Max 50k-100k characters</b> depending on plan.</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>questions</TableCell>
                                                <TableCell align='center'>object</TableCell>
                                                <TableCell align='center'>Yes</TableCell>
                                                <TableCell align='center'>-</TableCell>
                                                <TableCell>The types of questions to create. Can be mix of <b>multiple_choice</b>, <b>fill_in_the_blank</b>, <b>open_questions</b>, <b>graph_questions</b>. Max <b>15</b> in total.</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>difficulty</TableCell>
                                                <TableCell align='center'>string</TableCell>
                                                <TableCell align='center'>Yes</TableCell>
                                                <TableCell align='center'>-</TableCell>
                                                <TableCell>The exam's difficulty. Choose one of the options below the table.</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>language</TableCell>
                                                <TableCell align='center'>string</TableCell>
                                                <TableCell align='center'>Yes</TableCell>
                                                <TableCell align='center'>-</TableCell>
                                                <TableCell>The exam's language. Choose one of the options below the table.</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>focus</TableCell>
                                                <TableCell align='center' >string</TableCell>
                                                <TableCell align='center' >No</TableCell>
                                                <TableCell align='center' >""</TableCell>
                                                <TableCell>What should the exam focus on? Insert a text of the focus you want to provide.</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>exampleQuestion</TableCell>
                                                <TableCell align='center'>string</TableCell>
                                                <TableCell align='center'>No</TableCell>
                                                <TableCell align='center'>""</TableCell>
                                                <TableCell>Example question for to base the exam on. The generates questions will be in a similar format & difficulty.</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>isClosedContent</TableCell>
                                                <TableCell align='center'>boolean</TableCell>
                                                <TableCell align='center'>No</TableCell>
                                                <TableCell align='center'>true</TableCell>
                                                <TableCell>Whether the AI model should rely only on the provided text when generating exam.</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Grid container>
                                    <Grid item xs={12} md={12} >
                                        <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Difficulty options:</Typography>
                                        <ul>
                                            <li>&emsp;&#9679;&emsp;1st Grade</li>
                                            <li>&emsp;&#9679;&emsp;2nd Grade</li>
                                            <li>&emsp;&#9679;&emsp;3rd Grade</li>
                                            <li>&emsp;&#9679;&emsp;4th Grade</li>
                                            <li>&emsp;&#9679;&emsp;5th Grade</li>
                                            <li>&emsp;&#9679;&emsp;6th Grade</li>
                                            <li>&emsp;&#9679;&emsp;7th Grade</li>
                                            <li>&emsp;&#9679;&emsp;8th Grade</li>
                                            <li>&emsp;&#9679;&emsp;9th Grade</li>
                                            <li>&emsp;&#9679;&emsp;10th Grade</li>
                                            <li>&emsp;&#9679;&emsp;11th Grade</li>
                                            <li>&emsp;&#9679;&emsp;12th Grade</li>
                                            <li>&emsp;&#9679;&emsp;Academic</li>
                                        </ul>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Language options:</Typography>
                                        <ul>
                                            <li>&emsp;&#9679;&emsp;English</li>
                                            <li>&emsp;&#9679;&emsp;Hebrew</li>
                                            <li>&emsp;&#9679;&emsp;Hindi</li>
                                            <li>&emsp;&#9679;&emsp;Spanish</li>
                                            <li>&emsp;&#9679;&emsp;German</li>
                                            <li>&emsp;&#9679;&emsp;French</li>
                                            <li>&emsp;&#9679;&emsp;Russian</li>
                                            <li>&emsp;&#9679;&emsp;Italian</li>
                                            <li>&emsp;&#9679;&emsp;Dutch</li>
                                            <li>&emsp;&#9679;&emsp;Arabic</li>
                                        </ul>
                                    </Grid>
                                </Grid>

                                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Request Body:</Typography>
                                <pre>
                                    <code>
                                        {`{
    "text": "string", 
    "questions": {
        "multiple_choice": "number",
        "fill_in_the_blank": "number",
        "open_questions": "number",
        "graph_questions": "number",
    }, 
    "difficulty": "string",     
    "language": "string",       
    "focus": "string",          // Optional
    "exampleQuestion": "string",    // Optional
    "isClosedContent": "boolean",   // Optional
}`}
                                    </code>
                                </pre>

                                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Responses:</Typography>
                                <Typography variant="body1"><strong>202 Accepted:</strong></Typography>
                                <Typography variant="body1">The ID of the task processing the generation is returned</Typography>
                                <pre>
                                    <code>
                                        {`{
    "task_id": "string"
}
`}
                                    </code>
                                </pre>
                                <Typography variant="body1"><strong>500 Internal Server Error:</strong></Typography>
                                <pre>
                                    <code>
                                        "Internal Server Error"
                                    </code>
                                </pre>

                                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Example Request:</Typography>
                                <pre>
                                    <code>
                                        {`curl -X POST "https://exam-generator.com/api/v1/gen/exam" \\
    -H "X-API-KEY: YOUR_API_KEY" \\
    -d '{
        "text": "Coffee",
        "questions": {
            "multiple_choice": 5,
            "fill_in_the_blank": 2,
            "open_questions": 3
        },
        "difficulty": "10th Grade",     
        "language": "English",       
        "focus": "The impact of coffee on global economies and fair trade practices",
        "isClosedContent": false
    }'`}
                                    </code>
                                </pre>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Get generation task status */}
                    <Grid item xs={12}>
                        <Card sx={{ overflow: 'scroll', '&::-webkit-scrollbar': { display: 'none' }, msOverflowStyle: 'none', scrollbarWidth: 'none' }}>

                            <CardContent>
                                <Typography variant="h5">Get generation task status</Typography>
                                <Divider sx={{ mt: 1, mb: 1 }} />
                                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Endpoint: <code>/gen/{`task_id`}/status</code></Typography>
                                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Method: <code>GET</code></Typography>
                                <Typography variant="body1"><strong>Description:</strong> Retrieves the status of a generation task identified by its unique ID.</Typography>
                                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>URL Parameters:</Typography>
                                <ul>
                                    <li>&emsp;&#9679;&emsp;<code>task_id</code> (required): The unique identifier of the generation task. This ID is returned when you submit a generation request.</li>
                                </ul>
                                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Response Codes:</Typography>
                                <ul>
                                    <li>&emsp;&#9679;&emsp;<code>200 OK</code>: The task has completed successfully. The response body will contain the generated data (e.g., exam_id for an exam creation task).</li>
                                    <li>&emsp;&#9679;&emsp;<code>202 Accepted</code>: The task is still processing. The response body will contain "Running" string.</li>
                                    <li>&emsp;&#9679;&emsp;<code>500 Internal Server Error</code>: The task failed due to an internal server error unrelated to the AI model.</li>
                                    <li>&emsp;&#9679;&emsp;<code>502 Bad Gateway</code>: The task failed due to an error on the AI model's server.</li>
                                </ul>
                                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Responses:</Typography>
                                <Typography><strong>200 Ok:</strong></Typography>
                                <pre>
                                    <code>
                                        {`{
    "exam_id": "string"
}`}
                                    </code>
                                </pre>
                                <Typography><strong>202 Accepted:</strong></Typography>
                                <pre>
                                    <code>
                                        "Accepted"
                                    </code>
                                </pre>
                                <Typography><strong>502 Bad Gateway:</strong></Typography>
                                <pre>
                                    <code>
                                        "Bad Gateway"
                                    </code>
                                </pre>
                                <Typography><strong>500 Internal Server Error:</strong></Typography>
                                <pre>
                                    <code>
                                        "Internal Server Error"
                                    </code>
                                </pre>
                                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Example Request:</Typography>
                                <pre>
                                    <code>
                                        {`curl -X GET "https://exam-generator.com/api/v1/gen/2916456403.8146607/status" \\
    -H "X-API-KEY: YOUR_API_KEY"`}
                                    </code>
                                </pre>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Get Exam */}
                    <Grid item xs={12}>
                        <Card sx={{ overflow: 'scroll', '&::-webkit-scrollbar': { display: 'none' }, msOverflowStyle: 'none', scrollbarWidth: 'none' }}>

                            <CardContent>
                                <Typography variant="h5">Get Exam</Typography>
                                <Divider sx={{ mt: 1, mb: 1 }} />
                                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Endpoint: <code>/exams/{'{exam_id}'}</code></Typography>
                                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Method: <code>GET</code></Typography>
                                <Typography><strong>Description:</strong> Retrieves an exam by its unique ID.</Typography>
                                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>URL Parameters:</Typography>
                                <ul>
                                    <li>&emsp;&#9679;&emsp;<code>exam_id</code> (required): The unique identifier of the exam.</li>
                                </ul>
                                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Responses:</Typography>
                                <Typography><strong>200 Ok:</strong></Typography>
                                <pre>
                                    <code>
                                        {`{
    "_id": "string",
    "title": "string",
    "author": "string",
    "date": "string",
    "questions": [
        {
            "_id": "string",
            "type": "string",
            "grade": "number",
            "question": "string",
            "options": ["string"],
            "correctAnswers": ["string"],
        },
        {
            "_id": "string",
            "type": "string",
            "grade": "number",
            "question": "string",
            "explanation": "string",
        }
        ...
    ],
    "parameters":{
        "questions": {
            "multiple_choice": "number",
            "fill_in_the_blank": "number",
            "open_questions": "number",
            "graph_questions": "number",
        },
        "difficulty": "string",
        "prompt": "string",
        "focus": "string",
        "source": "string",
    }
}`}
                                    </code>
                                </pre>
                                <Typography><strong>404 Not Found:</strong></Typography>
                                <pre>
                                    <code>
                                        "Not Found"
                                    </code>
                                </pre>
                                <Typography><strong>500 Internal Server Error:</strong></Typography>
                                <pre>
                                    <code>
                                        "Internal Server Error"
                                    </code>
                                </pre>
                                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Example Request:</Typography>
                                <pre>
                                    <code>
                                        {`curl -X GET "https://exam-generator.com/api/v1/exams/294777f3cf72495qgva93981" \\
    -H "X-API-KEY: YOUR_API_KEY"`}
                                    </code>
                                </pre>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Evaluate an Exam */}
                    <Grid item xs={12}>
                        <Card sx={{ overflow: 'scroll', '&::-webkit-scrollbar': { display: 'none' }, msOverflowStyle: 'none', scrollbarWidth: 'none' }}>

                            <CardContent>
                                <Typography variant="h5">Evaluate an Exam</Typography>
                                <Divider sx={{ mt: 1, mb: 1 }} />
                                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Endpoint: <code>/gen/exam/evaluate</code></Typography>
                                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Method: <code>POST</code></Typography>
                                <Typography><strong>Description:</strong> Evaluate an Exam using AI. This is a synchronous request, and you'll have to wait to get a response. The evaluation process takes approximately 10-60 seconds.</Typography>
                                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Headers:</Typography>
                                <ul>
                                    <li>&emsp;&#9679;&emsp;<code>X-API-KEY: YOUR_API_KEY.</code></li>
                                </ul>
                                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Parameters:</Typography>
                                <Typography>Pass parameters in the request body JSON.</Typography>
                                <TableContainer sx={{ mt: 3, mb: 3 }} component={Paper}>
                                    <Table >
                                        <TableHead sx={{ backgroundColor: '#E4F3FE' }}>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Type</TableCell>
                                                <TableCell>Required</TableCell>
                                                <TableCell sx={{ minWidth: 320 }}>Description</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>answers</TableCell>
                                                <TableCell>array</TableCell>
                                                <TableCell align='center'>Yes</TableCell>
                                                <TableCell>
                                                    An array of objects, each containing the following fields:
                                                    <ul>
                                                        <li><code>_id</code> (string): Unique identifier for the question.</li>
                                                        <li><code>answer</code> (string): The provided answer to the question.</li>
                                                        <li><code>explanation</code> (string): An explanation for the answer, which is the indicator.</li>
                                                    </ul>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>language</TableCell>
                                                <TableCell>string</TableCell>
                                                <TableCell align='center'>Yes</TableCell>
                                                <TableCell>Supported languages include <b>English</b>, <b>Hebrew</b>, <b>Hindi</b>, <b>Spanish</b>, <b>German</b>, <b>French</b>, <b>Russian</b>, <b>Italian</b>, <b>Dutch</b>, and <b>Arabic</b> as specified by the API documentation.</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>difficulty</TableCell>
                                                <TableCell>string</TableCell>
                                                <TableCell align='center'>Yes</TableCell>
                                                <TableCell>The difficulty level of the evaluation. This setting will tailor the evaluation criteria to match the specified difficulty level. Possible options are shown in the table below.</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Difficulty options:</Typography>
                                <ul>
                                    <li>&emsp;&#9679;&emsp;1st Grade</li>
                                    <li>&emsp;&#9679;&emsp;2nd Grade</li>
                                    <li>&emsp;&#9679;&emsp;3rd Grade</li>
                                    <li>&emsp;&#9679;&emsp;4th Grade</li>
                                    <li>&emsp;&#9679;&emsp;5th Grade</li>
                                    <li>&emsp;&#9679;&emsp;6th Grade</li>
                                    <li>&emsp;&#9679;&emsp;7th Grade</li>
                                    <li>&emsp;&#9679;&emsp;8th Grade</li>
                                    <li>&emsp;&#9679;&emsp;9th Grade</li>
                                    <li>&emsp;&#9679;&emsp;10th Grade</li>
                                    <li>&emsp;&#9679;&emsp;11th Grade</li>
                                    <li>&emsp;&#9679;&emsp;12th Grade</li>
                                    <li>&emsp;&#9679;&emsp;Academic</li>
                                </ul>
                                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Request Body:</Typography>
                                <pre>
                                    <code>
                                        {`[
    {
        "answers": [
            {
                "_id": "string",
                "answer": "string",
                "explanation": "string"
            }
        ], 
        "language": "string",
        "difficulty": "string"
    }
]`}
                                    </code>
                                </pre>
                                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Responses:</Typography>
                                <Typography><strong>200 OK:</strong></Typography>
                                <pre>
                                    <code>
                                        {`[
    {
        "_id": "string",
        "grade": "number",
        "reason_for_grade": "string"
    },
    ...
]`}
                                    </code>
                                </pre>
                                <Typography><strong>500 Internal Server Error:</strong></Typography>
                                <pre>
                                    <code>
                                        "Internal Server Error"
                                    </code>
                                </pre>
                                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Example Request:</Typography>
                                <pre>
                                    <code>
                                        {`curl -X POST "https://exam-generator.com/api/v1/gen/exam/evaluate" \\
    -H "X-API-KEY: YOUR_API_KEY" \\
    -d '{
        "answers": [
            {
                "_id": "question1",
                "answer": "The capital of France is Paris.",
                "explanation": "Paris is the political and cultural capital of France."
            },
            {
                "_id": "question2",
                "answer": "Water freezes at 0 degrees Celsius.",
                "explanation": "At standard atmospheric pressure, water freezes at this temperature."
            }
        ],
        "language": "English",
        "difficulty": "5th Grade"
    }'`}
                                    </code>
                                </pre>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Get Exam's Responses */}
                    <Grid item xs={12}>
                        <Card sx={{ overflow: 'scroll', '&::-webkit-scrollbar': { display: 'none' }, msOverflowStyle: 'none', scrollbarWidth: 'none' }}>

                            <CardContent>
                                <Typography variant="h5">Get Exam's Responses</Typography>
                                <Divider sx={{ mt: 1, mb: 1 }} />
                                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Endpoint: <code>/exams/{'{exam_id}'}/responses</code></Typography>
                                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Method: <code>GET</code></Typography>
                                <Typography><strong>Description:</strong> Retrieves all responses of an exam.</Typography>
                                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>URL Parameters:</Typography>
                                <ul>
                                    <li>&emsp;&#9679;&emsp;<code>exam_id</code> (required): The unique identifier of the exam.</li>
                                </ul>
                                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Responses:</Typography>
                                <Typography><strong>200 Ok:</strong></Typography>
                                <pre>
                                    <code>
                                        {`{
    "responses": [
        {
            "_id": "string",
            "student_name": "string",
            "student_info": {
                "_id": "string",
                "personal_id": "string",
                "full_name": "string",
                "email": "string", // Optional
            },
            "exam_id": "string",
            "grade": "number",
            "reviewSubmitted": "boolean",
            "saveForLater": "boolean",
            "general_feedback": "string",
            "date": "string",
            "answers": [
                {
                    "question_id": "string",
                    "answer": "string",
                    "grade": "number",
                    "teacher_feedback": "string",
                },
                ...
            ]
        }
        ...
    ]
}`}
                                    </code>
                                </pre>
                                <Typography><strong>500 Internal Server Error:</strong></Typography>
                                <pre>
                                    <code>
                                        "Internal Server Error"
                                    </code>
                                </pre>
                                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Example Request:</Typography>
                                <pre>
                                    <code>
                                        {`curl -X GET "https://exam-generator.com/api/v1/exams/1234/responses" \\
    -H "X-API-KEY: YOUR_API_KEY"`}
                                    </code>
                                </pre>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Submit Exam Response */}
                    <Grid item xs={12}>
                        <Card sx={{ overflow: 'scroll', '&::-webkit-scrollbar': { display: 'none' }, msOverflowStyle: 'none', scrollbarWidth: 'none' }}>

                            <CardContent>
                                <Typography variant="h5">Submit Exam Response</Typography>
                                <Divider sx={{ mt: 1, mb: 1 }} />
                                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Endpoint: <code>/exams/{'{exam_id}'}/responses</code></Typography>
                                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Method: <code>POST</code></Typography>
                                <Typography><strong>Description:</strong> Submits a response for an exam identified by its unique ID.</Typography>
                                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>URL Parameters:</Typography>
                                <ul>
                                    <li>&emsp;&#9679;&emsp;<code>exam_id</code> (required): The unique identifier of the exam.</li>
                                </ul>
                                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Headers:</Typography>
                                <ul>
                                    <li>&emsp;&#9679;&emsp;<code>X-API-KEY: YOUR_API_KEY.</code></li>
                                </ul>
                                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Parameters:</Typography>
                                <Typography>Pass parameters in the request body as JSON.</Typography>
                                <TableContainer sx={{ mt: 3, mb: 3 }} component={Paper}>
                                    <Table>
                                        <TableHead sx={{ backgroundColor: '#E4F3FE' }}>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Type</TableCell>
                                                <TableCell>Required</TableCell>
                                                <TableCell sx={{ minWidth: 320 }}>Description</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>studentDetails</TableCell>
                                                <TableCell>object</TableCell>
                                                <TableCell align='center'>Yes</TableCell>
                                                <TableCell>
                                                    An object containing details about the student. It includes the following properties:
                                                    <ul>
                                                        <li><code>id</code> (string): The unique identifier for the student.</li>
                                                        <li><code>fullName</code> (string): The full name of the student.</li>
                                                        <li><code>email</code> (string): The email address of the student. (Optional)</li>
                                                    </ul>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>answers</TableCell>
                                                <TableCell>array</TableCell>
                                                <TableCell align='center'>Yes</TableCell>
                                                <TableCell>
                                                    An array of objects, each representing an answer to a question. Each object includes the following properties:
                                                    <ul>
                                                        <li><code>questionId</code> (string): The identifier for the question.</li>
                                                        <li><code>answer</code> (string): The provided answer to the question.</li>
                                                        <li><code>grade</code> (number): The grade or score given for the answer.</li>
                                                        <li><code>explanation</code> (string): A description or rationale for the provided answer.</li>
                                                    </ul>
                                                    In case the <b>answer has not been checked before the submission</b>, the <b>grade should be set to 0</b> and the <b>explanation should be an empty string.</b>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Request Body:</Typography>
                                <pre>
                                    <code>
                                        {`{
    "studentDetails": {
        "id": "string",
        "fullName": "string",
        "email": "string",
    },
    "answers": [
        {
            "questionId": "string",
            "answer": "string",         // The provided answer
            "grade": "number",          // Grade for the answer
            "explanation": "string",    // The indicator for the correct answer
        }
    ]
}`}
                                    </code>
                                </pre>
                                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Responses:</Typography>
                                <Typography><strong>200 Ok:</strong></Typography>
                                <pre>
                                    <code>
                                        "Exam responses submitted successfully."
                                    </code>
                                </pre>
                                <Typography><strong>400 Bad Request:</strong></Typography>
                                <Typography variant="body1">This error will occur if the data sent is invalid or not in the correct format.</Typography>
                                <pre>
                                    <code>
                                        "Bad Request."
                                    </code>
                                </pre>
                                <Typography><strong>500 Internal Server Error:</strong></Typography>
                                <pre>
                                    <code>
                                        "Internal Server Error"
                                    </code>
                                </pre>
                                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Example Request:</Typography>
                                <pre>
                                    <code>
                                        {`curl -X POST https://exam-generator.com/api/v1/exams/1234/responses \\
    -H "Content-Type: application/json" \\
    -H "X-API-KEY: YOUR_API_KEY" \\
    -d '{
        "studentDetails": {
            "id": "12345",
            "fullName": "John Doe",
            "email": "johndoe@example.com"
        },
        "answers": [
            {
                "questionId": "question1",
                "answer": "Paris is the capital of France.",
                "grade": 9,
                "explanation": "Correct. Paris is indeed the capital."
            }
        ]
    }'`}
                                    </code>
                                </pre>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Submit Response Review */}
                    <Grid item xs={12}>
                        <Card sx={{ overflow: 'scroll', '&::-webkit-scrollbar': { display: 'none' }, msOverflowStyle: 'none', scrollbarWidth: 'none', borderRadius: 0 }}>

                            <CardContent>
                                <Typography variant="h5">Submit Response Review</Typography>
                                <Divider sx={{ mt: 1, mb: 1 }} />
                                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Endpoint: <code>/exams/{'{exam_id}'}/responses/{'{response_id}'}/review</code></Typography>
                                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Method: <code>POST</code></Typography>
                                <Typography><strong>Description:</strong> Submits a review for a response of an exam identified by its unique ID.</Typography>
                                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>URL Parameters:</Typography>
                                <ul>
                                    <li>&emsp;&#9679;&emsp;<code>exam_id</code> (required): The unique identifier of the exam.</li>
                                    <li>&emsp;&#9679;&emsp;<code>response_id</code> (required): The unique identifier of the student's response.</li>
                                </ul>
                                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Headers:</Typography>
                                <ul>
                                    <li>&emsp;&#9679;&emsp;<code>X-API-KEY: YOUR_API_KEY.</code></li>
                                </ul>
                                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Parameters:</Typography>
                                <Typography>Pass parameters in the request body as JSON.</Typography>
                                <TableContainer sx={{ mt: 3, mb: 3, borderRadius: 0 }} component={Paper}>
                                    <Table>
                                        <TableHead sx={{ backgroundColor: '#E4F3FE' }}>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Type</TableCell>
                                                <TableCell>Required</TableCell>
                                                <TableCell sx={{ minWidth: 320 }}>Description</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>reviews</TableCell>
                                                <TableCell>array</TableCell>
                                                <TableCell align='center'>Yes</TableCell>
                                                <TableCell>
                                                    An array of objects, each containing a review of a question. Each object includes:
                                                    <ul>
                                                        <li><code>question_id</code> (string): The unique identifier of the question being reviewed.</li>
                                                        <li><code>feedback</code> (string): The feedback provided for the question.</li>
                                                        <li><code>grade</code> (number): The grade or score given for the question, relative to the question's total possible grade.</li>
                                                    </ul>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>generalFeedback</TableCell>
                                                <TableCell>string</TableCell>
                                                <TableCell align='center'>Yes</TableCell>
                                                <TableCell>General feedback provided for the overall review process.</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>reviewSubmitted</TableCell>
                                                <TableCell>boolean</TableCell>
                                                <TableCell align='center'>Yes</TableCell>
                                                <TableCell>Indicates whether the review has been submitted. This should be the opposite of <code>saveForLater</code>.</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>saveForLater</TableCell>
                                                <TableCell>boolean</TableCell>
                                                <TableCell align='center'>Yes</TableCell>
                                                <TableCell>Indicates whether the review is saved for later. This should be the opposite of <code>reviewSubmitted</code>.</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Request Body:</Typography>
                                <pre>
                                    <code>
                                        {`{
    "reviews": [
        {
            "question_id": "string",
            "feedback": "string",         
            "grade": "number",          
        },
        ...
    ], 
    "generalFeedback": "string",
    "reviewSubmitted": "boolean",
    "saveForLater": "boolean",
}`}
                                    </code>
                                </pre>
                                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Responses:</Typography>
                                <Typography><strong>200 Ok:</strong></Typography>
                                <pre>
                                    <code>
                                        "Response review submitted successfully."
                                    </code>
                                </pre>
                                <Typography><strong>400 Bad Request:</strong></Typography>
                                <Typography variant='body1'>This error will occur if the data sent is invalid or not in the correct format.</Typography>
                                <pre>
                                    <code>
                                        "Bad Request."
                                    </code>
                                </pre>
                                <Typography><strong>403 Forbidden:</strong></Typography>
                                <Typography variant='body1'>This error will occur if the user is trying to access data that he doesnt own. For example if he is not the exam's author.</Typography>
                                <pre>
                                    <code>
                                        "Forbidden."
                                    </code>
                                </pre>
                                <Typography><strong>500 Internal Server Error:</strong></Typography>
                                <pre>
                                    <code>
                                        "Internal Server Error"
                                    </code>
                                </pre>
                                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Example Request:</Typography>
                                <pre>
                                    <code>
                                        {`curl -X POST "https://exam-generator.com/api/v1/exams/1234/responses/5678/review" \\
    -H "X-API-KEY: YOUR_API_KEY" \\
    -d '{
        "reviews": [
        {
            "question_id": "question1",
            "feedback": "Good explanation, but missing details on the secondary topic.",
            "grade": 85
        },
        {
            "question_id": "question2",
            "feedback": "Excellent answer with thorough coverage of the topic.",
            "grade": 95
        }
    ], 
    "generalFeedback": "Overall, the responses were well-written and demonstrated a good understanding of the material.",
    "reviewSubmitted": true,
    "saveForLater": false
    }'`}
                                    </code>
                                </pre>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>

        </div>
    );
}

export default ApiDocs;