import { homepage } from "../components/menu/MenuLinks"
import { getOnboardingData } from "../requests/onboarding"
import { handleRedirectUrlAfterSignIn } from "../utils"
import ROLES from "./roles"

/**
 * Handles the redirect after authentication based on the user's role and onboarding data.
 * @param {function} navigate - The navigate function from the React Router.
 * @param {object} user - The user object containing user information.
 * @returns {Promise<void>} - A promise that resolves when the redirect is handled.
 */
export async function handleRedirectAfterAuth(navigate, user) {
    try {
        const onboardingData = await getOnboardingData()
        if (!onboardingData || !onboardingData.role) {
            handleRedirectUrlAfterSignIn(navigate, '/start')
        } else {
            handleRedirectUrlAfterSignIn(navigate, homepage)
        }
    } catch (e) {
        handleRedirectUrlAfterSignIn(navigate, '/start')
    }
}