import React, { useMemo } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Paper, Typography, Box } from '@mui/material';
import { colors } from '../../utils/customTheme';
import { useLanguage } from '../../contexts/languageContext';
import { defaultRanges } from './utils';

const DistributionGraph = ({ numbers, ranges = defaultRanges }) => {
    const { languageData } = useLanguage();
    // Compute data with useMemo
    const data = useMemo(() => {
        const frequencies = new Array(ranges.length).fill(0);
        numbers.forEach((number) => {
            const rangeIndex = ranges.findIndex((range) => number >= range.min && number <= range.max);
            if (rangeIndex !== -1) {
                frequencies[rangeIndex]++;
            }
        });

        return ranges.map((rangeObj, index) => ({
            grade: rangeObj.range,
            Total: frequencies[index] || 0
        }));
    }, [numbers, ranges]);

    return (
        <Box sx={{ width: '100%', p: 1 }}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant={"h5"} gutterBottom sx={{ pb: 2 }}>
                    {languageData?.review?.grades_distribution}
                </Typography>
                <ResponsiveContainer width="100%" height={350} style={{ marginLeft: '-20px' }}>
                    <BarChart data={data}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="grade" />
                        <YAxis />
                        <Tooltip />
                        <Bar dataKey="Total" fill={colors.secondary} />
                    </BarChart>
                </ResponsiveContainer>
            </Paper>
        </Box>
    );
};

export default DistributionGraph;
