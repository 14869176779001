// SupervisionApprove.js

import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { useLanguage } from '../../contexts/languageContext';

const SupervisionApprove = ({ open, onClose, onAccept }) => {
    const { languageData } = useLanguage();
    const supervisionTexts = languageData?.quiz?.supervision_approve_dialog

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        onClose();
    }

    return (
        <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { textAlign: 'center', display: 'flex', alignItems: 'center' } }}>
            <DialogTitle>{supervisionTexts?.title ?? 'Supervised Exam Notice'}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {supervisionTexts?.content ?? "This is a supervised exam. Leaving the exam tab will result in the end of the exam session."}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onAccept} variant='contained' color="error" sx={{ minWidth: '120px' }}>
                    {supervisionTexts?.button ?? "I understand"}
                </Button>
            </DialogActions>
        </Dialog >
    );
};

export default SupervisionApprove;
