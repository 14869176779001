import React, { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import { SnackbarTypes } from '../utils/snackbarTypes';
import { useLanguage } from '../contexts/languageContext';
import { isMobile } from '../utils';

const defaultDuration = 5000;
const useSnackBar = () => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('ERROR');
    const [snackBarDuration, setSnackBarDuration] = useState(defaultDuration);

    const { isRTL } = useLanguage()

    const openSnackBar = (type) => {
        const { msg, hebrew_msg, severity, duration } = SnackbarTypes[type] || SnackbarTypes.ERROR;
        setMessage(isRTL ? hebrew_msg : msg);
        setSeverity(severity);
        setSnackBarDuration(duration || defaultDuration);
        setOpen(true);
    };

    const closeSnackBar = () => {
        setOpen(false);
        setSnackBarDuration(defaultDuration); // Reset to default duration
    };

    const SnackBarComponent = () => (
        <Snackbar
            open={open}
            autoHideDuration={snackBarDuration}
            onClose={closeSnackBar}
            anchorOrigin={{ vertical: isMobile ? 'bottom' : 'top', horizontal: 'center' }}
            style={{ zIndex: 10000 }}
        >
            <Alert
                onClose={closeSnackBar}
                severity={severity}
                variant='filled'
            >
                {message.split('\n').map((line, index) => (
                    <Typography key={index} component="span">
                        {line}
                        <br />
                    </Typography>
                ))}
            </Alert>
        </Snackbar>
    );

    return { openSnackBar, SnackBarComponent };
};

export default useSnackBar;
