import React from 'react';
import { Box, Typography, Button, Grid, Container, Paper } from '@mui/material';
import { School, Assignment, Timer, Analytics } from '@mui/icons-material';
import HomeworkImg from '../../../assets/features/homework.jpg'; // Placeholder image
import LandingHeader from '../Header';
import { isMobile } from '../../../utils';

const HomeworkUseCase = () => {
    return (
        <Box>
            <LandingHeader />
            <Container maxWidth="auto" sx={{ mt: 10 }}>
                <Paper elevation={3} sx={{ p: 4 }}>
                    <Grid container spacing={4} alignItems="center">
                        {/* Full Width Column for Introductory Text */}
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                                {!isMobile && <School color='primary' sx={{ fontSize: 40, mr: 1 }} />}
                                <Typography variant="h3" sx={{ flexGrow: 1, fontSize: '35px' }} component="h1" aria-label="AI-Driven Homework and Assignment Maker">
                                    AI-Driven Homework and Assignment Maker for Enhanced Learning
                                </Typography>
                            </Box>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                Discover how our state-of-the-art AI-driven platform can transform the way you handle homework assignments and assessments. Our tools are designed to streamline the creation, management, and analysis of homework, making it easier for educators to engage students and monitor their progress effectively. Whether you are looking to automate assignment distribution, track submissions, or analyze performance, our platform provides comprehensive solutions that enhance the overall educational experience.
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                With our advanced AI tools, educators can quickly generate customized homework assignments that meet specific learning objectives. The platform’s automation features help manage submission deadlines and track student progress seamlessly. In addition, our detailed analytics provide valuable insights into student performance, allowing for data-driven decisions to improve future assignments and overall teaching strategies.
                            </Typography>
                        </Grid>
                        {/* Features and Benefits Column */}
                        <Grid item xs={12}>
                            <Box sx={{ mb: 4 }}>
                                <Typography variant="h3" component="h2" sx={{ fontSize: '35px' }}>
                                    Why Choose Our AI-Powered Platform for Homework and Assessments?
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Assignment color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Efficient Homework Assignment Creation</Typography>
                                    </Box>
                                    Our AI-driven assignment maker simplifies the process of creating and distributing homework. Customize assignments to align with your curriculum and learning goals, and effortlessly manage distribution to ensure timely delivery and engagement.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Timer color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Automated Test and Submission Tracking</Typography>
                                    </Box>
                                    Keep track of submission deadlines and monitor student progress with our intuitive tracking tools. Our platform automates reminders and status updates, reducing administrative workload and ensuring no assignment goes unnoticed.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Analytics color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Detailed Performance Analytics</Typography>
                                    </Box>
                                    Gain valuable insights into student performance through comprehensive analytics. Our platform provides detailed reports and visualizations to help identify areas for improvement, enabling you to refine assignments and tailor your teaching approach to better meet student needs.
                                </Typography>
                            </Box>
                            <Typography variant="h3" component="h2" sx={{ fontSize: '35px' }}>
                                How to Manage Homework and Assessments Effectively with AI
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mt: 2, mb: 4 }}>
                                Leverage our AI-powered platform to enhance the efficiency and effectiveness of homework and assessment management. Design, assign, and evaluate homework with ease, track student submissions and progress, and utilize detailed performance analytics to drive instructional improvements. Our tools are designed to support educators in creating a more organized, engaging, and data-driven educational environment.
                            </Typography>
                            <Box>
                                <Button variant="contained" color="primary" href="/signup">
                                    Sign Up for Free
                                </Button>
                            </Box>
                        </Grid>
                        {/* Image Column */}
                        {/* <Grid item xs={12}>
                            <img 
                                src={HomeworkImg} 
                                alt="AI Homework and Assignment Maker" 
                                style={{ width: '100%', borderRadius: 8, border: '1px solid black' }}
                                aria-label="Homework Image"
                            />
                        </Grid> */}
                    </Grid>
                </Paper>
            </Container>
        </Box>
    );
};

export default HomeworkUseCase;
