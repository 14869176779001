import React from 'react';
import { Box, Typography, Button, Grid, Container, Paper } from '@mui/material';
import { School, AccessTime, Star } from '@mui/icons-material';
import StudentsUseCaseImg from '../../../assets/features/students.jpg'; // Placeholder image
import LandingHeader from '../Header';
import { isMobile } from '../../../utils';

const StudentsUseCase = () => {
    return (
        <Box>
            <LandingHeader />
            <Container maxWidth="auto" sx={{ mt: 10 }}>
                <Paper elevation={3} sx={{ p: 4 }}>
                    <Grid container spacing={4} alignItems="center">
                        {/* Full Width Column for Introductory Text */}
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                                {!isMobile && <School color='primary' sx={{ fontSize: 40, mr: 1 }} />}
                                <Typography variant="h3" sx={{ flexGrow: 1, fontSize: '35px' }} component="h1" aria-label="Optimize Student Learning with Personalized AI Assessments">
                                    Optimize Student Learning with Personalized AI-Driven Assessments
                                </Typography>
                            </Box>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                Discover how our innovative AI-powered platform can revolutionize student learning experiences by offering highly tailored assessments that cater to each student's unique learning style. Our advanced system provides personalized feedback to help students understand their strengths and areas for improvement. In addition, our platform features in-depth performance tracking that allows educators to monitor progress over time, ensuring students receive the support they need to achieve academic excellence. By leveraging the latest AI technology, we aim to enhance academic success through customized and actionable insights.
                            </Typography>
                        </Grid>
                        {/* Features and Benefits Column */}
                        <Grid item xs={12} md={12}>
                            <Box sx={{ mb: 4 }}>
                                <Typography variant="h3" component="h2" sx={{ fontSize: '35px' }}>
                                    Why Choose Our AI-Enhanced Platform for Students?
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <School color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Personalized Assessments</Typography>
                                    </Box>
                                    Our state-of-the-art AI technology crafts highly personalized tests and assessments designed to meet each student’s individual learning preferences and requirements. This approach ensures a more engaging and effective learning experience by aligning assessments with the specific needs of each student. Our platform adapts to various learning styles and provides feedback that helps students better understand complex concepts and achieve their academic goals.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <AccessTime color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Effective Time Management</Typography>
                                    </Box>
                                    Our platform is designed to optimize exam scheduling and management, enabling students to use their time efficiently and effectively. By streamlining the exam creation and management process, we help reduce student stress and improve focus, leading to better performance. The platform’s intuitive tools and automated features simplify scheduling, allowing educators to spend more time on teaching and less on administrative tasks.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Star color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Track Academic Progress</Typography>
                                    </Box>
                                    Gain valuable insights into student performance through our comprehensive analytics and reporting features. Our platform provides detailed reports that track progress over time, identify trends, and pinpoint areas for improvement. These insights are crucial for supporting academic growth and making informed decisions about instructional strategies. By analyzing performance data, educators can tailor their approach to better meet the needs of their students and enhance learning outcomes.
                                </Typography>
                            </Box>
                            <Typography variant="h3" component="h2" sx={{ fontSize: '35px' }}>
                                How to Utilize Our AI Platform for Enhanced Student Success?
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mt: 2, mb: 4 }}>
                                Leverage our cutting-edge AI-driven tools to revolutionize the way you create and manage assessments. Our platform allows you to design custom exams tailored to individual learning needs, deliver actionable feedback, and track academic progress comprehensively. By incorporating our advanced features into your educational practices, you can enhance student learning outcomes, support academic success, and create a more effective and engaging learning environment.
                            </Typography>
                            <Box>
                                <Button variant="contained" color="primary" href="/signup">
                                    Sign Up for Free
                                </Button>
                            </Box>
                        </Grid>
                        {/* Right Column (Image) */}
                        {/* <Grid item xs={12} md={6}>
                            <img src={StudentsUseCaseImg} alt="AI-Powered Student Assessments" style={{ width: '100%', borderRadius: 8, border: '1px solid black' }} />
                        </Grid> */}
                    </Grid>
                </Paper>
            </Container>
        </Box>
    );
};

export default StudentsUseCase;
