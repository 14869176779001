import React, { useState } from 'react'
import GetAppIcon from '@mui/icons-material/GetApp';
import { CircularProgress, Button } from '@mui/material';
import { requestDownloadDocx } from '../../requests/docx';
import { isMobile } from '../../utils';
import { buttonsIds, reportClick } from '../../requests/analytics';

function DownloadDocx({ examId, label, openSnackBar }) {
    const [isLoading, setIsLoading] = useState(false)

    const handleDownload = async () => {
        setIsLoading(true)
        reportClick(buttonsIds.downloadPDF)
        requestDownloadDocx(examId).catch(e => {
            openSnackBar('ERROR')
        }).finally(() => {
            setIsLoading(false)
        })
    };

    return (
        <div>
            <Button
                variant="contained"
                sx={{ textTransform: 'capitalize', backgroundColor: '#4367A5', minWidth: '200px' }}
                startIcon={<GetAppIcon />}
                onClick={handleDownload}
                disabled={isMobile}
            >
                {isLoading ? <CircularProgress sx={{ color: 'white' }} /> : label}
            </Button>
            {isMobile && <p style={{ fontSize: 14, color: '#bbbbbb' }}>Works only on desktop</p>}
        </div>
    )
}

export default DownloadDocx;