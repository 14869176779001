import React from 'react';
import { Box, Typography, Button, Grid, Container, Paper } from '@mui/material';
import { AssignmentTurnedIn, Assessment, Chat } from '@mui/icons-material';
import AutoGradingImg from '../../../assets/features/auto-grade.png'; // Placeholder image
import LandingHeader from '../Header';
import { isMobile } from '../../../utils';

const AutoGrading = () => {
    return (
        <Box>
            <LandingHeader />
            <Container maxWidth="auto" sx={{ mt: 10 }}>
                <Paper elevation={3} sx={{ p: 4 }}>
                    <Grid container spacing={4}>
                        {/* Full Width Column for Introductory Text */}
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                                {!isMobile && <AssignmentTurnedIn color='primary' sx={{ fontSize: 40, mr: 1, mt: 0 ,left: 0, margin: 1}} />}
                                <Typography 
                                    variant="h3" 
                                    sx={{ flexGrow: 1, fontSize: '35px' }} 
                                    component="h1" 
                                    aria-label="AI-Powered Auto Grading for Assessments and Tests"
                                >
                                    Transform Your Grading Process with AI-Powered Auto Grading for Assessments and Tests
                                </Typography>
                            </Box>
                            <Typography 
                                variant="body1" 
                                color="textSecondary" 
                                sx={{ mb: 4, lineHeight: 1.6 }}
                            >
                                Revolutionize your grading process with our cutting-edge AI-powered auto grading tool. Effortlessly save time and reduce the burden of manual grading while providing your students with instant, actionable feedback on their assessments and tests. Our advanced AI technology ensures consistent, accurate grading and enhances overall efficiency in educational assessments.
                            </Typography>
                        </Grid>

                        {/* Benefits Column */}
                        <Grid item xs={12}>
                            <Box sx={{ mb: 4 }}>
                                <Typography 
                                    variant="h3" 
                                    component="h2" 
                                    sx={{ mb: 2, fontSize: '35px' }}
                                >
                                    Key Benefits of AI-Powered Auto Grading
                                </Typography>
                                <Typography 
                                    variant="body1" 
                                    color="textSecondary" 
                                    sx={{ mt: 2, lineHeight: 1.6 }}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <AssignmentTurnedIn color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6" >
                                            Enhanced Efficiency and Time Savings
                                        </Typography>
                                    </Box>
                                    Automate the grading process of tests and assignments to significantly boost efficiency and reduce the manual effort required. Our AI system handles grading swiftly, allowing educators to focus on more strategic aspects of teaching and learning.
                                </Typography>
                                <Typography 
                                    variant="body1" 
                                    color="textSecondary" 
                                    sx={{ mt: 2, lineHeight: 1.6 }}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Assessment color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6" >
                                            Improved Accuracy and Consistency
                                        </Typography>
                                    </Box>
                                    Utilize AI to achieve accurate and consistent grading of assessments. Our advanced algorithms ensure fairness and reduce the likelihood of human error, providing reliable and objective results for every test.
                                </Typography>
                                <Typography 
                                    variant="body1" 
                                    color="textSecondary" 
                                    sx={{ mt: 2, lineHeight: 1.6 }}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Chat color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6" >
                                            Instant and Constructive Feedback
                                        </Typography>
                                    </Box>
                                    Offer immediate feedback on assignments and tests, helping learners quickly understand their performance and areas for improvement. Instant feedback supports student engagement and accelerates the learning process.
                                </Typography>
                            </Box>

                            <Typography 
                                variant="h3" 
                                component="h2" 
                                sx={{ mb: 2, fontSize: '35px' }}
                            >
                                How to Effectively Implement AI for Auto Grading in Your Assessment Workflow
                            </Typography>
                            <Typography 
                                variant="body1" 
                                color="textSecondary" 
                                sx={{ mt: 2, mb: 4, lineHeight: 1.6 }}
                            >
                                Discover how to seamlessly integrate our AI-powered auto grading tool into your existing assessment workflow. Our platform simplifies the grading process, provides detailed insights, and enhances the overall educational experience. Start leveraging AI to streamline your grading practices and support educational excellence.
                            </Typography>
                            <Box>
                                <Button variant="contained" color="primary" href="/signup">
                                    Sign Up for Free
                                </Button>
                            </Box>
                        </Grid>

                        {/* Right Column (Image) */}
                        {/* <Grid item xs={12} md={6}>
                            <img src={AutoGradingImg} alt="AI-Powered Auto Grading" style={{ width: '100%', borderRadius: 8, border: '1px solid black' }} />
                        </Grid> */}
                    </Grid>
                </Paper>
            </Container>
        </Box>
    );
};

export default AutoGrading;
