import React, { useState, useMemo, useEffect } from 'react';
import { Line, XAxis, YAxis, CartesianGrid, LineChart } from 'recharts'
import { parse, create, all  } from 'mathjs'; // Import all from mathjs

import PropTypes from 'prop-types';

const strokeColors = ['#82ca9d', '#4285f4', '#db4437', '#0f9d58'];
function Graph({ fnStrings }) {
    const domain = Array.from({ length: 41 }, (_, i) => (i*0.5 - 10)); // [-5, 5]
    const [data, setData] = useState([]); // State to store processed data
    const math = create(all); // Create a mathjs instance
    // Function to convert string to function and evaluate
    /**
     * @param {string} fnStr 
     * @returns {Function}
     */
    const convertFn = (fnStr) => {
        if (fnStr.includes('='))
            fnStr = fnStr.split('=')[1].trim()
        try {
            // Custom parser to replace π with Math.PI
            const customParser = (expr) =>{
                // Replace π with math.pi in the expression
                expr = expr.replace(/π/g, Math.PI);
                expr = expr.replace(/ln/g, 'log');
                // Parse the expression using the built-in parse function
                return math.parse(expr);
            }

            // Parse the expression using the custom parser
            const expr = customParser(fnStr);
   
            // Compile the parsed expression
            //const compiledExpr = expr.compile();

            // Create a lambda function using the parsed expression
            const lambdaFunc = (x) => expr.evaluate({ x });
            return lambdaFunc;
        } catch (error) {
            console.error(`Error parsing expression: ${error.message}`);
            return null;
        }
    };

    // Convert functions using useMemo to memoize the result
    const convertedFns = useMemo(() => fnStrings.map(convertFn).filter(x => x), [fnStrings]);

    // Process data based on function strings
    useEffect(() => {
        try{
            const processedData = domain.map((x) => ({
                x,
                ...convertedFns.map((f, i) => {
                    let y = f(x)
                    if (!isFinite(y) || isNaN(y))
                        y = null

                    return { [`y${i}`]: y }
                }).reduce((obj, y) => ({ ...obj, ...y }), {}),
            }));
            setData(processedData);
        }
        catch(err){
            console.error(err)
        }

    }, [fnStrings]);

    if (convertedFns?.length) {
        return (
            <LineChart width={400} height={250} data={data} style={{ direction: 'ltr' }}>
                <XAxis dataKey="x" />
                <YAxis orientation='left' tickMargin={10} />
                <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                {fnStrings.map((_, i) => (
                    <Line type="monotone" dataKey={`y${i}`} stroke={strokeColors[i]} dot={false}/>
                ))}
            </LineChart>
        );
    } else
        return <></>
}

Graph.propTypes = {
    fns: PropTypes.arrayOf(PropTypes.func),
};

export default Graph;