import React from 'react';
import { Box, Typography, Button, Grid, Container, Paper } from '@mui/material';
import { School, AccessTime, Star } from '@mui/icons-material';
import TeachersUseCaseImg from '../../../assets/features/teachers.jpg'; // Placeholder image
import LandingHeader from '../Header';
import { isMobile } from '../../../utils';

const TeachersUseCase = () => {
    return (
        <Box>
            <LandingHeader />
            <Container maxWidth="auto" sx={{ mt: 10 }}>
                <Paper elevation={3} sx={{ p: 4 }}>
                    <Grid container spacing={4} alignItems="center">
                        {/* Full Width Column for Introductory Text */}
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                                {!isMobile && <School color='primary' sx={{ fontSize: 40, mr: 1 }} />}
                                <Typography variant="h3" sx={{ flexGrow: 1, fontSize: '35px' }} component="h1" aria-label="Empower Teachers with Advanced AI Assessment Tools">
                                    Empower Teachers with Advanced AI-Driven Assessment Tools
                                </Typography>
                            </Box>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                Transform your teaching approach with our cutting-edge AI-powered tools designed specifically for educators. Our platform is engineered to simplify the entire process of creating, administering, and evaluating exams and assessments. By leveraging advanced AI technology, we enable teachers to streamline administrative tasks, allowing them to devote more time to engaging with students and enhancing their learning experiences. Our solution supports a range of educational settings, from primary schools to higher education institutions, ensuring that your teaching methods remain effective and relevant in today’s rapidly evolving academic landscape.
                            </Typography>
                        </Grid>
                        {/* Features and Benefits Column */}
                        <Grid item xs={12} md={12}>
                            <Box sx={{ mb: 4 }}>
                                <Typography variant="h3" component="h2" sx={{ fontSize: '35px' }}>
                                    Why Our AI-Powered Platform is Essential for Teachers
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <School color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Streamlined Test Creation</Typography>
                                    </Box>
                                    Our AI-driven tools simplify the creation and distribution of custom tests and assessments. With our platform, teachers can effortlessly design personalized exams that align with their educational goals and the diverse learning needs of their students. This not only saves valuable time but also enhances the accuracy and effectiveness of assessments, ensuring they meet your specific educational objectives.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <AccessTime color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Automated Exam Grading</Typography>
                                    </Box>
                                    Automate the grading process with our advanced AI algorithms to provide timely and accurate feedback. Our platform streamlines the evaluation of student work, allowing teachers to track progress and performance efficiently. This automation not only ensures consistent grading but also frees up time for educators to focus on interactive teaching and personalized student support.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Star color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Comprehensive Insights and Analytics</Typography>
                                    </Box>
                                    Gain in-depth insights into student performance through our platform’s robust analytics features. Access detailed reports and performance metrics that help you evaluate the effectiveness of your teaching strategies, identify trends, and address individual student needs. Our comprehensive analytics enable you to make data-driven decisions that enhance learning outcomes and improve overall educational effectiveness.
                                </Typography>
                            </Box>
                            <Typography variant="h3" component="h2" sx={{ fontSize: '35px' }}>
                                How to Maximize the Use of Our AI Assessment Platform
                                </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mt: 2, mb: 4 }}>
                                To fully leverage the capabilities of our AI-powered assessment platform, integrate it into your daily teaching routine to design customized exams, automate grading processes, and obtain actionable insights into student performance. Our tools are designed to support educators in enhancing their teaching effectiveness, improving student engagement, and achieving better learning outcomes. By incorporating our platform into your educational practices, you can streamline administrative tasks, focus more on teaching, and ultimately contribute to a more dynamic and effective learning environment.
                            </Typography>
                            <Box>
                                <Button variant="contained" color="primary" href="/signup">
                                    Sign Up for Free
                                </Button>
                            </Box>
                        </Grid>
                        {/* Right Column (Image) */}
                        {/* <Grid item xs={12} md={6}>
                            <img src={TeachersUseCaseImg} alt="AI-Powered Tools for Teachers" style={{ width: '100%', borderRadius: 8, border: '1px solid black' }} />
                        </Grid> */}
                    </Grid>
                </Paper>
            </Container>
        </Box>
    );
};

export default TeachersUseCase;
