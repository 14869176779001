import React from 'react';
import { Box, Typography, Button, Grid, Container, Paper } from '@mui/material';
import { School, Feedback, Assessment as AssessmentIcon, Timeline } from '@mui/icons-material';
import LandingHeader from '../Header';
import { isMobile } from '../../../utils';
import FormativeAssessmentImg from '../../../assets/features/professor.jpg'; // Placeholder image

const FormativeAssessmentUseCase = () => {
    return (
        <Box>
            <LandingHeader />
            <Container maxWidth="auto" sx={{ mt: 10 }}>
                <Paper elevation={3} sx={{ p: 4 }}>
                    <Grid container spacing={4} alignItems="center">
                        {/* Full Width Column for Introductory Text */}
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                                {!isMobile && <AssessmentIcon color='primary' sx={{ fontSize: 40, mr: 1 }} />}
                                <Typography variant="h3" sx={{ flexGrow: 1, fontSize: '35px' }} component="h1" aria-label="Formative Assessment Tools">
                                    Advanced Formative Assessment Tools for Effective Learning and Test Preparation
                                </Typography>
                            </Box>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                Our advanced formative assessment tools are designed to revolutionize student learning and test preparation. By leveraging cutting-edge AI technology, our platform provides educators and students with immediate, actionable feedback to drive continuous improvement. 
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
                                Monitor student progress efficiently with real-time analytics and insights that help tailor teaching strategies to individual needs. Our tools support a variety of assessment types, making it easier to evaluate student performance and enhance learning outcomes.
                            </Typography>
                        </Grid>
                        {/* Features and Benefits Column */}
                        <Grid item xs={12}>
                            <Box sx={{ mb: 4 }}>
                                <Typography variant="h3" component="h2" sx={{ fontSize: '35px' }}>
                                    Key Features of Our Formative Assessment Platform
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Feedback color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">AI-Enhanced Feedback for Assessments and Tests</Typography>
                                    </Box>
                                    Our platform uses AI to deliver instant, personalized feedback on assessments and tests. This enables students to quickly understand their performance, identify areas for improvement, and enhance their readiness for future challenges.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Timeline color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Comprehensive Tracking of Test Performance</Typography>
                                    </Box>
                                    Track and analyze student performance over time with our robust analytics tools. Our platform provides detailed insights into learning trends, helping educators make informed decisions to improve test preparation and overall academic achievement.
                                </Typography>
                                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <School color='primary' sx={{ width: 22, height: 22, mr: 1 }} />
                                        <Typography variant="h6">Create an Effective Learning and Assessment Environment</Typography>
                                    </Box>
                                    Design and manage assessments seamlessly with our intuitive tools. Foster a supportive learning environment by providing constructive feedback and maintaining high academic standards to ensure students’ success.
                                </Typography>
                            </Box>
                            <Typography variant="h3" component="h2" sx={{ fontSize: '35px' }}>
                                Implementing Formative Assessments: A Guide for Educators
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ mt: 2, mb: 4 }}>
                                Implementing formative assessments with our platform is straightforward and efficient. Create, manage, and evaluate assessments with ease while leveraging AI for personalized feedback. Track student progress comprehensively to enhance learning and prepare students effectively for tests, driving academic success.
                            </Typography>
                            <Box>
                                <Button variant="contained" color="primary" href="/signup">
                                    Sign Up for Free
                                </Button>
                            </Box>
                        </Grid>
                        {/* Image Column */}
                        {/* <Grid item xs={12}>
                            <img 
                                src={FormativeAssessmentImg} 
                                alt="Educator using formative assessment tools" 
                                style={{ width: '100%', borderRadius: 8, border: '1px solid black' }}
                                aria-label="Formative Assessment Image"
                            />
                        </Grid> */}
                    </Grid>
                </Paper>
            </Container>
        </Box>
    );
};

export default FormativeAssessmentUseCase;
