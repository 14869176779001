import React from 'react';
import { Grid, Typography } from '@mui/material';

const DataRetentionPolicy = () => {
    return (
        <Grid container spacing={2} sx={{ padding: '50px' }}>
            <Grid item xs={12}>
                <Typography variant="h4" align="center" sx={{ fontWeight: "bolder", fontSize: "40px" }}>
                    Data Retention Policy - StudyWise.io
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                    <h5 style={{ fontSize: "30px" }}>1. Introduction</h5>
                    <p>Welcome to the Data Retention Policy of StudyWise.io. <br />This policy outlines how we manage and retain the data you provide while using our services, including the exams you generate, take, or assess.<br />We are committed to transparency and ensuring that your data is handled responsibly.<br />Please review this policy to understand our data retention practices.</p>
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                    <h5 style={{ fontSize: "30px" }}>2. Purpose of Data Retention</h5>
                    <p>Our primary goal in retaining data is to personalize your experience and improve the services we offer. By keeping your data, we can assist you in generating exams, taking assessments, and optimizing your learning process.</p>
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                    <h5 style={{ fontSize: "30px" }}>3. Types of Data Retained</h5>
                    <p><strong>3.1. User-Provided Information:</strong> This includes your name, email address, and other contact details provided during account creation and management.</p>
                    <p><strong>3.2. Exam Data:</strong> All exams generated on our platform, including questions, answers, and relevant metadata, as well as any exams taken or assessments conducted using our tools.</p>
                    <p><strong>3.3. Google User Data:</strong> Information accessed from your Google account solely for the purpose of exporting exams to Google Forms, with your explicit consent.</p>
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                    <h5 style={{ fontSize: "30px" }}>4. Retention Period</h5>
                    <p><strong>4.1. Indefinite Retention:</strong> All user data, including exam data and user - provided information, is retained indefinitely for personalization purposes.This means we do not delete your data unless specifically requested by you.</p>
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                    <h5 style={{ fontSize: "30px" }}>5. Data Security Measures</h5>
                    <p><strong>5.1. We take extensive measures to secure your data, including encryption, access controls, and regular security assessments to protect against unauthorized access, disclosure, or alteration.</strong></p>
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                    <h5 style={{ fontSize: "30px" }}>6. User Rights and Data Deletion</h5>
                    {/* <p><strong>6.1. Review and Update:</strong> You can review and update your account information at any time through your account settings.</p> */}
                    <p><strong>6.1. Deletion Requests:</strong> While we retain data indefinitely for personalization purposes, you have the right to request the deletion of your personal data. Such requests can be made by contacting us at <a href="mailto:support@studywise.io">support@studywise.io</a></p>
                    <p><strong>6.2. Revoking Consent:</strong> You can revoke access to your Google account data at any time through our application settings.</p>
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                    <h5 style={{ fontSize: "30px" }}>7. Changes to this Data Retention Policy</h5>
                    <p>We may update this Data Retention Policy periodically to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any significant changes via email or by prominently posting a notice on our website.</p>
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                    <h5 style={{ fontSize: "30px" }}>8. Contact Us</h5>
                    <p>If you have any questions, concerns, or requests regarding this Data Retention Policy or how we handle your data, please contact us at <a href="mailto:support@studywise.io">support@studywise.io</a>.</p>
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                    <p>By using StudyWise.io, you agree to the terms outlined in this Privacy Policy. Thank you for trusting us with your information.</p>
                    <p>StudyWise | <a href="https://studywise.io">www.studywise.io</a></p>
                </Typography>
            </Grid>

        </Grid>
    );
};

export default DataRetentionPolicy;
